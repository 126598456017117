<template>
    <div class="sub-campaign-allocation-grid-container">
        <parking-lot-sub-campaign-selector
            class="sub-campaign-allocation-header"
            @change="handleSelectorChange"
        />

        <promo-ag-grid
            :row-data="gridData"
            :column-defs="columnDefs"
            :default-col-def="defaultColDef"
            :grid-options="gridOptions"
            grid-style="width: 100%; height: 100%;"
            grid-class="ag-theme-custom__sub-campaign-allocation-grid"
            dom-layout="normal"
            @grid-ready="onGridReady"
        />

        <v-row class="allocation-row">
            <v-col cols="4">
                <create-promotion-overview
                    :is-disabled="shouldDisableButton"
                    @create-promotion="openDialog()"
                    @set-split-promotion="setSplitPromotion"
                    @set-promotion-name="setPromotionName"
                />
                <feature-toggle :toggle="enableDownloadCentre">
                    <div class="allocation-row__download-btn">
                        <download-centre
                            :ignore-parent-read-only="true"
                            :disabled="shouldDisableButton"
                        />
                    </div>
                    <template v-slot:alternative>
                        <v-btn
                            secondary
                            depressed
                            outlined
                            class="allocation-row__download-btn"
                            :disabled="shouldDisableButton"
                            @click="onExport"
                        >
                            {{ $t('actions.export') | toSentenceCase }}
                            <icon icon-name="download" right small />
                        </v-btn>
                    </template>
                </feature-toggle>
            </v-col>
            <v-col cols="4" />
            <v-col cols="2">
                <accept-all-notifications
                    entity="scenarios"
                    :entity-id="get(parkingLotFilters, 'selections.scenario._id', '')"
                    :applying-notifications="
                        get(parkingLotFilters, 'selections.scenario.applyingNotifications', false)
                    "
                    :is-child="!!get(parkingLotFilters, 'selections.subCampaign.parentId', null)"
                    :has-notifications="hasNotifications"
                    :is-executing-promotions-with-pending-notifications="
                        isExecutingPromotionsWithPendingNotifications
                    "
                    :disabled="isActionsDisabled"
                />
                <accept-all-notifications-report-dialog
                    :entity-id="get(parkingLotFilters, 'selections.scenario._id', '')"
                    :is-child="!!get(parkingLotFilters, 'selections.subCampaign.parentId', null)"
                />
            </v-col>
            <v-col cols="2">
                <v-menu
                    v-model="isActionsMenuOpen"
                    content-class="actions-menu"
                    :close-on-content-click="false"
                    offset-y
                    top
                >
                    <template v-slot:activator="{ on, attrs }">
                        <icon-button
                            v-bind="attrs"
                            :disabled="allocationMenuDisabled || applyingNotifications"
                            :btn-text="$tkey('selectedItems')"
                            icon="expand_more"
                            class="actions-menu__icon"
                            icon-on-right
                            v-on="on"
                        />
                    </template>
                    <v-list class="actions-menu__list">
                        <v-list-item
                            v-for="task in workflowTasksForSubCampaign"
                            :key="`${task.task}::${task.isNegativeAction}`"
                        >
                            <spinner-dynamic v-if="isActionsDisabled" />
                            <task-button
                                v-bind="task"
                                :entity-type="workflowEntities.promotion"
                                :entity-ids="selectedForAllocationPromotionIds"
                                :split-promotions="splitPromotionsById"
                                :sub-campaign-id="selectedSubCampaign._id"
                                :categories="selectedSubCampaign.categories || []"
                                :multiple-entities="true"
                                :disabled-function="task.disabledFunction"
                                :disabled="isActionsDisabled || applyingNotifications"
                                text
                                hide-tooltip
                                always-show
                                confirm-first
                                @click="onActionButtonClick"
                                @action-completed="onActionCompleted"
                                @toggle-action-buttons="toggleActionButtons"
                            />
                        </v-list-item>

                        <v-list-item>
                            <v-btn
                                text
                                :disabled="
                                    allocationDialogDisabled ||
                                        isActionsDisabled ||
                                        applyingNotifications
                                "
                                @click="openAllocationDialog"
                            >
                                {{ $tkey('removeSelectedPromotions') | toSentenceCase }}
                            </v-btn>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>

        <forecasts-dialog
            ref="forecasts-dialog"
            :sub-campaign="parkingLotFilters.selections.subCampaign || {}"
            :top-level-products-data="allProductData"
            :top-level-targets-data="allTargetsData"
            :unit-data="isUnit ? unitsData : categoriesData"
            :selected-hierarchy-id="String(selectedHierarchyId)"
            :is-unit="isUnit"
        />

        <allocate-promotion-to-parking-lot-dialog
            ref="promotion-allocation-dialog"
            :campaign="parkingLotFilters.selections.campaign || {}"
            :sub-campaign="parkingLotFilters.selections.subCampaign || {}"
            :scenario="parkingLotFilters.selections.scenario || {}"
            :start-date="selectedDateRange[0]"
            :end-date="selectedDateRange[1]"
            :promotion-ids="filteringSelectedForAllocationPromotionIds"
            :has-activator="false"
            @confirm="afterAllocation"
        />

        <promotion-viewer-dialog
            ref="promotion-viewer-dialog"
            :key="`promotion-viewer::${editPromotionId}::${promotionToggle}`"
            is-sub-campaign-allocation
            :is-parking-lot="false"
            :promotion="dialogPromotionGetter()"
            :scenario="parkingLotFilters.selections.scenario || {}"
            :sub-campaign="parkingLotFilters.selections.subCampaign || {}"
            :split-promotion="splitPromotion"
            :promotion-name="promotionName"
            @close="closePromotionViewerDialog"
        />
    </div>
</template>

<script>
import promoResourcesEnum from '@enums/promo-resources';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import {
    enableDownloadCentre,
    canDeleteParkingLotPromotion,
    disablePromotionEditingDaysBeforeStart,
} from '@enums/feature-flags';
import notificationTypes from '@enums/notification-types';
import workflowTasks from '@enums/workflow-tasks';
import workflowEntities from '@enums/workflow-entities';
import {
    get,
    isEmpty,
    isNil,
    add,
    groupBy,
    sumBy,
    values,
    find,
    intersectionWith,
    intersectionBy,
    differenceBy,
    intersection,
    has,
    uniq,
    uniqBy,
    includes,
    debounce,
} from 'lodash';
import exportFormats from '@enums/export-formats';
import { subCampaignAllocationView } from '@enums/route-names';
import { parkingLot } from '@enums/resources';
import promotionErrorSeverities from '@enums/promotion-error-severities';
import createFeatureAwareFactory from '@/js/feature-toggles/feature-factory';
import { isStoreWidePromotion, isCategoryWidePromotion } from '@sharedModules/promotion-utils';
import promoAgIconHeader from '@/js/components/promo-ag-grid/ag-icon-header';
import iconRenderer from '@/js/components/workflow/icon-renderer';
import vuexComponentMixin from '@/js/mixins/vuex-component';
import configDrivenGridComponentMixin from '@/js/mixins/config-driven-grid-component';
import { toSentenceCase } from '@/js/utils/string-utils';
import editIconRenderer from './edit-icon-renderer';
import parentChildIconRenderer from './parent-child-icon-renderer';
import expandCollapseRows from './expand-collapse-rows';
import executionErrorsDialogRenderer from './execution-errors-dialog-renderer';
import deleteIconRenderer from './delete-icon-renderer';
import notesRenderer from './notes-renderer';
import promotionRagСolourRenderer from './promotion-rag-colour-renderer';
import checkboxRenderer from './checkbox-renderer';
import buttonRenderer from './button-renderer';
import buttonCheckboxRenderer from './button-checkbox-renderer';
import agGridUtils from '@/js/utils/ag-grid-utils';
import { getStoreGroupAttributeFilters } from '@/js/utils/store-group-attribute-utils';
import parkingLotGridMixin from '../mixins/parking-lot-grids-mixin';
import { mapWorkflowStateIcons } from '@/js/utils/workflow-utils';

const totalsRowKey = 'all';
const storeWideRowKey = 'storeWide';
const categoryWideRowKey = 'categoryWide';

export default {
    localizationKey: 'subCampaignAllocation',
    mixins: [vuexComponentMixin, configDrivenGridComponentMixin, parkingLotGridMixin],
    data() {
        return {
            workflowEntities,
            gridData: [],
            filteredGridData: [],
            // The actual value of promotionToggle is not important,
            // changing it on dialog close is just used to force the component
            // to be recreated by referencing the toggle in the key for the dialog.
            promotionToggle: true,
            selectedForAllocationPromotionIds: [],
            // used for edit button
            editPromotionId: null,

            selectedHierarchyId: null,
            isUnit: null,

            subCampaignProductAggregationsData: [],

            storeGroups: [],

            targetsData: [],

            isActionsMenuOpen: false,

            promotionsAggregationsData: [],

            defaultColDef: {
                editable: false, // read only for now, make-read-only on component too
                suppressMovable: true, // Stop users from being able to rearrange columns.
                lockPinned: true, // Stop users from being able to pin columns.
                sortable: false, // All columns default to being sortable.
                unSortIcon: true, // Ensures the sort icon displays all the time (not just when hovered over).
                suppressMenu: true, // Hide the column menu (includes filter and auto-size options)
                flex: 1,
                resizable: true,
                filter: false,
            },
            promoCount: 0,
            gridOptions: {
                rowHeight: 35, // Specified in pixels.
                frameworkComponents: {
                    editIconRenderer,
                    promoAgIconHeader,
                    deleteIconRenderer,
                    notesRenderer,
                    iconRenderer,
                    buttonCheckboxRenderer,
                    checkboxRenderer,
                    buttonRenderer,
                    promotionRagСolourRenderer,
                    parentChildIconRenderer,
                    executionErrorsDialogRenderer,
                    expandCollapseRows,
                },
                groupSuppressAutoColumn: true,
                pagination: false,
                columnTypes: {
                    numericColumnCustom: agGridUtils.columnTypes.numericColumnCustom,
                },
                treeData: true,
                groupDefaultExpanded: 1,
                rowSelection: 'multiple',
                // only use checkboxes, can't select row by clicking, since it allows selection of non-promotion rows
                suppressRowClickSelection: true,
                getDataPath: data => {
                    return data.hierarchy;
                },
                statusBar: {
                    statusPanels: [{ statusPanel: 'agSelectedRowCountComponent', align: 'right' }],
                },
                getRowNodeId: data => data.id || data._id,
                // When true, if you set new data into the grid and have groups open,
                // the grid will keep groups open if they exist in the new dataset
                rememberGroupStateWhenNewData: true,
                suppressScrollOnNewData: true,
                enableCellTextSelection: true,
                enableRangeSelection: true,
            },
            // name of the row that shows totals for all categories and it's name is configurable via translation
            totalsRowName: toSentenceCase(this.$tkey('totalsRowName')),
            storeWideRowName: toSentenceCase(this.$tkey('storeWideRowName')),
            categoryWideRowName: toSentenceCase(this.$tkey('categoryWideRowName')),
            enableDownloadCentre,

            workflowTasksList: [
                {
                    task: workflowTasks.draftSubmit,
                    isNegativeAction: false,
                },
                {
                    task: workflowTasks.submit,
                    isNegativeAction: false,
                },
                {
                    task: workflowTasks.approve,
                    isNegativeAction: false,
                },
                {
                    // negative approve = reject
                    task: workflowTasks.approve,
                    isNegativeAction: true,
                },
                {
                    task: workflowTasks.unlock,
                    isNegativeAction: false,
                    disabledFunction: this.disableTaskInFlightPromotions,
                },
            ],
            editButtonsIsDisabled: false,
            isActionsDisabled: false,
            isFilterChanged: false,
        };
    },

    computed: {
        ...mapState(['parkingLotFilters']),
        ...mapState('clientConfig', [
            'toggleLogic',
            'hierarchyConfig',
            'overviewPageConfig',
            'releaseFlags',
        ]),
        ...mapState('promotions', ['loading']),
        ...mapGetters('promotions', [
            'getPromotionById',
            'allPromotionsBySubCampaignId',
            'getPromotionsByScenarioId',
            'getParentPromotionsKeyedById',
            'getPromotionNotifications',
        ]),
        ...mapGetters('hierarchy', ['getAllCategories', 'getAllUnits']),
        ...mapGetters('scenarios', ['allScenariosIdsBySubCampaignId', 'getScenarioById']),
        ...mapGetters('hierarchy', ['getUnitToCategoriesMap']),
        ...mapGetters('context', ['userCategories']),
        ...mapGetters('subCampaigns', ['getSubCampaignById']),
        ...mapGetters('notifications', ['getNotificationsForScenario']),

        selectedSubCampaign() {
            return this.parkingLotFilters.selections.subCampaign || {};
        },

        workflowTasksForSubCampaign() {
            // Return early if the sub-campaign does not have a workflow.
            if (!this.selectedSubCampaign.workflow) return [];

            return intersectionWith(
                this.workflowTasksList,
                this.selectedSubCampaign.workflow.steps,
                (availableTask, step) => {
                    return (
                        step.entity === workflowEntities.promotion &&
                        step.task === availableTask.task
                    );
                }
            );
        },

        selectedScenario() {
            return this.getScenarioById({
                _id: get(this.parkingLotFilters, 'selections.scenario._id'),
                usePluralResourceName: true,
            });
        },

        applyingNotifications() {
            return get(this.selectedScenario, 'applyingNotifications', false);
        },

        shouldDisableButton() {
            return !this.parkingLotFilters.selections.scenario;
        },

        workflowStateIconsMap() {
            return createFeatureAwareFactory(
                this.toggleLogic
            ).getSubCampaignAllocationWorkflowStateIconsMap();
        },

        selectedDateRange() {
            if (!this.parkingLotFilters.selections.dateRange) {
                return ['', ''];
            }
            return this.parkingLotFilters.selections.dateRange;
        },

        childPromotionIds() {
            return this.gridData
                .filter(item => item.parentPromotionId)
                .map(item => item.promotionId);
        },
        filteringSelectedForAllocationPromotionIds() {
            return this.selectedForAllocationPromotionIds.filter(
                id => !this.childPromotionIds.includes(id)
            );
        },

        allocationDialogDisabled() {
            return isEmpty(this.filteringSelectedForAllocationPromotionIds);
        },

        allocationMenuDisabled() {
            return isEmpty(this.selectedForAllocationPromotionIds);
        },

        renderEmptyGrid() {
            return this.loading || !this.parkingLotFilters.selections.scenario;
        },

        unitKeyToNameMap() {
            return this.getAllUnits.reduce((acc, unit) => {
                acc[unit.levelEntryKey] = unit.levelEntryDescription;
                return acc;
            }, {});
        },

        categoryKeyToNameMap() {
            return this.getAllCategories.reduce((acc, cat) => {
                acc[cat.levelEntryKey] = cat.levelEntryDescription;
                return acc;
            }, {});
        },

        columnDefs() {
            return [
                {
                    ...(this.releaseFlags.releaseFlags.expandCollapseSCAP
                        ? {
                              headerGroupComponent: 'expandCollapseRows',
                              headerGroupComponentParams: {
                                  expandAll: () => {
                                      this.gridOptions.api.expandAll();
                                  },
                                  collapseAll: () => {
                                      // we could use this.gridOptions.api.collapseAll() to collapse the all node too
                                      // this code below ensures the all node is expanded
                                      this.gridOptions.api.forEachNode(node => {
                                          if (node.id === totalsRowKey) {
                                              this.gridOptions.api.setRowNodeExpanded(node, true);
                                          } else {
                                              this.gridOptions.api.setRowNodeExpanded(node, false);
                                          }
                                      });
                                  },
                              },
                          }
                        : { headerName: '' }),
                    children: [
                        {
                            resizable: false,
                            headerName: toSentenceCase(this.$tkey('gridHeadings.promotions')),
                            minWidth: 300,
                            colId: 'grouped',
                            cellRenderer: 'agGroupCellRenderer',
                            cellRendererParams: {
                                selectedForAllocationPromotionIds: this
                                    .selectedForAllocationPromotionIds,
                                suppressCount: true,
                                isAllocationPage: true,
                                innerRenderer: 'promotionRagСolourRenderer',
                                innerRendererParams: {
                                    selectAllSCAP: this.releaseFlags.releaseFlags.selectAllSCAP,
                                    selectedIds: this.selectedForAllocationPromotionIds,
                                    promotionsForHierarchy: id => {
                                        if (id === totalsRowKey) {
                                            return this.filteredDataPromotions.map(
                                                p => p.promotionId
                                            );
                                        }
                                        return (
                                            get(this.filteredDataPromotionsByUnit, id) ||
                                            get(this.filteredDataPromotionsByCategory, id) ||
                                            []
                                        ).map(p => p.promotionId);
                                    },
                                    selection: (promotionId, value) => {
                                        if (!value) {
                                            this.selectedForAllocationPromotionIds = this.selectedForAllocationPromotionIds.filter(
                                                id => id !== promotionId
                                            );
                                        } else if (
                                            !includes(
                                                this.selectedForAllocationPromotionIds,
                                                promotionId
                                            )
                                        ) {
                                            this.selectedForAllocationPromotionIds.push(
                                                promotionId
                                            );
                                        }
                                        // looks like ag-grid somehow caching innerRendererParams (it is not documented)
                                        // but updates selectedForAllocationPromotionIds not propagate to promotionRagСolourRenderer by default
                                        // events are resolved this problem
                                        this.globalEmit(
                                            'selected-ids-updated',
                                            this.selectedForAllocationPromotionIds
                                        );
                                    },
                                    hierarchySelection: (id, value) => {
                                        const isAll = id === totalsRowKey;
                                        const promotions = this.filteredDataPromotions;
                                        let selectedPromotionIds = [];
                                        if (isAll) {
                                            selectedPromotionIds = uniq(
                                                promotions.map(p => p.promotionId)
                                            );
                                        } else {
                                            selectedPromotionIds = uniq(
                                                promotions
                                                    .filter(p => p.hierarchy.includes(id))
                                                    .map(p => p.promotionId)
                                            );
                                        }

                                        if (value) {
                                            this.selectedForAllocationPromotionIds = selectedPromotionIds;
                                        } else {
                                            this.selectedForAllocationPromotionIds = this.selectedForAllocationPromotionIds.filter(
                                                pid => !selectedPromotionIds.includes(pid)
                                            );
                                        }
                                        // looks like ag-grid somehow caching innerRendererParams (it is not documented)
                                        // but updates selectedForAllocationPromotionIds not propagate to promotionRagСolourRenderer by default
                                        // events are resolved this problem
                                        this.globalEmit(
                                            'selected-ids-updated',
                                            this.selectedForAllocationPromotionIds
                                        );
                                    },
                                },
                            },
                            // informs the grid to display row groups under this column
                            showRowGroup: true,
                        },
                    ],
                },
                // promotion info
                {
                    children: [
                        // pending notifications
                        ...(this.releaseFlags.releaseFlags.pendingChangesSCAPAlert && {
                            colId: 'pendingChanges',
                            width: 50,
                            headerName: toSentenceCase(this.$tkey('gridHeadings.parentChild')),
                            sorting: false,
                            cellRenderer: 'parentChildIconRenderer',
                            cellRendererParams: {
                                icon: 'parent-child-icon-red',
                                renderFunction: (params, expanded) => {
                                    const pendingChanges = params.data.pendingChanges;
                                    if (pendingChanges) {
                                        if (!expanded || params.data.promotionId) return true;
                                    }
                                    return false;
                                },
                            },
                            valueGetter: params => {
                                return params.data.pendingChanges;
                            },
                            filterParams: {
                                values: ['true', 'false'],
                                valueFormatter: params => {
                                    return toSentenceCase(
                                        this.$tkey(`pendingChanges.${params.value}`)
                                    );
                                },
                            },
                            filter: 'agSetColumnFilter',
                            menuTabs: ['filterMenuTab'],
                            suppressMenu: false,
                        }),
                        // forecasting/execution errors
                        ...(this.releaseFlags.releaseFlags.forecastingExecutionSCAPAlert && {
                            colId: 'forecastingExecutionErrors',
                            width: 50,
                            headerName: toSentenceCase(this.$tkey('gridHeadings.errors')),
                            sorting: false,
                            cellRenderer: 'executionErrorsDialogRenderer',
                            cellRendererParams: {
                                renderFunction: params => {
                                    const hasErrors =
                                        !isEmpty(params.data.executionErrors) ||
                                        !isEmpty(params.data.forecastingErrors) ||
                                        !isEmpty(params.data.inToolValidations);

                                    return hasErrors;
                                },
                            },
                            valueGetter: params => {
                                const errorList = uniq(
                                    [
                                        ...params.data.executionErrors,
                                        ...params.data.forecastingErrors,
                                        ...params.data.inToolValidations,
                                    ].map(e =>
                                        get(
                                            e,
                                            'errorSeverity',
                                            promotionErrorSeverities.WARNING
                                        ).toUpperCase()
                                    )
                                );
                                return isEmpty(errorList) ? null : errorList;
                            },
                            filterParams: {
                                values: [
                                    promotionErrorSeverities.ERROR,
                                    promotionErrorSeverities.WARNING,
                                    null, // handle "none" option for rows with no errors
                                ],
                                valueFormatter: params => {
                                    return toSentenceCase(
                                        this.$tkey(
                                            `forecastingExecutionErrors.${params.value || 'none'}`
                                        )
                                    );
                                },
                                valueGetter: params => {
                                    const allErrorSeverities = uniq(
                                        [
                                            ...params.data.executionErrors,
                                            ...params.data.forecastingErrors,
                                            ...params.data.inToolValidations,
                                        ].map(err =>
                                            get(
                                                err,
                                                'errorSeverity',
                                                promotionErrorSeverities.WARNING
                                            ).toUpperCase()
                                        )
                                    );
                                    return isEmpty(allErrorSeverities) ? null : allErrorSeverities;
                                },
                            },
                            filter: 'agSetColumnFilter',
                            menuTabs: ['filterMenuTab'],
                            suppressMenu: false,
                        }),
                        // promotion count
                        {
                            headerName: toSentenceCase(
                                this.$tkey('gridHeadings.numberOfPromotions')
                            ),
                            maxWidth: 80,
                            field: 'count',
                        },
                        // week span
                        {
                            maxWidth: 60,
                            headerName: toSentenceCase(this.$tkey('gridHeadings.weeks')),
                            field: 'week',
                        },
                        // mechanic descriprion
                        {
                            headerName: toSentenceCase(this.$tkey('gridHeadings.mechanic')),
                            field: 'mechanic',
                        },
                        {
                            headerName: toSentenceCase(this.$tkey('gridHeadings.SKUs')),
                            field: 'productsCount',
                            maxWidth: 80,
                            type: 'numericColumnCustom',
                        },
                        // tags
                        {
                            headerName: toSentenceCase(this.$tkey('gridHeadings.tags')),
                            field: 'tags',
                        },
                        // store groups
                        {
                            headerName: toSentenceCase(this.$tkey('gridHeadings.storeGroups')),
                            field: 'storeGroups',
                        },
                    ],
                },
                // channels
                {
                    headerName: toSentenceCase(this.$tkey('gridHeadings.channels')),
                    headerClass: 'ag-left-border-light',
                    children: [
                        // Channels (config driven) with a check mark in the box of the channel where the promotion applies.
                        ...values(promoResourcesEnum).map(resource => {
                            return {
                                maxWidth: 50,
                                field: 'resources',
                                headerComponent: 'promoAgIconHeader',
                                headerComponentParams: { iconComponent: resource },
                                sorting: false,
                                headerClass: 'ag-left-border-light',
                                cellClass: ['ag-left-border-light', 'ag-checkbox-center'],
                                cellRenderer: params => {
                                    if (!params.data.promotionId) return;
                                    const checked = (params.value || []).some(
                                        r => r.type === promoResourcesEnum[resource]
                                    );
                                    return `<input type="checkbox" disabled ${
                                        checked ? 'checked' : ''
                                    } />`;
                                },
                            };
                        }),
                    ],
                },
                // actions
                {
                    headerName: toSentenceCase(this.$tkey('gridHeadings.actions')),
                    headerClass: 'ag-left-border-dark',
                    maxWidth: 100,
                    children: [
                        // edit icon
                        {
                            colId: 'edit',
                            maxWidth: 50,
                            headerComponent: 'promoAgIconHeader',
                            headerComponentParams: { iconComponent: 'edit', unsortable: true },
                            headerClass: 'ag-left-border-dark',
                            cellClass: 'ag-left-border-dark',
                            cellRenderer: 'editIconRenderer',
                            cellRendererParams: {
                                onClick: params => {
                                    this.editButtonsIsDisabled = true;
                                    this.openDialog(null, params);
                                },
                                isDisabled: () => this.editButtonsIsDisabled,
                                conditionalRender: true,
                                conditionPath: 'promotionId',
                            },
                            flex: 1,
                        },
                        // delete icon
                        {
                            colId: 'delete',
                            maxWidth: 50,
                            cellClassRules: {
                                'ag-left-border-light': params => !!params.data.promotionId,
                                'is-child': params => !!params.data.parentPromotionId,
                            },
                            cellRenderer: 'deleteIconRenderer',
                            cellRendererParams: {
                                isDisabled: () => !this.toggleLogic[canDeleteParkingLotPromotion],
                                conditionalRender: true,
                                conditionPath: 'promotionId',
                                resource: parkingLot,
                                resourceId: 'promotionId',
                                isDeleteFromSubCampaignAllocation: true,
                            },
                            flex: 1,
                        },
                    ],
                },
                // Status
                {
                    headerName: toSentenceCase(this.$tkey('gridHeadings.status')),
                    headerClass: 'ag-left-border-dark',
                    maxWidth: 100,
                    children: [
                        // Workflow State
                        {
                            colId: 'workflowState',
                            width: 5,
                            resizable: false,
                            headerClass: 'ag-left-border-dark',
                            cellClass: ['ag-left-border-dark', 'workflow-cell'],
                            cellRenderer: 'iconRenderer',
                            valueGetter: params => {
                                const workflowStateIcon = mapWorkflowStateIcons({
                                    workflowState: params.data.workflowState,
                                    ...this.workflowStateIconsMap,
                                    releaseFlags: this.releaseFlags.releaseFlags,
                                });

                                return {
                                    icon: workflowStateIcon.icon,
                                    tooltipText: toSentenceCase(
                                        this.$t(
                                            `workflow.taskButton.states.${workflowStateIcon.text}`
                                        )
                                    ),
                                    order: workflowStateIcon.order,
                                };
                            },
                            keyCreator: params => params.value.icon,
                            filterParams: {
                                values: Object.values(this.workflowStateIconsMap.iconsStateMap).map(
                                    ({ icon }) => icon
                                ),
                                valueFormatter: params => {
                                    const icons = Object.values(
                                        this.workflowStateIconsMap.iconsStateMap
                                    );
                                    const icon = find(icons, { icon: params.value });
                                    return toSentenceCase(
                                        this.$t(`workflow.taskButton.states.${icon.text}`)
                                    );
                                },
                                suppressSorting: true, // Keeps the filter values in the order in the config
                            },
                            filter: 'agSetColumnFilter',
                            menuTabs: ['filterMenuTab'],
                            suppressMenu: false,
                            sortable: true,
                            comparator: (valueA, valueB) => valueA.order - valueB.order,
                        },
                    ],
                },
                // Notes
                {
                    headerName: toSentenceCase(this.$tkey('gridHeadings.notes')),
                    headerClass: 'ag-left-border-dark',
                    maxWidth: 50,
                    children: [
                        {
                            colId: 'workflowNotes',
                            maxWidth: 50,
                            resizable: false,
                            headerClass: 'ag-left-border-dark',
                            cellClass: 'ag-left-border-dark',
                            cellRenderer: 'notesRenderer',
                            cellRendererParams: {
                                conditionalRender: true,
                                conditionPath: 'notes',
                            },
                        },
                    ],
                },
                // forecasting data
                ...this.getDetailsColumns({
                    columnGroups: this.overviewPageConfig.subCampaign.detailColumns,
                    useDefinedFieldSource: false,
                }),
                // checkboxes
                {
                    headerClass: 'ag-left-border-dark',
                    children: [
                        {
                            width: 40,
                            flex: 1,
                            cellRenderer: 'buttonRenderer',
                            headerName: toSentenceCase(this.$tkey('gridHeadings.targets')),
                            cellRendererParams: {
                                clicked: (clickedId, isUnit) => {
                                    this.selectedHierarchyId = clickedId;
                                    this.isUnit = isUnit;
                                    this.openForecastsDialog();
                                },
                            },
                        },
                    ],
                },
            ];
        },

        selectedSubCampaignStoreGroupAttributeKeys() {
            return this.generateAttributeKeys(
                get(this.parkingLotFilters.selections.subCampaign, 'storeGroups', [])
            );
        },

        allProductData() {
            return {
                ...this.sumByFields(this.promotionsAggregationsData),
            };
        },

        allSubCampaignData() {
            return {
                ...this.sumByFields(this.subCampaignProductAggregationsData),
            };
        },

        allTargetsData() {
            return {
                ...this.sumByFields(this.targetsData.filter(td => td.hierarchyLevel === 1)),
            };
        },

        unitProductData() {
            return groupBy(this.promotionsAggregationsData, 'unitLevelEntryKey');
        },

        unitSubCampaignData() {
            return groupBy(this.subCampaignProductAggregationsData, 'unitLevelEntryKey');
        },

        unitTargetsData() {
            return groupBy(
                this.targetsData.filter(td => td.hierarchyLevel === this.hierarchyConfig.unitLevel),
                'unitLevelEntryKey'
            );
        },

        categoryTargetsDataByUnit() {
            return groupBy(
                this.targetsData.filter(
                    td => td.hierarchyLevel === this.hierarchyConfig.categoryLevel
                ),
                'unitLevelEntryKey'
            );
        },

        unitsData() {
            const data = {};
            const targetFieldsToCalculateFromCategoryLevel = get(
                this.overviewPageConfig.subCampaign.featureFlags,
                'fieldsToCalculateFromCategoryLevel',
                []
            );
            Object.entries(this.unitTargetsData || {}).forEach(([key, value]) => {
                if (data[key]) {
                    data[key].target = this.sumByFields(value);
                } else {
                    data[key] = { target: this.sumByFields(value) };
                }
                if (targetFieldsToCalculateFromCategoryLevel.length) {
                    const categoryTargetsSummed = this.sumByFields(
                        this.categoryTargetsDataByUnit[key]
                    );
                    targetFieldsToCalculateFromCategoryLevel.forEach(k => {
                        data[key].target[k] = categoryTargetsSummed[k];
                    });
                }
            });
            Object.entries(this.unitProductData || {}).forEach(([key, value]) => {
                if (data[key]) {
                    data[key].product = this.sumByFields(value);
                } else {
                    data[key] = { product: this.sumByFields(value) };
                }
            });
            Object.entries(this.unitSubCampaignData || {}).forEach(([key, value]) => {
                if (data[key]) {
                    data[key].current = this.sumByFields(value);
                } else {
                    data[key] = { current: this.sumByFields(value) };
                }
            });

            Object.keys(data || {}).forEach(key => {
                data[key].name = this.unitKeyToNameMap[key];
                data[key].templateKey = this.unitKeyToNameMap[key]
                    .split(' ')
                    .concat([key])
                    .join('')
                    .replace(/\./g, '');
            });
            if (!isEmpty(data)) {
                const allTargets = { ...this.allTargetsData };
                if (targetFieldsToCalculateFromCategoryLevel) {
                    const summedValues = this.sumByFields(
                        Object.values(data).map(({ target }) => target)
                    );
                    targetFieldsToCalculateFromCategoryLevel.forEach(field => {
                        allTargets[field] = summedValues[field];
                    });
                }
                data.all = {
                    name: toSentenceCase(this.$tkey('forecastsModal.all')),
                    templateKey: 'all',
                    product: this.allProductData,
                    target: allTargets,
                    current: this.allSubCampaignData,
                };
            }
            return data;
        },

        categoriesData() {
            const data = {};
            let keys = [];
            if (this.selectedHierarchyId) {
                keys = this.selectedHierarchyId.split(':');
            }
            const unitKey = keys[0];
            const catKey = keys[1];
            if (!this.categoryKeyToNameMap[catKey]) return data;

            get(this.categoryTargetsDataByUnit, [unitKey], []).forEach(td => {
                const key = td.hierarchyValue;
                if (data[key]) {
                    data[key].target = td;
                } else {
                    data[key] = { target: td };
                }
            });

            get(this.unitProductData, [unitKey], []).forEach(upd => {
                const key = upd.categoryLevelEntryKey;
                if (data[key]) {
                    data[key].product = upd;
                } else {
                    data[key] = { product: upd };
                }
            });

            get(this.unitSubCampaignData, [unitKey], []).forEach(scd => {
                const key = scd.categoryLevelEntryKey;
                if (data[key]) {
                    data[key].current = scd;
                } else {
                    data[key] = { current: scd };
                }
            });

            Object.keys(data || {}).forEach(key => {
                data[key].name = this.categoryKeyToNameMap[key];
                data[key].templateKey = this.categoryKeyToNameMap[key]
                    .split(' ')
                    .concat([key])
                    .join('')
                    .replace(/\./g, '');
            });

            data.all = {
                name: this.unitKeyToNameMap[unitKey],
                templateKey: 'all',
                product: this.sumByFields(this.unitProductData[unitKey]),
                target: this.overviewPageConfig.subCampaign.featureFlags
                    .useUnitTargetsAtCategoryView
                    ? this.unitsData[unitKey].target
                    : this.sumByFields(this.categoryTargetsDataByUnit[unitKey]),
                current: this.sumByFields(this.unitSubCampaignData[unitKey]),
            };
            return data;
        },

        parentChildNotifications() {
            return this.getNotificationsForScenario(
                get(this.parkingLotFilters, 'selections.scenario._id', null)
            ).filter(n => n.notificationKey === notificationTypes.parentPromotionUpdated);
        },

        hasNotifications() {
            return !isEmpty(this.parentChildNotifications);
        },

        isExecutingPromotionsWithPendingNotifications() {
            if (!this.hasNotifications) return false;

            // Get all promotions with pending notifications for the current scenario
            const promotionsWithPendingNotifications = uniqBy(
                this.parentChildNotifications,
                notification => get(notification, 'details.entityIds.promotionId')
            ).map(notification => get(notification, 'details.entityIds.promotionId'));

            // Check if any of the promotions with pending notifications are also being executed or split
            // This should prevent users from applying all notifications if one of the promos affected is being submitted to execution,
            // so we avoid concurrency issues.
            return this.promotions.some(
                promotion =>
                    promotion.execution &&
                    promotion.execution.executionId &&
                    promotion.splitInProgress &&
                    includes(promotionsWithPendingNotifications, promotion._id)
            );
        },

        filteredDataPromotionsByUnit() {
            const filteredPromotionsWithUnitId = this.filteredGridData
                .filter(row => row.promotionId)
                .map(p => ({ ...p, unitId: p.hierarchy[this.hierarchyConfig.unitLevel] }));
            return groupBy(filteredPromotionsWithUnitId, 'unitId');
        },

        filteredDataPromotionsByCategory() {
            const filteredPromotionsWithCategoryId = this.filteredGridData
                .filter(row => row.promotionId)
                .map(p => ({ ...p, categoryId: p.hierarchy[this.hierarchyConfig.categoryLevel] }));
            return groupBy(filteredPromotionsWithCategoryId, 'categoryId');
        },

        filteredDataPromotions() {
            return this.filteredGridData.filter(row => row.promotionId);
        },

        splitPromotionsById() {
            return this.promotions.filter(p => p.splitPromotion).map(p => p._id);
        },
        debouncedHandler() {
            return debounce(this.promotionHandler, 200);
        },
    },
    // load necessary promotions on tab switch considering applied filters
    watch: {
        async $route() {
            if (
                this.$route.name === subCampaignAllocationView &&
                this.parkingLotFilters.selections.subCampaign
            ) {
                this.editPromotionId = null;
                this.selectedForAllocationPromotionIds = [];
                await Promise.all([
                    this.loadPromotions(),
                    this.fetchAggregations(),
                    this.fetchSubCampaignProductAggregations(),
                ]);

                this.isFilterChanged = true;
                this.promotionHandler();
            }
        },
        promotions: {
            handler(value) {
                let force = false;
                if (value && value.length && value.length !== this.promoCount) {
                    setTimeout(() => {
                        this.promoCount = value.length;
                    }, 1000); 
                    force = true;
                }
                
                this.promotionHandler(force);
            },
            deep: true,
        },
    },
    beforeDestroy() {
        this.gridOptions.api.removeEventListener('filterChanged');
    },

    methods: {
        get,
        ...mapActions('subCampaigns', ['fetchSubCampaignWithDependencies']),
        ...mapActions('storeGroups', ['fetchStoreGroups']),
        ...mapActions('targets', ['fetchTargetsAggregations']),
        ...mapActions('promotions', [
            'fetchPromotionsAggregations',
            'downloadPromotionsForScenario',
            'addParentPromotionToState',
        ]),
        ...mapActions('promotions', ['setSelectedPromotion']),
        ...mapMutations('promotions', ['setUnsavedPromotion', 'setAddProductsInProgress']),
        promotionHandler(force) {
            if (this.gridApi && !this.isFilterChanged && !force) {
                let tRowData = [];
                if (isEmpty(this.filteredGridData)) {
                    this.gridApi.forEachNode(node => tRowData.push(node.data));
                } else {
                    tRowData = this.filteredGridData;
                }
                const promoIds = tRowData.map(row => row.promotionId);
                const allData = this.getRowData(promoIds);
                if (isEmpty(tRowData)) {
                    this.gridData = allData;
                    this.filteredGridData = allData;
                } else {
                    const added = differenceBy(allData, tRowData, 'id');
                    const removed = differenceBy(tRowData, allData, 'id');
                    const updated = intersectionBy(allData, tRowData, 'id');
                    this.filteredGridData = allData;
                    this.gridApi.applyTransactionAsync({
                        add: added,
                        remove: removed,
                        update: updated,
                    });
                }
            } else {
                const allData = this.getRowData();
                this.gridData = allData;
                this.filteredGridData = allData;
                this.isFilterChanged = false;
            }
        },
        onGridReady(params) {
            this.gridApi = params.api;
            setTimeout(() => {
                // Listen to column filters so we can update the charts according to
                // the products being displayed in the grid
                this.gridOptions.api.addEventListener('filterChanged', e => {
                    const filterModel = e.api.getFilterModel();
                    if (isEmpty(filterModel)) {
                        const allData = this.getRowData();
                        this.gridData = allData;
                        this.filteredGridData = allData;
                        return;
                    }
                    let promotionIdsOnDisplayAfterFilter = [];
                    let promotionIdsOnDisplayAfterPendingChangesFilter = [];
                    let promotionIdsOnDisplayAfterForecastingExecutionErrorsFilter = [];
                    const promotionIdsOnDisplayAfterWorkflowFilter = [];
                    if (has(filterModel, 'pendingChanges')) {
                        const filterValues = filterModel.pendingChanges.values;
                        promotionIdsOnDisplayAfterPendingChangesFilter = this.promotions
                            .filter(p =>
                                filterValues.includes(
                                    String(get(p, 'notification.pendingChanges', false))
                                )
                            )
                            .map(p => p._id);
                    }

                    if (has(filterModel, 'forecastingExecutionErrors')) {
                        const filterValues = filterModel.forecastingExecutionErrors.values;
                        promotionIdsOnDisplayAfterForecastingExecutionErrorsFilter = this.gridData
                            .filter(p => {
                                const allErrorSeverities = uniq(
                                    [
                                        ...p.executionErrors,
                                        ...p.forecastingErrors,
                                        ...p.inToolValidations,
                                    ].map(err =>
                                        get(
                                            err,
                                            'errorSeverity',
                                            promotionErrorSeverities.WARNING
                                        ).toUpperCase()
                                    )
                                );
                                return filterValues.some(
                                    value =>
                                        allErrorSeverities.includes(value) ||
                                        (!value && isEmpty(allErrorSeverities))
                                );
                            })
                            .map(p => p.promotionId);
                    }

                    if (has(filterModel, 'workflowState')) {
                        e.api.forEachNodeAfterFilter(n => {
                            if (n.data.promotionId) {
                                promotionIdsOnDisplayAfterWorkflowFilter.push(n.data.promotionId);
                            }
                        });
                    }

                    let dynamicFilterPromotionIds = [];
                    if (
                        !isEmpty(promotionIdsOnDisplayAfterPendingChangesFilter) &&
                        !isEmpty(promotionIdsOnDisplayAfterForecastingExecutionErrorsFilter)
                    ) {
                        dynamicFilterPromotionIds = intersection(
                            promotionIdsOnDisplayAfterForecastingExecutionErrorsFilter,
                            promotionIdsOnDisplayAfterPendingChangesFilter
                        );
                    } else {
                        dynamicFilterPromotionIds = !isEmpty(
                            promotionIdsOnDisplayAfterPendingChangesFilter
                        )
                            ? promotionIdsOnDisplayAfterPendingChangesFilter
                            : promotionIdsOnDisplayAfterForecastingExecutionErrorsFilter;
                    }

                    if (
                        !isEmpty(dynamicFilterPromotionIds) &&
                        !isEmpty(promotionIdsOnDisplayAfterWorkflowFilter)
                    ) {
                        promotionIdsOnDisplayAfterFilter = intersection(
                            promotionIdsOnDisplayAfterWorkflowFilter,
                            dynamicFilterPromotionIds
                        );
                    } else {
                        promotionIdsOnDisplayAfterFilter = !isEmpty(dynamicFilterPromotionIds)
                            ? dynamicFilterPromotionIds
                            : promotionIdsOnDisplayAfterWorkflowFilter;
                    }
                    const updatedGridData = this.getRowData(promotionIdsOnDisplayAfterFilter);
                    const added = differenceBy(updatedGridData, this.filteredGridData, 'id');
                    const removed = differenceBy(this.filteredGridData, updatedGridData, 'id');
                    const updated = intersectionBy(updatedGridData, this.filteredGridData, 'id');
                    this.filteredGridData = updatedGridData;
                    this.gridOptions.api.applyTransaction({
                        add: added,
                        remove: removed,
                        update: updated,
                    });
                });
            }, 200);
        },

        getRowData(promotionIdsOnDisplayAfterFilter = []) {
            if (this.renderEmptyGrid) return [];

            let promotionRows = [
                // row that shows totals for all categories
                {
                    id: totalsRowKey,
                    description: this.totalsRowName,
                    hierarchy: [totalsRowKey],
                    count: 0,
                    productsCount: 0,
                    products: [],
                    pendingChanges: false,
                    executionErrors: [],
                    forecastingErrors: [],
                    inToolValidations: [],
                },
            ];
            const units = {};
            const categories = {};

            let promotions = this.getPromotionsByScenarioId(
                this.parkingLotFilters.selections.scenario._id
            );

            if (!isEmpty(promotionIdsOnDisplayAfterFilter)) {
                promotions = promotions.filter(p =>
                    promotionIdsOnDisplayAfterFilter.includes(p._id)
                );
            }

            // If scenario is empty show only 'all' row with 0 count
            if (!promotions) return promotionRows;

            const storeWidePromotions = [];
            const categoryWidePromotions = [];

            // We separate all the products by their unit and category
            promotions.forEach(promotion => {
                if (isStoreWidePromotion(promotion)) {
                    storeWidePromotions.push(promotion);
                    return;
                }

                if (isCategoryWidePromotion(promotion)) {
                    categoryWidePromotions.push(promotion);
                    return;
                }

                const {
                    unitRows,
                    categoryRows,
                    promotionSeparatedByUnitCategory,
                } = this.separateProductsByHierarchy(promotion);

                if (!unitRows || !categoryRows || !promotionSeparatedByUnitCategory) return;

                promotionRows[0].count += 1;
                promotionRows[0].products.push(...promotion.products);
                promotionRows[0].productsCount = promotionRows[0].products.length;
                promotionRows[0].pendingChanges =
                    promotionRows[0].pendingChanges ||
                    get(promotion, 'notification.pendingChanges', false);
                const executionErrors = get(promotion, 'execution.errors', []) || [];
                promotionRows[0].executionErrors.push(...executionErrors);
                const deleteError = get(promotion, 'execution.deleteError');
                if (deleteError) {
                    promotionRows[0].executionErrors.push(deleteError);
                }
                const forecastingErrors =
                    get(promotion, 'forecastingAggregations.errors', []) || [];
                promotionRows[0].forecastingErrors.push(...forecastingErrors);
                promotionRows[0].inToolValidations.push(
                    ...this.getInToolValidations({
                        promotionId: promotion._id,
                    })
                );

                unitRows.forEach(u => {
                    if (!units[u.id]) {
                        units[u.id] = { ...u, count: 1 };
                    } else {
                        units[u.id].count += 1;
                        units[u.id].products = units[u.id].products.concat(u.products);
                        units[u.id].pendingChanges = units[u.id].pendingChanges || u.pendingChanges;
                        units[u.id].executionErrors = units[u.id].executionErrors.concat(
                            u.executionErrors
                        );
                        units[u.id].forecastingErrors = units[u.id].forecastingErrors.concat(
                            u.forecastingErrors
                        );
                        units[u.id].inToolValidations = units[u.id].inToolValidations.concat(
                            u.inToolValidations
                        );
                    }
                    units[u.id].productsCount = units[u.id].products.length;
                });

                categoryRows.forEach(c => {
                    if (!categories[c.id]) {
                        categories[c.id] = { ...c, count: 1 };
                    } else {
                        categories[c.id].count += 1;
                        categories[c.id].products = categories[c.id].products.concat(c.products);
                        categories[c.id].pendingChanges =
                            categories[c.id].pendingChanges || c.pendingChanges;
                        categories[c.id].executionErrors = categories[c.id].executionErrors.concat(
                            c.executionErrors
                        );
                        categories[c.id].forecastingErrors = categories[
                            c.id
                        ].forecastingErrors.concat(c.forecastingErrors);
                        categories[c.id].inToolValidations = categories[
                            c.id
                        ].inToolValidations.concat(c.inToolValidations);
                    }
                    categories[c.id].productsCount = categories[c.id].products.length;
                });

                promotionRows = promotionRows.concat(promotionSeparatedByUnitCategory);
            });

            if (!isEmpty(storeWidePromotions)) {
                promotionRows[0].count += storeWidePromotions.length;

                promotionRows.push({
                    id: storeWideRowKey,
                    description: this.storeWideRowName,
                    hierarchy: [totalsRowKey, storeWideRowKey],
                    count: storeWidePromotions.length,
                    productsCount: '',
                    products: [],
                });

                promotionRows = promotionRows.concat(
                    storeWidePromotions.map(promotion => {
                        return {
                            id: `${storeWideRowKey}:${promotion._id}`,
                            description: promotion.name,
                            promotionId: promotion._id,
                            keys: [storeWideRowKey, promotion._id],
                            week: this.getWeekLabel(promotion.startDate, promotion.endDate),
                            effectivenessRating: promotion.effectivenessRating,
                            hierarchy: [totalsRowKey, storeWideRowKey, promotion._id],
                            mechanic: promotion.offerMechanic.description || '',
                            storeGroups: promotion.storeGroups.map(sg => sg.description).join(', '),
                            resources: promotion.resources,
                            productsCount: '',
                            tags: promotion.tags.map(sg => sg.tagName).join(', '),
                            isGhost: promotion.isGhost,
                            parentPromotionId: promotion.parentPromotionId,
                            workflowState: promotion.workflowState,
                            notes: get(promotion, 'notes', []) || [],
                        };
                    })
                );
            }

            if (!isEmpty(categoryWidePromotions)) {
                promotionRows[0].count += categoryWidePromotions.length;

                promotionRows.push({
                    id: categoryWideRowKey,
                    description: this.categoryWideRowName,
                    hierarchy: [totalsRowKey, categoryWideRowKey],
                    count: categoryWidePromotions.length,
                    productsCount: '',
                    products: [],
                });

                promotionRows = promotionRows.concat(
                    categoryWidePromotions.map(promotion => {
                        return {
                            id: `${categoryWideRowKey}:${promotion._id}`,
                            description: promotion.name,
                            promotionId: promotion._id,
                            keys: [categoryWideRowKey, promotion._id],
                            week: this.getWeekLabel(promotion.startDate, promotion.endDate),
                            effectivenessRating: promotion.effectivenessRating,
                            hierarchy: [totalsRowKey, categoryWideRowKey, promotion._id],
                            mechanic: promotion.offerMechanic.description || '',
                            storeGroups: promotion.storeGroups.map(sg => sg.description).join(', '),
                            resources: promotion.resources,
                            productsCount: '',
                            tags: promotion.tags.map(sg => sg.tagName).join(', '),
                            isGhost: promotion.isGhost,
                            parentPromotionId: promotion.parentPromotionId,
                            workflowState: promotion.workflowState,
                            notes: get(promotion, 'notes', []) || [],
                        };
                    })
                );
            }

            const {
                allRowWithForecasts,
                unitRowsWithForecasts,
                categoryRowsWithForecasts,
            } = this.getAggregatedRowsWithForecastData(promotionRows[0], units, categories);

            // row with all aggregated info
            promotionRows[0] = allRowWithForecasts;
            // rows with unit info
            if (!isEmpty(unitRowsWithForecasts)) {
                Object.values(unitRowsWithForecasts).forEach(u => promotionRows.push(u));
            }
            // rows with category info
            if (!isEmpty(categoryRowsWithForecasts)) {
                Object.values(categoryRowsWithForecasts).forEach(c => promotionRows.push(c));
            }
            return promotionRows;
        },

        async afterAllocation() {
            const allocatedPromos = this.selectedForAllocationPromotionIds.map(id =>
                this.getPromotionById(id)
            );
            this.globalEmit('allocate-promotions', allocatedPromos);
            this.selectedForAllocationPromotionIds = [];
            await this.loadPromotions();
        },

        separateProductsByHierarchy(promotion) {
            // If promotion has no products/categories (categories are in sync with products) - do not include it in the list
            if (isEmpty(promotion.products)) return {};

            // If promotion has only one category - no need to separate products by category and recalculate forecasting data
            if (promotion.categories.length === 1) {
                return this.singleCategoryPromotionCase(promotion);
            }

            // if promotion spans across several units/categories we filter products by unit and category
            return this.multipleCategoriesPromotionCase(promotion);
        },

        singleCategoryPromotionCase(promotion) {
            // Getting unit
            const {
                levelEntryDescription: unitLevelName,
                levelEntryKey: unitKey,
            } = promotion.products[0].hierarchy.find(
                h => h.level === this.hierarchyConfig.unitLevel
            );
            // Getting category
            const {
                levelEntryDescription: categoryLevelName,
                levelEntryKey: categoryKey,
            } = promotion.products[0].hierarchy.find(
                h => h.level === this.hierarchyConfig.categoryLevel
            );

            const pendingChanges = get(promotion, 'notification.pendingChanges', false);
            let executionErrors = get(promotion, 'execution.errors', []) || [];
            const deleteError = get(promotion, 'execution.deleteError');
            if (deleteError) {
                executionErrors = [...executionErrors, deleteError];
            }
            const forecastingErrors = get(promotion, 'forecastingAggregations.errors', []) || [];
            const inToolValidations = this.getInToolValidations({ promotionId: promotion._id });
            const notes = get(promotion, 'notes', []) || [];

            return {
                // array of units in the promotion
                unitRows: [
                    {
                        id: unitKey,
                        hierarchy: [totalsRowKey, unitKey],
                        products: promotion.products,
                        description: unitLevelName,
                        pendingChanges,
                        executionErrors,
                        forecastingErrors,
                        inToolValidations,
                    },
                ],
                // array of categories in the promotion
                categoryRows: [
                    {
                        id: `${unitKey}:${categoryKey}`,
                        hierarchy: [totalsRowKey, unitKey, categoryKey],
                        products: promotion.products,
                        description: categoryLevelName,
                        pendingChanges,
                        executionErrors,
                        forecastingErrors,
                        inToolValidations,
                    },
                ],
                promotionSeparatedByUnitCategory: [
                    {
                        id: `${unitKey}:${categoryKey}:${promotion._id}`,
                        description: promotion.name,
                        promotionId: promotion._id,
                        keys: [unitKey, categoryKey, promotion._id],
                        week: this.getWeekLabel(promotion.startDate, promotion.endDate),
                        effectivenessRating: promotion.effectivenessRating,
                        hierarchy: [totalsRowKey, unitKey, categoryKey, promotion._id],
                        mechanic: promotion.offerMechanic.description || '',
                        storeGroups: promotion.storeGroups.map(sg => sg.description).join(', '),
                        resources: promotion.resources,
                        productsCount: promotion.products.length,
                        tags: promotion.tags.map(sg => sg.tagName).join(', '),
                        isGhost: promotion.isGhost,
                        parentPromotionId: promotion.parentPromotionId,
                        workflowState: promotion.workflowState,
                        notes,
                        pendingChanges,
                        executionErrors,
                        forecastingErrors,
                        inToolValidations,

                        ...this.calculateProductsTotal(promotion.products),
                    },
                ],
            };
        },

        handleDivision(dividend, divisor) {
            if (divisor === 0) return 0;
            return dividend / divisor || 0;
        },

        sumByFields(arrayToSum) {
            const fieldsToSum = [];
            this.overviewPageConfig.subCampaign.forecastsModal.forEach(field => {
                if (!field.onTheFly) fieldsToSum.push(field.fieldName);
            });

            const summedFields = fieldsToSum.reduce((acc, field) => {
                acc[field] = sumBy(arrayToSum, field);
                return acc;
            }, {});

            this.addOnTheFlyFields(summedFields);

            return summedFields;
        },

        multipleCategoriesPromotionCase(promotion) {
            const productsByUnitCategory = {};
            const categoryRows = {};
            const unitRows = {};

            const pendingChanges = get(promotion, 'notification.pendingChanges', false);
            let executionErrors = get(promotion, 'execution.errors', []) || [];
            const deleteError = get(promotion, 'execution.deleteError');
            if (deleteError) {
                executionErrors = [...executionErrors, deleteError];
            }
            const forecastingErrors = get(promotion, 'forecastingAggregations.errors', []) || [];
            const inToolValidations = this.getInToolValidations({ promotionId: promotion._id });
            const notes = get(promotion, 'notes', []) || [];

            promotion.products.forEach(product => {
                const {
                    levelEntryDescription: unitLevelName,
                    levelEntryKey: unitKey,
                } = product.hierarchy.find(h => h.level === this.hierarchyConfig.unitLevel);

                const {
                    levelEntryDescription: categoryLevelName,
                    levelEntryKey: categoryKey,
                } = product.hierarchy.find(h => h.level === this.hierarchyConfig.categoryLevel);

                // ignore product that doesn't have any hierarchy level
                if (unitKey && categoryKey) {
                    // adding unit info
                    if (!unitRows[unitKey]) {
                        unitRows[unitKey] = {
                            id: unitKey,
                            hierarchy: [totalsRowKey, unitKey],
                            products: [product],
                            description: unitLevelName,
                            pendingChanges,
                            executionErrors,
                            forecastingErrors,
                            inToolValidations,
                        };
                    } else {
                        unitRows[unitKey].products.push(product);
                    }

                    // adding category info
                    const categoryId = `${unitKey}:${categoryKey}`;
                    if (!categoryRows[categoryId]) {
                        categoryRows[categoryId] = {
                            id: categoryId,
                            hierarchy: [totalsRowKey, unitKey, categoryKey],
                            products: [product],
                            description: categoryLevelName,
                            pendingChanges,
                            executionErrors,
                            forecastingErrors,
                            inToolValidations,
                        };
                    } else {
                        categoryRows[categoryId].products.push(product);
                    }

                    // adding promotion containing only unit.category products
                    // if it's not promotion's first occurrence - add the product to the array
                    const id = `${unitKey}:${categoryKey}:${promotion._id}`;
                    if (!productsByUnitCategory[id]) {
                        productsByUnitCategory[id] = {
                            id,
                            keys: [unitKey, categoryKey, promotion._id],
                            products: [product],
                            hierarchy: [totalsRowKey, unitKey, categoryKey, promotion._id],
                            description: promotion.name,
                        };
                    } else {
                        productsByUnitCategory[id].products.push(product);
                    }
                }
            });

            const promotionSeparatedByUnitCategory = [];
            const promotionRowData = {
                promotionId: promotion._id,
                description: promotion.name,
                week: this.getWeekLabel(promotion.startDate, promotion.endDate),
                mechanic: promotion.offerMechanic.description || '',
                effectivenessRating: promotion.effectivenessRating,
                storeGroups: promotion.storeGroups.map(sg => sg.description).join(', '),
                resources: promotion.resources,
                productsCount: promotion.products.length,
                tags: promotion.tags.map(sg => sg.tagName).join(', '),
                isGhost: promotion.isGhost,
                parentPromotionId: promotion.parentPromotionId,
                workflowState: promotion.workflowState,
                pendingChanges,
                executionErrors,
                forecastingErrors,
                inToolValidations,
                notes,
            };

            // Creating a promotion instance for each unit/category incide the promotion and then separating products and forecasting data
            Object.values(productsByUnitCategory).forEach(p => {
                promotionSeparatedByUnitCategory.push({
                    ...promotionRowData,
                    id: p.id,
                    hierarchy: p.hierarchy,
                    ...this.calculateProductsTotal(p.products),
                });
            });

            return {
                // array of units in the promotion
                unitRows: Object.values(unitRows).map(u => u),
                // array of categories in the promotion
                categoryRows: Object.values(categoryRows).map(c => c),
                promotionSeparatedByUnitCategory,
            };
        },

        calculateProductsTotal(products = []) {
            const childColumns = this.overviewPageConfig.subCampaign.detailColumns.flatMap(
                detailColumn => detailColumn.children
            );

            const productTotalsSeed = childColumns.reduce((acc, column) => {
                acc[column.header || column.fieldName] = undefined;
                return acc;
            }, {});

            return products.reduce((acc, product) => {
                childColumns.forEach(
                    ({ header, fieldName, source, onTheFly, onTheFlyFunction, onTheFlyParams }) => {
                        const accValue = acc[header || fieldName];
                        const newValue = get(product, `${source}.${fieldName}`);

                        if (onTheFly) {
                            acc[header || fieldName] = this[onTheFlyFunction].call(
                                null,
                                ...onTheFlyParams.map(param => {
                                    return acc[param];
                                })
                            );
                        } else if (!isNil(accValue) || !isNil(newValue)) {
                            // If both values are undefined or null, then persist
                            // the undefined value. Otherwise sum the two fields
                            // treating undefined as 0.
                            acc[header || fieldName] = add(accValue, newValue);
                        }
                    }
                );
                return acc;
            }, productTotalsSeed);
        },

        getAggregatedRowsWithForecastData(all, units, categories) {
            const allRowWithForecasts = {
                ...all,
                ...this.calculateProductsTotal(all.products),
            };
            delete all.products;

            const unitRowsWithForecasts = Object.values(units).map(unit => {
                const products = unit.products;
                delete unit.products;
                return {
                    ...unit,
                    ...this.calculateProductsTotal(products),
                };
            });

            const categoryRowsWithForecasts = Object.values(categories).map(category => {
                const products = category.products;
                delete category.products;
                return {
                    ...category,
                    ...this.calculateProductsTotal(products),
                };
            });

            return {
                allRowWithForecasts,
                unitRowsWithForecasts,
                categoryRowsWithForecasts,
            };
        },

        openAllocationDialog() {
            this.$refs['promotion-allocation-dialog'].openDialog();
        },

        closePromotionViewerDialog() {
            this.setUnsavedPromotion({
                namespace: this.editPromotionId,
                tab: 'all',
                value: false,
            });
            this.setAddProductsInProgress(false);
            this.editPromotionId = null;
            this.editButtonsIsDisabled = false;
        },

        openForecastsDialog() {
            this.$refs['forecasts-dialog'].openDialog();
        },

        async openDialog(event, rowData = {}) {
            this.editPromotionId = rowData.promotionId;
            const { parentPromotionId } = rowData;
            // get parent promotion if it is not in state
            if (parentPromotionId && !this.getParentPromotionsKeyedById[parentPromotionId]) {
                await this.addParentPromotionToState({ promotionId: parentPromotionId });
            }
            await this.setSelectedPromotion({ promotionId: this.editPromotionId || null });
            this.$nextTick(() => {
                // Need to use nextTick because editPromotionId is updated
                // and dialog key depends on editPromotionId
                this.$refs['promotion-viewer-dialog'].openDialog();
            });
        },

        dialogPromotionGetter() {
            if (!this.editPromotionId) {
                return {};
            }
            return this.getPromotionById(this.editPromotionId);
        },

        /**
         * Some workflow tasks are only allowed for promos that are not in-flight
         */
        disableTaskInFlightPromotions({ promotions }) {
            return promotions.some(promotionId => {
                const { startDate } = this.getPromotionById(promotionId);
                const promoStartDate = this.$moment(startDate);
                const currentDate = this.$moment().startOf('day');
                const diffBetweenCurrentDateAndStartDate = promoStartDate.diff(currentDate, 'days');

                return (
                    diffBetweenCurrentDateAndStartDate <=
                    this.toggleLogic[disablePromotionEditingDaysBeforeStart]
                );
            });
        },

        /**
         * Filter the parking lot promotions based on user selections in the filter
         */
        async handleSelectorChange() {
            if (this.canRenderGrid) {
                this.gridOptions.api.deselectAll();
            }

            this.selectedForAllocationPromotionIds = [];
            this.filteredGridData = [];

            if (
                !this.parkingLotFilters.selections.subCampaign ||
                !this.parkingLotFilters.selections.scenario
            ) {
                return;
            }
            // We need reset ag-grid filters and call full data refresh in case
            // if top filters were changed this flag will help controll it
            this.isFilterChanged = true;
            await Promise.all([this.loadPromotions(), this.fetchAggregations()]);
        },

        async fetchAggregations() {
            await this.fetchRelevantStoreGroups();
            await Promise.all([this.fetchTargetsData(), this.fetchPromotionsProductAggregations()]);
        },

        async fetchRelevantStoreGroups() {
            this.storeGroups = await this.fetchStoreGroups({
                params: {
                    where: {
                        attributes: {
                            $elemMatch: {
                                key: { $in: this.selectedSubCampaignStoreGroupAttributeKeys },
                            },
                        },
                    },
                },
                returnDocuments: true,
            });
        },

        async fetchTargetsData() {
            const storeGroupAttributeFilters = getStoreGroupAttributeFilters({
                storeGroups: this.storeGroups,
            });

            const targetsData = await this.fetchTargetsAggregations({
                params: {
                    where: {
                        ...storeGroupAttributeFilters,
                        hierarchyLevel: {
                            $in: [
                                this.hierarchyConfig.categoryLevel,
                                this.hierarchyConfig.unitLevel,
                            ],
                        },
                    },
                    minDate: this.getWeekStartDate(
                        this.parkingLotFilters.selections.subCampaign.startDate
                    ).format('YYYY-MM-DD'),
                    maxDate: this.getStartOfNextWeek(
                        this.parkingLotFilters.selections.subCampaign.endDate
                    ).format('YYYY-MM-DD'),
                },
            });
            const targetsDataWithUnit = targetsData.map(target => {
                if (target.hierarchyLevel === this.hierarchyConfig.unitLevel) {
                    target.unitLevelEntryKey = target.hierarchyValue;
                } else {
                    target.unitLevelEntryKey = get(
                        this.getAllCategories.find(
                            cat => cat.levelEntryKey === target.hierarchyValue
                        ),
                        'parentId'
                    );
                }
                return target;
            });

            // Make sure we have atleast access to one category in a unit
            this.targetsData = targetsDataWithUnit.filter(target => {
                return (
                    (target.hierarchyLevel === this.hierarchyConfig.unitLevel &&
                        !isEmpty(this.getUnitToCategoriesMap({})[target.hierarchyValue])) ||
                    (target.hierarchyLevel === this.hierarchyConfig.categoryLevel &&
                        this.userCategories.find(
                            cat => cat.levelEntryKey === target.hierarchyValue
                        ))
                );
            });
        },

        async fetchPromotionsProductAggregations() {
            const storeGroupKeys = this.storeGroups.map(sg => sg.key);
            const promotionsAggregationsData = await this.fetchPromotionsAggregations({
                params: {
                    where: {
                        storeGroups: {
                            $elemMatch: {
                                key: {
                                    $in: storeGroupKeys,
                                },
                            },
                        },
                    },
                    minDate: this.getWeekStartDate(
                        this.parkingLotFilters.selections.subCampaign.startDate
                    ).format('YYYY-MM-DD'),
                    maxDate: this.getStartOfNextWeek(
                        this.parkingLotFilters.selections.subCampaign.endDate
                    ).format('YYYY-MM-DD'),
                    onlyFavoriteScenarios: true,
                },
            });

            promotionsAggregationsData.forEach(promotionsAggregation => {
                this.addOnTheFlyFields(promotionsAggregation);
            });

            this.promotionsAggregationsData = promotionsAggregationsData;
        },

        async fetchSubCampaignProductAggregations() {
            const scenarioIds = get(this.parkingLotFilters.options, 'scenarioOptions', [])
                .filter(scr => scr.isFavourite)
                .map(scr => scr._id);
            const subCampaignAggregations = await this.fetchPromotionsAggregations({
                params: {
                    where: {
                        scenarioId: { $in: scenarioIds },
                    },
                },
            });

            subCampaignAggregations.forEach(subCampaign => {
                this.addOnTheFlyFields(subCampaign);
            });

            this.subCampaignProductAggregationsData = subCampaignAggregations;
        },

        async loadPromotions() {
            const promotionFields = [
                '_id',
                'name',
                'storeGroups',
                'resources',
                'workflowState',
                'execution',
                'clientState',
                'startDate',
                'endDate',
                'tags',
                'scenarioId',
                'categories',
                'forecasts',
                'forecastingAggregations',
                'offerMechanic.description',
                'effectivenessRating',
                'products',
                'isGhost',
                'parentPromotionId',
                'productOfferGroups',
                'notification',
                'notes',
                'splitPromotion',
                'splitInProgress',
            ];
            await this.fetchSubCampaignWithDependencies({
                subCampaign: {
                    _id: this.parkingLotFilters.selections.subCampaign._id,
                    parentId: this.parkingLotFilters.selections.subCampaign.parentId,
                    categories: this.parkingLotFilters.selections.subCampaign.categories,
                },
                patchState: false,
                promotionFields,
                scenario: this.parkingLotFilters.selections.scenario,
            });
        },

        getWeekStartDate(date) {
            return this.$moment(date).startOf('isoWeek');
        },

        getStartOfNextWeek(date) {
            const convertedDate = this.getWeekStartDate(date);
            return convertedDate.add(1, 'weeks');
        },

        generateAttributeKeys(storeGroups) {
            return storeGroups.reduce((acc, storeGroup) => {
                const attributes = get(storeGroup, 'attributes', []);
                acc.push(...attributes.map(attr => attr.key));
                return acc;
            }, []);
        },

        addOnTheFlyFields(data) {
            this.overviewPageConfig.subCampaign.forecastsModal
                .filter(field => field.onTheFly)
                .forEach(field => {
                    data[field.fieldName] = this[field.onTheFlyFunction].call(
                        null,
                        ...field.onTheFlyParams.map(param => {
                            return data[param];
                        })
                    );
                });
        },

        async onExport() {
            const scenario = this.parkingLotFilters.selections.scenario;
            const subCampaign = this.getSubCampaignById({
                _id: scenario.subCampaignId,
                usePluralResourceName: true,
            });

            return this.downloadPromotionsForScenario({
                params: {
                    export: true,
                    exportFormat: exportFormats.excel,
                    exportSchema: 'promotions-for-scenario-flat',
                    fieldToUnwind: 'productOfferGroups',
                    campaignId: subCampaign.campaignId,
                    subCampaignId: scenario.subCampaignId,
                    scenarioId: scenario._id,
                },
            });
        },
        toggleActionButtons(disabled) {
            this.isActionsDisabled = disabled;
        },
        onActionButtonClick() {
            this.isActionsMenuOpen = false;
            this.toggleActionButtons(true);
        },
        async onActionCompleted() {
            await new Promise(resolve => {
                setTimeout(() => resolve(), 3700);
            });
            this.toggleActionButtons(false);
        },

        getInToolValidations({ promotionId }) {
            const notificationKey = notificationTypes.ruleValidationError;
            const notification = this.getPromotionNotifications({
                promotionId,
                notificationKey,
            });
            if (notification && notification.length) {
                const ruleValidationTypes = get(notification[0], 'details.ruleValidationTypes', []);
                return ruleValidationTypes.map(rule => {
                    return { ...rule, errorSeverity: rule.severity };
                });
            }

            return [];
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.sub-campaign-allocation-header {
    width: 100%;
    background-color: $promo-white;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.sub-campaign-allocation-grid-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.allocation-row {
    background-color: $promo-white;
    margin-left: 0px !important;
    width: 100%;
    position: relative;

    .allocation-btn {
        position: absolute;
        bottom: 1.2rem;
        right: 2rem;
        padding: 0 1.2rem;
        height: 2rem !important;
    }

    &__download-btn {
        position: absolute;
        bottom: 2.2rem;
        left: 46rem;
        font-size: 1.2rem;
    }
}

.actions-menu {
    &__list {
        background: $buttons-menu-list-bg;
        padding: 0.3rem;
    }

    &__icon {
        color: $promo-white;
        background: $buttons-menu-bg;
        padding: 0.5rem;
        border-radius: 0.4rem;
        ::v-deep {
            .v-icon,
            .icon-btn__text {
                color: $promo-white;
            }

            .icon-btn__text {
                padding: 0 1rem 0 0;
                border-right: 0.1rem solid;
                margin-right: 1rem;
            }

            .v-icon {
                padding-right: 0.3rem;
            }
        }

        &:disabled {
            background: $promo-white;
            border: 0.1rem solid $buttons-menu-disabled-color;
            color: $buttons-menu-bg;
            ::v-deep {
                .v-icon,
                .icon-btn__text {
                    color: $buttons-menu-bg;
                }

                .icon-btn__text {
                    color: $buttons-menu-disabled-color;
                    border-right: none;
                }
            }
        }
    }
    &.v-menu__content {
        border-radius: 0;
        margin-top: -0.5rem;
        padding: 0;
    }

    .v-list-item {
        border-top: 0.1rem solid $promo-white;
        border-bottom: 0.1rem solid $promo-white;
        padding: 0;
        min-height: 3.1rem;

        &:hover {
            background: $buttons-menu-hover;
        }

        &:first-of-type {
            border-top: none;
        }

        &:last-of-type {
            border: none;
        }

        button,
        ::v-deep button {
            width: 100%;
            height: 100%;
            &:hover {
                background: none;
                &:before {
                    opacity: 0;
                }
            }
            .v-btn__content {
                justify-content: flex-start;
                color: $buttons-menu-text-color;
            }
        }
    }
}

::v-deep {
    .workflow-cell {
        span {
            cursor: default;
        }
    }
    .checkbox-width {
        .ag-cell-value {
            width: 1.6rem;
        }
    }
    .promotion-rag-colour {
        margin-top: 1rem !important;
    }

    .is-child {
        span {
            padding-bottom: 0.6rem;
            padding-left: 0.2rem;
            &:hover {
                cursor: pointer;
            }
        }
    }
    // Filter does not increase width based on content,
    // so this is required to show all options.
    .ag-filter-body-wrapper {
        min-width: 21rem;
    }
}
</style>
