import exportFormats from '@enums/export-formats';
import temporaryIds from '@enums/temporary-ids-for-new-resources';
import tabsEnum from '@enums/promotion-tabs';
import to from 'await-to-js';
import axios from 'axios';
import UXEvents from '@enums/ux-events';
import { ascending } from '@enums/sort-direction';
import {
    cloneDeep,
    differenceWith,
    filter,
    forEach,
    get,
    groupBy,
    includes,
    isArray,
    isEmpty,
    keyBy,
    map,
    merge,
    size,
    uniq,
    has,
    isObject,
    intersectionWith,
    round,
    intersection,
    uniqBy,
    some,
    isNil,
    isNull,
    pullAll,
    differenceBy,
    omit,
} from 'lodash';
import Vue from 'vue';

import { promoPriceCalculator } from '@sharedModules/promo-price-calculator';
import { identifyMainSupplierAndSCI } from '@sharedModules/supply-utils';
import offerMechanicTemplates from '@enums/offer-mechanic-templates';
import createFeatureAwareFactory from '@/js/feature-toggles/feature-factory';
import {
    isStoreWidePromotion,
    isCategoryOrStoreWidePromotion,
    isCategoryWidePromotion,
} from '@sharedModules/promotion-utils';
import { calculateProductVariableFunding } from '@sharedModules/funding-utils';
import resources from '@enums/resources';
import notificationTypes from '@enums/notification-types';
import rewardTypes from '@enums/reward-types';
import namespaceEnum from '@enums/namespaces';
import pogScope from '@enums/product-offer-group-scope';
import { allowStoreWidePromotions, showOnlyMinPrices } from '@enums/feature-flags';
import offerMechanicDescriptionGeneratorFactory from '@sharedModules/offer-mechanic-description-generator-factory';
import clientStateRolesUtils from '@/js/utils/client-state-roles-utils';
import i18n from '@/js/vue-i18n';
import { getFieldPathByProductKey } from '@/js/utils/promotion-products-utils';
import downloadJSONData from '@/js/utils/download-json';
import downloadExcelData from '@/js/utils/download-excel';
import { isPopulatedArray } from '@/js/store/utils/offer-mechanic-utils';
import storeMixin from '@/js/store/mixins/vuex-store';
import { sortByDate } from '@/js/utils/sort-utils';

const generateOfferMechanicDescription = offerMechanicDescriptionGeneratorFactory(i18n);

const defaultPromotionSorting = {
    field: 'effectivenessRating',
    order: 'asc',
};

const numberOfPromosToRender = {
    onLoad: 3,
    afterLoad: 3,
};

// Define reusable promotion stream object. This allows previous existing streams to be
// closed if a new request is made.
let promotionStream;

// During moving products between products groups or in case when we move product outside product group
// to main selected candidates area, we have state with two similar products, because we put candidate in new place
// but we still didn't remove from the source area. In this case we can find wrong product when we adding
// productOfferGroupId and costs. And then we remove this product.
// For finding correct product we check also product group.
// As we don't have product group field in all cases, we send it explicitly.
function findProduct({ state, namespace, productKey, productGroup }) {
    const product = state.stagingArea[namespace].products.find(promotionProduct => {
        const productGroupId = promotionProduct.productGroup || null;
        return promotionProduct.productKey === productKey && productGroupId === productGroup;
    });

    // A product should always be found. If not, this is an error scenario.
    if (!product) {
        throw new Error('No matching product found in staging area.');
    }

    return product;
}

function getResourceInstanceDetailedProvisionOverwriteObject({
    state,
    namespace,
    resourceType,
    instanceKey,
}) {
    const resourceIndex = state.stagingArea[namespace].resources.findIndex(
        resource => resource.type === resourceType
    );

    const instanceIndex = state.stagingArea[namespace].resources[resourceIndex].instances.findIndex(
        instance => instance.key === instanceKey
    );

    const instanceToUpdate =
        state.stagingArea[namespace].resources[resourceIndex].instances[instanceIndex];

    // Return an object containing the field path for updating the given instance's detailed provisions alongside a
    // copy of the instance's detailed provisions which can be changed without affecting the staging area.
    // This copy can then be used for overwriting the detailed provisions.
    return {
        fieldPath: `resources[${resourceIndex}].instances[${instanceIndex}]`,
        detailedProvisionsCopy: instanceToUpdate.detailedProvision.map(dp => {
            return { ...dp };
        }),
    };
}

const getInitialState = () => ({
    promotions: [],
    selectedPromotionMaintenanceTab: null,
    selectedPromotionId: null,
    sorting: [{ ...defaultPromotionSorting }],
    numberOfPromosToRender,
    promotionsAggregatedByScenario: [],
    weeklyMetrics: {},
    tabValidationState: {},
    highlightedInstanceKey: null,
    promotionsForSelectedSupplierCommitment: [],
    parentPromotions: [],
    promosInView: [],
    bulkUploadSupplierFundingErrors: {},
    uploadReportDetails: {},
    changesetApplyInProgress: {},
    unsavedPromotion: {},
    saveInProgress: false,
    loadingWeeklyMetrics: false,
    addProductInProgress: false,
});

const getVariableFundingForProduct = ({ product }) => {
    return {
        ...get(product, 'funding.variableFunding'),
        uiFields: {
            onInvoiceValue: null,
            onInvoiceType: null,
            offInvoiceValue: null,
            offInvoiceType: null,
        },
        sellInPeriod: {
            daysBefore: null,
            daysAfter: null,
        },
        buyingPrice: null,
        supplierCompensation: null,
        unitFundingValue: 0,
    };
};

const setInitialFunding = product => {
    const initialFunding = {
        lumpFunding: [],
        lumpFundingTotal: 0,
        rebate: 0,
        rebateMultiplier: 0,
        variableFunding: getVariableFundingForProduct({
            product,
        }),
    };

    Vue.set(product, 'funding', {
        ...initialFunding,
        ...product.funding,
    });
};

const setInitialVolumes = product => {
    const initialVolumes = {
        forecasted: {
            calcBaseline: 0,
            calcUplift: 0,
            triggerCount: null,
        },
        baseline: null,
        uplift: null,
        totalVolume: 0,
        percentageVolumeBuffer: 0,
    };

    Vue.set(product, 'volumes', {
        ...initialVolumes,
        ...product.volumes,
    });
};

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 * Inherits from the store form mixin which takes care of creating a staging area for creations / updates
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     * - filter
     */
    state: getInitialState(),

    /**
     * Default getters available:
     * - getPromotionById
     * - getFilteredPromotions
     */
    getters: {
        getPromotionsForSelectedSupplierCommitment: state =>
            state.promotionsForSelectedSupplierCommitment,
        // returns current year YTD aggregations compared to last year YTD
        getYearToDateAggregatedMetrics: (state, getters, rootState, rootGetters) => {
            const currentDate = Vue.moment();
            const currentWeek = rootGetters['weeks/getWeekByDate'](currentDate);
            const defaultKpi = () => ({ value: 0, efficiency: 0, delta: 0 });

            if (!currentWeek) {
                return {
                    incrementalSales: defaultKpi(),
                    incrementalMargin: defaultKpi(),
                    promoSales: defaultKpi(),
                    promoMargin: defaultKpi(),
                };
            }

            const currentYTD = {
                incrementalSalesExcTax: 0,
                incrementalMargin: 0,
                actualSalesExcTax: 0,
                promoMarginRate: 0,
            };
            const previousYTD = {
                incrementalSalesExcTax: 0,
                incrementalMargin: 0,
                actualSalesExcTax: 0,
                promoMarginRate: 0,
            };

            // aggregate YTD metrics, excluding the current week
            for (let weekOfYear = 1; weekOfYear < currentWeek.weekOfYear; weekOfYear += 1) {
                const currentYearWeekKey = `${currentWeek.year}${weekOfYear}`;
                const previousYearWeekKey = `${currentWeek.year - 1}${weekOfYear}`;

                forEach(currentYTD, (value, key) => {
                    currentYTD[key] += get(
                        state.weeklyMetrics[currentYearWeekKey],
                        `KPIMetrics['${key}']`,
                        0
                    );
                });
                forEach(previousYTD, (value, key) => {
                    previousYTD[key] += get(
                        state.weeklyMetrics[previousYearWeekKey],
                        `KPIMetrics['${key}']`,
                        0
                    );
                });
            }

            const efficiencies = {
                // avoid division by 0
                // if no data for previous year return null
                incrementalSalesExcTax: previousYTD.incrementalSalesExcTax
                    ? currentYTD.incrementalSalesExcTax / previousYTD.incrementalSalesExcTax
                    : null,
                incrementalMargin: previousYTD.incrementalMargin
                    ? currentYTD.incrementalMargin / previousYTD.incrementalMargin
                    : null,
                actualSalesExcTax: previousYTD.actualSalesExcTax
                    ? currentYTD.actualSalesExcTax / previousYTD.actualSalesExcTax
                    : null,
                promoMarginRate: previousYTD.promoMarginRate
                    ? currentYTD.promoMarginRate / previousYTD.promoMarginRate
                    : null,
            };

            const deltas = {
                incrementalSalesExcTax:
                    currentYTD.incrementalSalesExcTax - previousYTD.incrementalSalesExcTax,
                incrementalMargin: currentYTD.incrementalMargin - previousYTD.incrementalMargin,
                actualSalesExcTax: currentYTD.actualSalesExcTax - previousYTD.actualSalesExcTax,
                promoMarginRate: currentYTD.promoMarginRate - previousYTD.promoMarginRate,
            };

            return {
                incrementalSales: {
                    value: currentYTD.incrementalSalesExcTax,
                    efficiency: efficiencies.incrementalSalesExcTax,
                    delta: deltas.incrementalSalesExcTax,
                },
                incrementalMargin: {
                    value: currentYTD.incrementalMargin,
                    efficiency: efficiencies.incrementalMargin,
                    delta: deltas.incrementalMargin,
                },
                promoSales: {
                    value: currentYTD.actualSalesExcTax,
                    efficiency: efficiencies.actualSalesExcTax,
                    delta: deltas.actualSalesExcTax,
                },
                promoMargin: {
                    value: currentYTD.promoMarginRate,
                    efficiency: efficiencies.promoMarginRate,
                    delta: deltas.promoMarginRate,
                },
            };
        },
        getPromotionsByScenarioId: state => (scenarioId, includeGhostPromotions = false) =>
            state.promotions.filter(
                promotion =>
                    promotion.scenarioId === scenarioId &&
                    (!includeGhostPromotions ? !promotion.isGhost : true)
            ),
        selectedPromotion: (state, getter) => getter.promotionMap[state.selectedPromotionId],
        promotionMap: state => keyBy(state.promotions, '_id'),
        getPromotionById: (state, getter) => promotionId => getter.promotionMap[promotionId],
        getStagingAreaPromotionById: state => promotionId => state.stagingArea[promotionId],
        stagingAreaPromotionProductsKeyed: state => promotionId => {
            const products = state.stagingArea[promotionId].products;

            return keyBy(products, 'productKey');
        },
        /**
         * All candidates which can be added to the current promotion.
         */
        candidates: (state, getters, rootState) =>
            rootState.promotionCandidates.filteredPromotionCandidates,
        /**
         * Generic function for returning a set of available options which exclude any options already set.
         * This is useful for getting the available options for the candidates, and can be used for getting the
         * options in a multi-select list excluding any already selected.
         * TODO: Expand on this to make it more applicable in other use cases. It is still quite specific to selecting
         * candidates.
         *
         * Added mixinsPath for expanding products which we use for filtering
         * Products can be part of other instances for example part of ppg
         * This products keep in store and we need to include them in filter
         */
        filteredOptions: (state, getters, rootState) => ({
            fieldName,
            namespace,
            optionsGetter,
            comparator,
        }) => {
            let selectedOptions = state.stagingArea[namespace][fieldName];
            const mixinsItems = get(
                rootState,
                'promotionProductGroups.stagingArea.default.products',
                []
            );
            selectedOptions = [...selectedOptions, ...mixinsItems];

            const allOptions = getters[optionsGetter];
            const optionsToExclude = selectedOptions;
            const defaultComparator = (option1, option2) => option1._id === option2._id;

            // Perform the difference between the two lists, using the ID as the comparison field.
            return differenceWith(allOptions, optionsToExclude, comparator || defaultComparator);
        },
        byScenarioIds: state => scenarioIds =>
            filter(state.promotions, promotion => includes(scenarioIds, promotion.scenarioId)),
        allPromotionsBySubCampaignId: (state, getters, rootState, rootGetters) => subCampaignId => {
            const scenarioIds = rootGetters['scenarios/allScenariosIdsBySubCampaignId'](
                subCampaignId
            );

            return getters.byScenarioIds(scenarioIds);
        },
        promotionProductsAndGroups: (state, getter) => promotionId => {
            const promotion = getter.getPromotionById(promotionId);
            const allProducts = get(promotion, 'products', []);
            const productGroups = map(get(promotion, 'productGroups', []), obj => {
                return { ...obj };
            });
            const productGroupsMap = keyBy(productGroups, '_id');
            const nonGroupedProducts = [];
            allProducts.forEach(product => {
                const productGroupKey = product.productGroup;
                if (productGroupKey) {
                    if (has(productGroupsMap[productGroupKey], 'products')) {
                        // Check if product is already exists in product group
                        const hasMatchingProduct = some(
                            productGroupsMap[productGroupKey].products,
                            {
                                productKey: product.productKey,
                            }
                        );
                        if (!hasMatchingProduct) {
                            productGroupsMap[productGroupKey].products.push(product);
                        }
                    } else {
                        productGroupsMap[productGroupKey].products = [];
                        productGroupsMap[productGroupKey].products.push(product);
                    }
                } else {
                    nonGroupedProducts.push(product);
                }
            });

            return [...nonGroupedProducts, ...productGroups];
        },
        promotionsAggregatedByScenario: state => state.promotionsAggregatedByScenario,
        weeklyMetrics: state => state.weeklyMetrics,
        supplierNotesMap: (state, getters) => {
            const notesMap = {};
            getters.selectedPromotion.supplierNotes.forEach(note => {
                if (notesMap[note.supplierKey]) {
                    notesMap[note.supplierKey].push(note);
                } else {
                    notesMap[note.supplierKey] = [note];
                }
            });

            return notesMap;
        },

        productOfferGroupsData: state => (namespace, groupIndex) => {
            const { products, offerMechanic } = state.stagingArea[namespace];

            if (isEmpty(products)) return [];

            const groupedProducts = groupBy(
                products.filter(p => p.productGroup !== temporaryIds.newProductGroupId),
                'productOfferGroupId'
            );

            return groupedProducts[offerMechanic.tiers[0].productOfferGroups[groupIndex]._id] || [];
        },

        parentPromotionWorkflowStateById: (state, getters) => ({ id }) => {
            const parentPromotion = getters.getParentPromotionsKeyedById[id];
            if (parentPromotion) {
                return [...parentPromotion.workflowState];
            }
        },

        entityWorkflowStateById: (state, getters, rootState, rootGetters) => ({
            id,
            getParentEntities = true,
        }) => {
            const promotion = getters.getPromotionById(id);
            if (promotion) {
                const { scenarioId, workflowState: promotionWorkflowState } = promotion;

                const scenarioWorkflowState = getParentEntities
                    ? rootGetters['scenarios/entityWorkflowStateById']({
                          id: scenarioId,
                          getChildEntities: false,
                      })
                    : [];

                return [...scenarioWorkflowState, ...promotionWorkflowState];
            }
            return [];
        },

        getSubCampaignIdForEntity: (state, getters, rootState, rootGetters) => ({ entityId }) => {
            const promotion = getters.getPromotionById(entityId);
            const scenario =
                promotion && promotion.scenarioId
                    ? rootGetters['scenarios/getScenarioById']({
                          _id: promotion.scenarioId,
                          usePluralResourceName: true,
                      })
                    : null;

            return scenario ? scenario.subCampaignId : null;
        },

        getCheckboxListOptions: (state, getters, rootState, rootGetters) => ({
            attributeName,
            attributeKey,
            resource,
            getOptionsFunction,
            scenarioId,
            getUserAccessOptionsMap,
            useSelectedContext = true,
        }) => {
            const selectedContext = scenarioId
                ? rootGetters['scenarios/getScenarioById']({
                      _id: scenarioId,
                      usePluralResourceName: true,
                  })
                : rootGetters['scenarios/selectedScenario'];

            let resourceOptions = rootGetters[`${resource}/${getOptionsFunction}`];

            if (selectedContext && useSelectedContext) {
                resourceOptions = filter(resourceOptions, resourceOption => {
                    const selectedResourceOptionsKeys = map(selectedContext[attributeName], value =>
                        isObject(value) ? value[attributeKey] : value
                    );
                    return includes(selectedResourceOptionsKeys, resourceOption[attributeKey]);
                });
            }

            return map(resourceOptions, resourceOption => {
                const optionKey = resourceOption[attributeKey];
                return {
                    reference: resourceOption,
                    disabled:
                        // check if user has no access to campaign resource option
                        !!getUserAccessOptionsMap &&
                        isNil(rootGetters[`context/${getUserAccessOptionsMap}`][optionKey]),
                };
            });
        },

        getNominatedPromotions: (state, getters, rootState, rootGetters) => (
            filterInstanceFunction = () => true
        ) => {
            const { resourceKey, key } = rootGetters['subCampaigns/selectedResourceDefinition'];

            return state.promotions.filter(
                promotion =>
                    // exclude ghost promotions from preparation tab
                    !promotion.isGhost &&
                    promotion.resources.some(resource => {
                        return (
                            resource.type === resourceKey &&
                            resource.instances.some(
                                instance => instance.key === key && filterInstanceFunction(instance)
                            )
                        );
                    })
            );
        },

        getPromotionForForecasting: (state, getters) => ({ promotionId }) => {
            const promotion = getters.getPromotionById(promotionId);
            const stagingAreaPromotion = getters.getStagingAreaPromotionById(promotionId);

            return {
                ...promotion,
                ...stagingAreaPromotion,
            };
        },

        getPromotionsAllocatedToPromoResource: (
            state,
            getters,
            rootState,
            rootGetters
        ) => promoResourceKey => {
            const resourceDefinition = rootGetters[
                'subCampaigns/getResourceDefinitionByResourceKey'
            ]({ resourceKey: promoResourceKey });

            if (!resourceDefinition) {
                throw new Error(
                    `No resource definition could be found for promo resource key: ${promoResourceKey}`
                );
            }

            // Retrieve the IDs of all the promotions assigned to the resource.
            const promotionIdsAssignedToResource = resourceDefinition.pages
                .map(page => {
                    // Retrieve the IDs of all the promotions assigned to each page of the resource.
                    return page.assignment.map(assignment => assignment.promotionId);
                })
                .flat();

            // Return all the promotions assigned to the resource.
            return intersectionWith(
                state.promotions,
                promotionIdsAssignedToResource,
                (promotion, promotionId) => {
                    return promotion._id === promotionId;
                }
            );
        },

        getSupplierCommitmentPromotions: (state, getters, rootState, rootGetters) => {
            const today = Vue.moment().utc();
            const sc = rootGetters['supplierCommitments/getSelectedSupplierCommitment'];
            if (!sc) {
                return { planned: [], executed: [] };
            }

            // If a promotion has recently been allocated then it won't exist in
            // the promotionsForSelectedSupplierCommitment state, therefore we
            // need to add it to ensure it's included in the promotions list.
            let selectedPromotion;
            if (getters.selectedPromotion) {
                selectedPromotion = state.promotionsForSelectedSupplierCommitment.find(
                    promotion => promotion._id === getters.selectedPromotion._id
                );
            }

            const promotions = selectedPromotion
                ? state.promotionsForSelectedSupplierCommitment
                : [...state.promotionsForSelectedSupplierCommitment, selectedPromotion];

            return promotions
                .filter(promotion => sc.allocatedPromotionIds.includes(promotion && promotion._id))
                .reduce(
                    (acc, promotion) => {
                        if (
                            Vue.moment(promotion.startDate)
                                .utc()
                                .isAfter(today)
                        ) {
                            acc.planned.push(promotion);
                        } else {
                            acc.executed.push(promotion);
                        }
                        return acc;
                    },
                    { planned: [], executed: [] }
                );
        },

        isPromotionPlannedOnResourceInstance: (state, getters, rootState, rootGetters) => ({
            promotionId,
            subCampaignId,
            resourceKey,
        }) => {
            const subCampaign = rootGetters['subCampaigns/getSubCampaignById']({
                _id: subCampaignId,
                usePluralResourceName: true,
            });

            const resource = subCampaign.resourceDefinitions.find(rd => rd.key === resourceKey);

            return resource.pages.some(page =>
                page.assignment.some(assignment => assignment.promotionId === promotionId)
            );
        },

        getPromotionRankedProducts: (state, getter) => ({ promotionId }) =>
            getter.promotionMap[promotionId].products.filter(p => !!p.rank).map(p => p.productKey),

        getParentPromotionsKeyedById: state => {
            return keyBy(state.parentPromotions, '_id');
        },
        getPromotionNotifications: (state, getter, rootState) => ({
            promotionId,
            notificationKey,
        }) => {
            const notifications = rootState.notifications.notifications;
            if (notifications) {
                return notifications
                    .filter(
                        notification =>
                            notification.notificationKey === notificationKey &&
                            get(notification, 'details.entityIds.promotionId', null) === promotionId
                    )
                    .sort((a, b) => sortByDate(a, b, ascending));
            }

            return null;
        },

        pendingChanges: (state, getters) => ({ promotionId }) => {
            const notifications = getters.getPromotionNotifications({
                promotionId,
                notificationKey: notificationTypes.parentPromotionUpdated,
            });
            return !isEmpty(notifications) && !notifications[0].details.changeset;
        },

        editablePriceMap: state => {
            const initialMap = {};
            Object.keys(state.stagingArea).forEach(key => {
                const { offerMechanic } = state.stagingArea[key] || {};
                if (offerMechanic) {
                    initialMap[key] = offerMechanic.tiers.map(tier => {
                        const result = { productOfferGroups: {} };
                        tier.productOfferGroups.forEach(pog => {
                            const { rewards } = pog || {};
                            result.productOfferGroups[pog._id] = !!(
                                rewards &&
                                rewards.length &&
                                rewardTypes.newPriceRewardTypes.includes(rewards[0].type)
                            );
                        });
                        return result;
                    });
                }
            });
            return initialMap;
        },

        isSelectedPromotionStoreWide: (state, getters) => {
            // if creating a new promotion in the parkinglot, promotion will be in staging area and _id is default
            const promotion = getters.getStagingAreaPromotionById(
                get(getters.selectedPromotion, '_id', null) || 'default'
            );

            return isStoreWidePromotion(promotion);
        },

        isSelectedPromotionCategoryOrStoreWide: (state, getters) => {
            // if creating a new promotion in the parkinglot, promotion will be in staging area and _id is default
            const promotion = getters.getStagingAreaPromotionById(
                get(getters.selectedPromotion, '_id', null) || 'default'
            );

            return isCategoryOrStoreWidePromotion(promotion);
        },

        isSelectedPromotionCategoryWide: (state, getters) => {
            // if creating a new promotion in the parkinglot, promotion will be in staging area and _id is default
            const promotion = getters.getStagingAreaPromotionById(
                get(getters.selectedPromotion, '_id', null) || 'default'
            );

            return isCategoryWidePromotion(promotion);
        },
    },

    /**
     * Default mutations available:
     * - setLoading
     * - setPromotions
     * - deletePromotion
     * - updatePromotion
     * - addPromotion
     * - setSelectedFilter
     * - resetFilter
     * - resetState
     */
    mutations: {
        addPromotions(state, promos) {
            state.promotions = [...state.promotions, ...promos];
        },
        setSelectedPromotionMaintenanceTab(state, selectedTab) {
            state.selectedPromotionMaintenanceTab = selectedTab;
        },
        resetPromotions(state) {
            state.promotions = [];
        },
        resetPromotionsInView(state) {
            state.promotionsInView = [];
        },
        setHighlightedInstanceKey(state, instanceKey) {
            state.highlightedInstanceKey = instanceKey;
        },

        setSelectedPromotionId(state, promotionId) {
            state.selectedPromotionId = promotionId;
        },

        setLoadingWeeklyMetrics(state, value) {
            state.loadingWeeklyMetrics = value;
        },

        setPromotionsForSelectedSupplierCommitment(state, promotionsForSelectedSupplierCommitment) {
            state.promotionsForSelectedSupplierCommitment = promotionsForSelectedSupplierCommitment;
        },

        setPromotionForecast(
            state,
            {
                _id: promotionId,
                forecastingAggregations,
                effectivenessRating,
                products,
                forecasts,
                lastForecastDate,
            }
        ) {
            if (!has(state.stagingArea, promotionId)) return;
            state.stagingArea[promotionId].forecastingAggregations = forecastingAggregations;
            state.stagingArea[promotionId].effectivenessRating = effectivenessRating;
            state.stagingArea[promotionId].forecasts = forecasts;
            state.stagingArea[promotionId].lastForecastDate = lastForecastDate;

            if (products) {
                const stagingAreaProducts = get(state.stagingArea[promotionId], 'products', []);
                const forecastedProducts = keyBy(products, 'productKey');

                stagingAreaProducts.forEach(productToUpdate => {
                    if (forecastedProducts[productToUpdate.productKey]) {
                        Vue.set(
                            productToUpdate,
                            'forecastingAggregations',
                            forecastedProducts[productToUpdate.productKey].forecastingAggregations
                        );
                        Vue.set(
                            productToUpdate,
                            'forecasts',
                            forecastedProducts[productToUpdate.productKey].forecasts
                        );
                        Vue.set(
                            productToUpdate,
                            'volumes',
                            forecastedProducts[productToUpdate.productKey].volumes
                        );
                    }
                });
            }
        },

        setPromotionForecastingResults(
            state,
            {
                _id: promotionId,
                forecastingAggregations,
                effectivenessRating,
                products,
                forecasts,
                lastForecastDate,
            }
        ) {
            // only update if the requested promotion is still in the staging area
            if (!has(state.stagingArea, promotionId)) return;

            state.stagingArea[promotionId].forecastingAggregations = forecastingAggregations;
            state.stagingArea[promotionId].effectivenessRating = effectivenessRating;
            state.stagingArea[promotionId].forecasts = forecasts;
            state.stagingArea[promotionId].lastForecastDate = lastForecastDate;

            if (products) {
                const stagingAreaProducts = get(state.stagingArea[promotionId], 'products', []);
                const forecastedProducts = keyBy(products, 'productKey');

                // Update values that could've been recalculated in the backend
                stagingAreaProducts.forEach(productToUpdate => {
                    if (forecastedProducts[productToUpdate.productKey]) {
                        productToUpdate.funding.totalFunding =
                            forecastedProducts[productToUpdate.productKey].funding.totalFunding;
                        productToUpdate.funding.lumpFundingTotal =
                            forecastedProducts[productToUpdate.productKey].funding.lumpFundingTotal;
                        productToUpdate.volumes.forecasted =
                            forecastedProducts[productToUpdate.productKey].volumes.forecasted;
                        productToUpdate.volumes.totalVolume =
                            forecastedProducts[productToUpdate.productKey].volumes.totalVolume;
                        Vue.set(
                            productToUpdate,
                            'forecastingAggregations',
                            forecastedProducts[productToUpdate.productKey].forecastingAggregations
                        );
                        Vue.set(
                            productToUpdate,
                            'forecasts',
                            forecastedProducts[productToUpdate.productKey].forecasts
                        );
                        Vue.set(
                            productToUpdate,
                            'volumes',
                            forecastedProducts[productToUpdate.productKey].volumes
                        );
                    }
                });
            }
        },

        setSorting(state, { field, isSingleFieldSorting }) {
            let sorting = [...state.sorting];
            const index = sorting.findIndex(item => item.field === field);

            // Remove field from sorting if prev state is desc
            // in other cases change state or add new field
            if (index !== -1 && sorting[index].order === 'desc') {
                sorting.splice(index, 1);
            } else if (index !== -1 && sorting[index].order === 'asc') {
                sorting[index].order = 'desc';
            } else if (index === -1) {
                // If we are sorting by a single field then set the sorting array to just include
                // the new field. Otherwise add the field to the existing sorting fields.
                if (isSingleFieldSorting) {
                    sorting = [{ field, order: 'asc' }];
                } else {
                    sorting.push({ field, order: 'asc' });
                }
            }
            state.sorting = sorting;
        },

        resetSorting(state) {
            state.sorting = [{ ...defaultPromotionSorting }];
        },

        setPpgName(state, { namespace, name, id }) {
            const ppg = state.stagingArea[namespace].productGroups;
            const newPpg = ppg.map(item => {
                if (item._id === id) {
                    item.name = name;
                }
                return item;
            });

            state.stagingArea[namespace].productGroups = [...newPpg];
        },
        addProductToHostProducts(state, { namespace, product }) {
            const productOfferGroups =
                state.stagingArea[namespace].offerMechanic.tiers[0].productOfferGroups;

            // If no product offer groups have been created then do nothing.
            if (!isPopulatedArray(productOfferGroups)) {
                return;
            }

            // We assume that the first productOfferGroup in the array contains the host products.
            // New candidates default to being added to the host products as this is the only area
            // guaranteed to be present.
            const hostProductOfferGroup = productOfferGroups[0];

            hostProductOfferGroup.products.push(product);
        },
        addProductGroupToHostProducts(state, { namespace, productGroup }) {
            const productOfferGroups =
                state.stagingArea[namespace].offerMechanic.tiers[0].productOfferGroups;

            // If no product offer groups have been created then do nothing.
            if (!isPopulatedArray(productOfferGroups)) {
                return;
            }

            // We assume that the first productOfferGroup in the array contains the host products.
            // New candidates default to being added to the host products as this is the only area
            // guaranteed to be present.
            const hostProductOfferGroup = productOfferGroups[0];

            hostProductOfferGroup.productGroups.push(productGroup);
        },
        addCostsAndNonPromoPricesToProduct(
            state,
            {
                namespace,
                product,
                productPrices,
                productCosts,
                productGroup = null,
                precisionForPricesAndCosts,
            }
        ) {
            const productToUpdate = findProduct({ state, namespace, ...product, productGroup });

            const roundValue = value => {
                return round(value, precisionForPricesAndCosts);
            };

            Vue.set(productToUpdate, 'nonPromoPrices', {
                minPrice: roundValue(productPrices.minNonPromoPrice),
                maxPrice: roundValue(productPrices.maxNonPromoPrice),
                avgPrice: isNull(productPrices.avgNonPromoPrice)
                    ? 0
                    : roundValue(productPrices.avgNonPromoPrice),
                avgPriceExcTax: isNull(productPrices.avgNonPromoPriceExcTax)
                    ? 0
                    : roundValue(productPrices.avgNonPromoPriceExcTax),
            });

            const onInvoiceCosts = productCosts.onInvoiceCosts || {};
            const commercialCosts = productCosts.commercialCosts || {};

            Vue.set(productToUpdate, 'onInvoiceCosts', {
                minCost: isNull(onInvoiceCosts.minNonPromoCost)
                    ? 0
                    : roundValue(onInvoiceCosts.minNonPromoCost),
                maxCost: isNull(onInvoiceCosts.maxNonPromoCost)
                    ? 0
                    : roundValue(onInvoiceCosts.maxNonPromoCost),
                avgCost: isNull(onInvoiceCosts.avgNonPromoCost)
                    ? 0
                    : roundValue(onInvoiceCosts.avgNonPromoCost),
            });

            Vue.set(productToUpdate, 'commercialCosts', {
                minCost: isNull(commercialCosts.minNonPromoCost)
                    ? 0
                    : roundValue(commercialCosts.minNonPromoCost),
                maxCost: isNull(commercialCosts.maxNonPromoCost)
                    ? 0
                    : roundValue(commercialCosts.maxNonPromoCost),
                avgCost: isNull(commercialCosts.avgNonPromoCost)
                    ? 0
                    : roundValue(commercialCosts.avgNonPromoCost),
            });
        },

        updateSupplierCaseInformationInPromotion(
            state,
            { namespace, product, supplierCaseInformation, productGroup = null }
        ) {
            const productToUpdate = findProduct({ state, namespace, ...product, productGroup });

            Vue.set(productToUpdate, 'supplierCaseInformation', supplierCaseInformation);
            Vue.set(productToUpdate, 'supplierKey', product.supplierKey);
            Vue.set(productToUpdate, 'clientSupplierKey', product.clientSupplierKey);
            Vue.set(productToUpdate, 'supplierName', product.supplierName);
        },

        setProductGroupExpanded(state, { namespace, productGroupId, isExpanded = false }) {
            const productGroups = state.stagingArea[namespace].productGroups;
            const pgToToggle = productGroups.find(pg => pg._id === productGroupId);
            Vue.set(pgToToggle, 'isExpanded', isExpanded);
        },

        setPromotionsAggregatedByScenario(state, { promotionsAggregatedByScenario }) {
            state.promotionsAggregatedByScenario = promotionsAggregatedByScenario;
        },
        setWeeklyMetrics(state, weeklyMetrics) {
            Vue.set(state, 'weeklyMetrics', weeklyMetrics);
        },

        setTabValidationState(state, value) {
            state.tabValidationState = value;
        },

        setPromotionExecution(state, { promotionId, executionId, executionResult = null }) {
            const promotion = state.promotions.find(p => p._id === promotionId);
            const updates = { executionId, errors: [] };
            const promoDeleteError = get(promotion, 'execution.deleteError', null);
            if (promoDeleteError) {
                updates.deleteError = promoDeleteError;
            }
            if (executionResult && executionResult.execution.errors) {
                updates.errors = executionResult.execution.errors;
            }

            if (executionResult && executionResult.execution.deleteError) {
                updates.deleteError = executionResult.execution.deleteError;
            }
            Vue.set(promotion, 'execution', updates);
        },

        setPromotionApplyingNotificationsFlag(state, { entityId, value = true }) {
            const promotion = state.promotions.find(p => p._id === entityId);
            Vue.set(promotion, 'applyingNotifications', value);
        },

        setParentPromotions(state, parentPromotions) {
            state.parentPromotions = parentPromotions;
        },

        setPromosInView(state, { promotions }) {
            state.promosInView = promotions;
        },

        setBulkUploadSupplierFundingErrors(state, errors) {
            state.bulkUploadSupplierFundingErrors = errors;
        },

        setUploadReportDetails(state, uploadReportDetails) {
            state.uploadReportDetails = uploadReportDetails;
        },
        setChangesetApplyInProgress(state, { promoId, value }) {
            state.changesetApplyInProgress = {
                ...state.changesetApplyInProgress,
                [promoId]: value,
            };
        },
        setPromotionPendingChangesField(state, { promotionId, value }) {
            const index = state.promotions.findIndex(
                promo => String(promo._id) === String(promotionId)
            );
            if (index > -1) {
                Vue.set(state.promotions[index].notification, 'pendingChanges', value);
            }
        },
        setUnsavedPromotion(state, { namespace, tab, value }) {
            const currentPromotionState = get(state, namespace, {});
            // If namespace is default, that means we are in create mode and do not care about locking tabs
            if (namespace === namespaceEnum.default) return;
            if (tab === 'all') {
                state.unsavedPromotion = {
                    ...state.unsavedPromotion,
                    [namespace]: {},
                };
            } else {
                state.unsavedPromotion = {
                    ...state.unsavedPromotion,
                    [namespace]: {
                        ...currentPromotionState,
                        [tab]: value,
                    },
                };
            }
        },
        setSaveInProgress(state, value) {
            state.saveInProgress = value;
        },
        setAddProductsInProgress(state, value) {
            state.addProductInProgress = value;
        },
    },

    /**
     * Default actions available:
     * - fetchPromotions
     * - deletePromotion
     * - updatePromotion
     * - createPromotion
     * - submitForm
     * - handleResponseNotifications
     * - setSelectedFilter
     * - resetFilter
     * - resetState
     */
    actions: {
        async deletePromotion(
            { dispatch, commit, getters, rootGetters },
            { id, refreshData = false, refreshParams = {} }
        ) {
            const promotionCopy = cloneDeep(getters.getPromotionById(id));

            // for promotions assigned to scenario need to check if it is parent with executing children
            if (promotionCopy && promotionCopy.scenarioId) {
                const { scenarioId } = promotionCopy;
                const { subCampaignId } = rootGetters['scenarios/getScenarioById']({
                    _id: scenarioId,
                    usePluralResourceName: true,
                });

                const { children } = rootGetters['subCampaigns/getSubCampaignById']({
                    _id: subCampaignId,
                    usePluralResourceName: true,
                });
                // check children for parent promotion
                if (!isEmpty(children)) {
                    const [error, response] = await to(
                        axios.post(`/api/promotions/checkChildrenExecutionStatus`, {
                            promotionId: id,
                        })
                    );

                    if (error || get(response, 'data.childrenExecutionStatus', false)) {
                        dispatch(
                            'notifications/addNotification',
                            {
                                message: i18n.t('general.errors.cannotDeleteParentPromotionError'),
                                severity: 'error',
                            },
                            { root: true }
                        );
                        // these lines were added to keep toast visible
                        // whithout this, toast disapierd very fast it no depend on popupTimeout param
                        // looks like issue is in vuetify
                        await new Promise(resolve => {
                            setTimeout(() => resolve(), 2500);
                        });
                        return { error: true };
                    }
                }
            }

            commit('deletePromotion', id);
            const [error, response] = await to(axios.delete(`/api/promotions/${id}`));
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t(`notifications.deleteSuccess`, { resource: 'promotion' }),
                errorMessage: i18n.t(`notifications.deleteError`, { resource: 'promotion' }),
            });
            if (error) {
                commit('addPromotion', promotionCopy);
                return { error };
            }
            if (refreshData) {
                dispatch('fetchPromotions', { params: refreshParams });
            }
            return { result: response.data };
        },
        setHighlightedInstanceKey({ commit }, { instanceKey }) {
            commit('setHighlightedInstanceKey', instanceKey);
        },

        clearSelectedPromotion({ commit }) {
            commit('setSelectedPromotionId', null);
        },

        addPromotions({ commit }, promos) {
            commit('addPromotions', promos);
        },

        resetPromotions({ commit }) {
            commit('resetPromotions');
        },

        resetPromotionsInView({ commit }) {
            commit('resetPromotionsInView');
        },

        fetchPromotionsStreamed({ commit, dispatch }, { params = {}, callbackFunction } = {}) {
            const requestParams = new URLSearchParams(params);

            if (promotionStream) {
                // If a promotion stream is already open, then close it.
                promotionStream.close();
            }

            promotionStream = new EventSource(`/api/promotions/streamed?${requestParams}`, {
                withCredentials: true,
            });

            promotionStream.addEventListener(
                'message',
                e => {
                    const newPromotions = JSON.parse(e.data);

                    if (callbackFunction) callbackFunction(newPromotions);
                    else dispatch('addPromotions', newPromotions);

                    commit('setLoading', false);
                },
                false
            );

            promotionStream.addEventListener(
                'error',
                e => {
                    if (e.eventPhase === EventSource.CLOSED) {
                        promotionStream.close();

                        // Call callbackFunction with no data when connection is closed.
                        // This ensures the grid is aware there is no data to be loaded
                        // in the event that the connection is closed straight away.
                        if (callbackFunction) callbackFunction([]);
                        commit('setLoading', false);
                    }
                },
                false
            );
        },

        async submitPromotion({ getters, dispatch, commit }, { namespace }) {
            commit('setSaveInProgress', true);
            const { error } = await dispatch('submitForm', {
                namespace,
                editMode: true,
                submitAction: 'prepareAndUpdatePromotion',
            });
            // handle any error
            if (error) return { error };
            dispatch('subCampaigns/updateSubCampaignResourceDefinitions', null, { root: true });
            const savedPromotion = getters.getPromotionById(namespace);
            commit('setSaveInProgress', false);
            // trigger promotionSaved or parkingLotSaved event
            const globalEvent = savedPromotion.isInParkingLot
                ? UXEvents.parkingLotSaved
                : UXEvents.promotionSaved;
            // namespace required for some global event handlers e.g. updateOfferMechanicDescription
            this.$app.globalEmit(globalEvent, { ...savedPromotion, namespace });
        },
        async setSelectedPromotion(
            { getters, commit, dispatch, rootGetters, rootState },
            { promotionId } = { promotionId: null }
        ) {
            if (promotionId) {
                const subCampaign =
                    rootGetters['subCampaigns/selectedSubCampaign'] ||
                    rootState.parkingLotFilters.selections.subCampaign;

                const promises = [];

                promises.push(
                    dispatch('fetchPromotions', {
                        params: {
                            where: {
                                _id: promotionId,
                                isGhost: true,
                                ...(rootState.clientConfig.toggleLogic[allowStoreWidePromotions]
                                    ? { storeWideCategories: get(subCampaign, 'categories') }
                                    : {}),
                            },
                        },
                        patchState: true,
                        patchOptions: {
                            fieldToMatchOn: '_id',
                            isMatchFunction: promotion => promotion._id === promotionId,
                        },
                    })
                );

                // Check if the promotion has any pending notifications, if so reload these in full
                const notifications = getters.getPromotionNotifications({
                    promotionId,
                    notificationKey: notificationTypes.parentPromotionUpdated,
                });
                const where = notifications.length
                    ? {
                          _id: { $in: map(notifications, '_id') },
                          status: 'OPEN',
                      }
                    : {
                          'details.entityIds.promotionId': promotionId,
                          notificationKey: notificationTypes.parentPromotionUpdated,
                          status: 'OPEN',
                      };
                promises.push(
                    dispatch(
                        'notifications/fetchNotifications',
                        {
                            params: {
                                where,
                            },
                            patchState: true,
                            patchOptions: {
                                fieldToMatchOn: '_id',
                                isMatchFunction: newNotification =>
                                    notifications.find(existingNotification => {
                                        return existingNotification._id === newNotification._id;
                                    }),
                            },
                        },
                        { root: true }
                    )
                );

                await Promise.all(promises);
            }

            commit('setSelectedPromotionId', promotionId);
        },

        async updateDataForPromotion({ dispatch }, promotion) {
            if (!promotion._id) {
                await dispatch('clearTabValidationState');
            }
            dispatch('validatePromotionMaintenanceArea', promotion);
            dispatch('promotionCandidates/resetUserFilters', null, { root: true });
            dispatch(
                'rateCards/fetchRateCardsByStoreGroupsAndDates',
                { promotion },
                {
                    root: true,
                }
            );
        },

        toggleSelectedPromotion({ state, dispatch }, { promotionId }) {
            const newPromotionId = promotionId === state.selectedPromotionId ? null : promotionId;
            dispatch('setSelectedPromotion', { promotionId: newPromotionId });
        },

        setSortingAction({ commit }, { field, isSingleFieldSorting = false }) {
            commit('setSorting', { field, isSingleFieldSorting });
        },

        async download({ dispatch }, { params = {} } = {}) {
            const url = '/api/promotions/promotionsForSubCampaigns';
            const [error, response] = await to(
                axios({ url, method: 'get', params, responseType: 'blob' })
            );

            if (error) {
                return dispatch('handleResponseNotifications', {
                    error,
                    errorMessage: i18n.t('notifications.fetchError', {
                        resource: i18n.tc('entities.promotions', 2),
                    }),
                });
            }

            if (params.exportFormat === exportFormats.excel) {
                downloadExcelData(response.data, i18n.tc('entities.promotions', 2));
            } else if (params.exportFormat === exportFormats.json) {
                downloadJSONData(response.data, i18n.tc('entities.promotions', 2));
            }
        },

        async downloadPromotionsWithExecutionErrors({ dispatch }, { params = {} } = {}) {
            const url = '/api/promotions/promotionsWithExecutionErrors';
            const [error, response] = await to(
                axios({ url, method: 'get', params, responseType: 'blob' })
            );

            if (error) {
                return dispatch('handleResponseNotifications', {
                    error,
                    errorMessage: i18n.t('notifications.fetchError', {
                        resource: i18n.tc('entities.promotions', 2),
                    }),
                });
            }

            downloadExcelData(response.data, i18n.tc('entities.promotions', 2));
        },

        async downloadPromotionsForScenario({ dispatch, rootGetters }, { params = {} } = {}) {
            params.campaignId = params.campaignId || rootGetters['campaigns/selectedCampaignId'];
            params.subCampaignId =
                params.subCampaignId || rootGetters['subCampaigns/selectedSubCampaignId'];
            params.scenarioId = params.scenarioId || rootGetters['scenarios/selectedScenarioId'];
            const url = '/api/promotions/promotionsForScenario';
            const [error, response] = await to(
                axios({ url, method: 'get', params, responseType: 'blob' })
            );

            if (error) {
                return dispatch('handleResponseNotifications', {
                    error,
                    errorMessage: i18n.t('notifications.fetchError', {
                        resource: i18n.tc('entities.promotions', 2),
                    }),
                });
            }

            downloadExcelData(response.data, i18n.tc('entities.promotions', 2));
        },

        async downloadProductSupplierFundingExcel({ dispatch }, { promotionId }) {
            const params = {
                export: true,
                exportFormat: exportFormats.excel,
                exportSchema: 'promotion-product-funding',
            };
            const url = `/api/promotions/${promotionId}/products`;
            const [error, response] = await to(
                axios({ url, method: 'get', params, responseType: 'blob' })
            );
            if (error) {
                return dispatch('handleResponseNotifications', {
                    error,
                    errorMessage: i18n.t('notifications.fetchError', {
                        resource: i18n.tc('entities.promotions', 2),
                    }),
                });
            }

            downloadExcelData(
                response.data,
                `${i18n.tc('entities.products', 2)}-${i18n.t('downloadCentre.withFunding')}`
            );
        },

        async downloadParkingLot({ rootState, dispatch }, { params }) {
            const projection = [
                'startDate',
                'endDate',
                'products',
                'name',
                'offerMechanic',
                'tags',
                'storeGroups',
                'resources',
                'forecasts',
                'forecastingAggregations',
                'isInParkingLot',
                'rateCards',
                'productOfferGroups',
                'userSelectedCategories',
                'isAlternativeMechanicSelected',
            ].map(field => `{ "${field}": 1}`);

            params.maxExportLimit =
                rootState.clientConfig.generalConfig.excelParkingLotRowExportLimit;
            // Add 1 to the limit so we know when the limit has been exceeded
            // in the export-file middleware.
            params.limit = rootState.clientConfig.generalConfig.excelParkingLotRowExportLimit + 1;
            params.sortBy = 'startDate';
            params.pick = projection;
            const url = '/api/promotions';
            const [error, response] = await to(
                axios({ url, method: 'get', params, responseType: 'blob' })
            );

            if (error) {
                return dispatch('handleResponseNotifications', {
                    error,
                    errorMessage: i18n.t('notifications.fetchError', {
                        resource: i18n.tc('entities.promotions', 2),
                    }),
                });
            }

            downloadExcelData(response.data, i18n.t('parkingLot.exportFileName'));
        },

        async addProductsToPromotion(
            { dispatch, getters, commit },
            { promotionId, newProducts, productOfferGroupId, skipCostsPrices = false }
        ) {
            const selectedPromotion = getters.getStagingAreaPromotionById(promotionId);

            const updatedProducts = [...selectedPromotion.products, ...newProducts];

            await dispatch('setStagingAreaField', {
                namespace: promotionId,
                fieldName: 'products',
                value: updatedProducts,
            });

            // we are not emiting event above but we need to update description
            await dispatch('updateOfferMechanicDescription', {
                namespace: promotionId,
            });

            await dispatch('initialiseProductsInPromotion', {
                products: newProducts,
                namespace: promotionId,
                storeGroups: selectedPromotion.storeGroups,
                startDate: selectedPromotion.startDate,
                endDate: selectedPromotion.endDate,
                productOfferGroupId,
                skipCostsPrices,
            });

            this.$app.globalEmit(UXEvents.candidateAddedToPromotion, {
                namespace: promotionId,
                savePromotion: false,
            });

            commit('setUnsavedPromotion', {
                namespace: promotionId,
                tab: tabsEnum.offer,
                value: true,
            });
        },

        async assignProductsToPOG(
            { state, dispatch },
            { namespace, products, productOfferGroupId }
        ) {
            const stagingAreaPOGs = state.stagingArea[namespace].productOfferGroups;

            const productOfferGroupToUpdate = stagingAreaPOGs.find(
                offerGroup => offerGroup._id === productOfferGroupId
            );

            productOfferGroupToUpdate.products.push(...map(products, p => p.productKey));

            await dispatch('setStagingAreaField', {
                namespace,
                fieldName: 'productOfferGroups',
                value: stagingAreaPOGs,
            });
        },

        async getPromotionUserSelectedCategories({ state }, { namespace, chosenCategories }) {
            const promotionCategories = state.stagingArea[namespace].userSelectedCategories;

            if (isCategoryWidePromotion(state.stagingArea[namespace])) {
                return promotionCategories;
            }

            const promotionProductsGroupedByCategory = groupBy(
                state.stagingArea[namespace].products,
                'category'
            );
            const potentiallyRemovedCats = differenceBy(
                promotionCategories,
                chosenCategories,
                'levelEntryKey'
            );
            const removedCats = potentiallyRemovedCats
                .filter(cat => isEmpty(promotionProductsGroupedByCategory[cat.levelEntryKey]))
                .map(cat => cat.levelEntryKey);

            return promotionCategories.filter(cat => !removedCats.includes(cat.levelEntryKey));
        },

        async removeProductsFromPOG(
            { state, dispatch, rootState },
            { namespace, productsToRemove, productOfferGroupId }
        ) {
            const stagingAreaPOGs = state.stagingArea[namespace].productOfferGroups;

            const productOfferGroupToUpdate = stagingAreaPOGs.find(
                offerGroup => offerGroup._id === productOfferGroupId
            );

            // productOfferGroupToUpdate was removed with products
            if (!productOfferGroupToUpdate) {
                return;
            }

            const categoriesSelectedByUser = cloneDeep(
                productOfferGroupToUpdate.userSelectedCategories
            );

            pullAll(productOfferGroupToUpdate.products, productsToRemove);

            const pogProducts = state.stagingArea[namespace].products.filter(p =>
                productOfferGroupToUpdate.products.includes(p.productKey)
            );

            const pogCategories = pogProducts.map(p => {
                const cat = p.hierarchy.find(
                    h => h.level === rootState.clientConfig.hierarchyConfig.categoryLevel
                );
                return cat;
            });

            productOfferGroupToUpdate.userSelectedCategories = uniqBy(
                [...pogCategories, ...categoriesSelectedByUser],
                'levelEntryKey'
            );

            const promotionUserSelectedCategories = await dispatch(
                'getPromotionUserSelectedCategories',
                { chosenCategories: [...pogCategories, ...categoriesSelectedByUser], namespace }
            );

            const fieldsToUpdate = [
                {
                    fieldName: 'userSelectedCategories',
                    value: isStoreWidePromotion(state.stagingArea[namespace])
                        ? []
                        : promotionUserSelectedCategories,
                },
                {
                    fieldPath: 'productOfferGroups',
                    value: stagingAreaPOGs,
                },
            ];

            await dispatch('setStagingAreaFields', {
                namespace,
                fieldsToUpdate,
            });

            this.$app.globalEmit(UXEvents.promotionProductsUpdatedForGridRefresh, {
                namespace,
                products: productsToRemove,
                removed: true,
            });
        },

        async initialiseProductsInPromotion(
            { commit, dispatch },
            {
                namespace,
                products,
                storeGroups,
                startDate,
                endDate,
                productGroup,
                productOfferGroupId,
                skipCostsPrices = false,
            }
        ) {
            await Promise.all(
                products.map(product => {
                    setInitialFunding(product);
                    setInitialVolumes(product);
                    return product;
                })
            );

            if (startDate && endDate) {
                const params = {
                    productKeys: products.map(p => p.productKey),
                    startDate,
                    endDate,
                };

                const suppliers = await dispatch(
                    'suppliers/fetchSCIByProductKeys',
                    { params },
                    { root: true }
                );

                // update supplierCaseInformation to default
                products.forEach(product => {
                    const { mainSupplier, newSupplierCaseInformation } = identifyMainSupplierAndSCI(
                        {
                            availableSuppliers: suppliers.filter(
                                sp => String(sp.productKey) === String(product.productKey)
                            ),
                            currentProductSupplierKey: product.supplierKey,
                        }
                    );

                    const supplierCaseInformation = (newSupplierCaseInformation || []).map(
                        caseInfo => {
                            return {
                                ...caseInfo,
                                isSelected: caseInfo.isDefaultSupplierCase,
                                volumeSplit: caseInfo.isDefaultSupplierCase ? 100 : 0,
                                buyingPrice: null,
                            };
                        }
                    );

                    product.supplierCaseInformation = supplierCaseInformation;

                    if (
                        (product.supplierCaseInformation || []).length === 1 &&
                        !supplierCaseInformation[0].isSelected
                    ) {
                        supplierCaseInformation[0].isSelected = true;
                    }

                    if (mainSupplier) {
                        product.supplierKey = mainSupplier.supplierKey;
                        product.clientSupplierKey = mainSupplier.clientSupplierKey;
                        product.supplierName = mainSupplier.supplierName;
                    }

                    commit('updateSupplierCaseInformationInPromotion', {
                        namespace,
                        product,
                        supplierCaseInformation,
                        productGroup,
                    });
                });
            }

            if (!skipCostsPrices) {
                // Fetch the product prices and costs and add them to the new products in the promotion.
                await dispatch('updateProductPricesOnPromotion', {
                    namespace,
                    products,
                    storeGroups,
                    startDate,
                    productGroup,
                });
            }

            if (productOfferGroupId) {
                return dispatch('assignProductsToPOG', {
                    namespace,
                    products,
                    productOfferGroupId,
                });
            }
        },

        async updateProductPricesOnPromotion(
            { rootState, dispatch, commit },
            {
                namespace,
                products,
                storeGroups,
                startDate = Vue.moment().format('YYYY-MM-DD'),
                productGroup = null,
            }
        ) {
            if (!products.length) return;
            const distinctProductKeys = uniq(
                products.map(product => product.proxyProductKey || product.productKey)
            );

            let productPrices = [];
            let productCosts = [];

            if (size(distinctProductKeys) > 0) {
                const params = {
                    products: distinctProductKeys,
                    storeGroups: map(storeGroups, 'key'),
                    startDate,
                };
                [productPrices, productCosts] = await Promise.all([
                    dispatch('productPrices/fetchProductPrices', { params }, { root: true }),
                    dispatch('productCosts/fetchProductCosts', { params }, { root: true }),
                ]);
            }

            const defaultProductPrices = {
                avgNonPromoPrice: null,
                avgNonPromoPriceExcTax: null,
                maxNonPromoPrice: null,
                minNonPromoPrice: null,
            };
            const defaultCosts = {
                onInvoiceCosts: { avgNonPromoCost: 0, maxNonPromoCost: 0, minNonPromoCost: 0 },
                commercialCosts: { avgNonPromoCost: 0, maxNonPromoCost: 0, minNonPromoCost: 0 },
            };

            // Key the product prices by productKey for easier access when adding
            // prices to products below.
            const keyedProductPrices = keyBy(productPrices, 'productKey');
            const keyedProductCosts = keyBy(productCosts, 'productKey');

            products.forEach(product => {
                commit('addCostsAndNonPromoPricesToProduct', {
                    namespace,
                    product,
                    productPrices: keyedProductPrices[
                        product.proxyProductKey || product.productKey
                    ] || {
                        ...defaultProductPrices,
                        productKey: product.productKey,
                    },
                    productCosts:
                        keyedProductCosts[product.proxyProductKey || product.productKey] ||
                        defaultCosts,
                    productGroup,
                    precisionForPricesAndCosts:
                        rootState.clientConfig.generalConfig.precisionForPricesAndCosts,
                });
            });
        },

        async updateProductPricesOnPromotionById({ dispatch, getters }, { namespace }) {
            const { products, startDate, storeGroups } = getters.getPromotionForForecasting({
                promotionId: namespace,
            });
            await dispatch('updateProductPricesOnPromotion', {
                namespace,
                products,
                startDate,
                storeGroups,
            });

            this.$app.globalEmit(UXEvents.promotionUpdatedForPromoPriceRecalculation, {
                namespace,
            });
        },

        async updatePromotionProductSuppliers({ dispatch, getters }, { namespace }) {
            const promotion = getters.getPromotionForForecasting({
                promotionId: namespace,
            });
            const updatedProducts = await dispatch(
                'suppliers/updatePromotionProductSuppliers',
                { promotion },
                { root: true }
            );

            return dispatch('setStagingAreaField', {
                namespace,
                fieldName: 'products',
                value: updatedProducts,
            });
        },

        async generateChildTotalVolumeAggregations({ commit, state }, { promotionId }) {
            // generates familyTotalVolume and adds the data to product volumes
            commit('setLoading', true);
            const [error, response] = await to(
                axios.get(`/api/promotions/${promotionId}/childTotalVolumeAggregations`)
            );
            commit('setLoading', false);
            if (error) throw error;

            const { childTotalVolumes } = response.data;

            // productKey is projected as _id in aggregation.
            const keyedChildTotalVolumes = keyBy(childTotalVolumes, '_id');
            const stagingAreaProducts = get(state.stagingArea[promotionId], 'products', []);

            stagingAreaProducts.forEach(productToUpdate => {
                const childTotalVolume = get(
                    keyedChildTotalVolumes[productToUpdate.productKey],
                    'childVolumes',
                    0
                );

                Vue.set(productToUpdate, 'childVolumes', childTotalVolume);
            });
        },

        async fetchSelectedSubcampaignPromotionAggregations({ commit, rootGetters, rootState }) {
            const selectedSubCampaignId = rootState.subCampaigns.selectedSubCampaignId;
            const scenarioIds = rootGetters['scenarios/allScenariosIdsBySubCampaignId'](
                selectedSubCampaignId
            );

            const tagsInFilter = get(rootState, ['tagMetadata', 'filter', 'tags'], []);

            const statesInFilter = get(rootState, ['promotions', 'filter', 'clientState'], []);
            const clientStatesForUser = clientStateRolesUtils.generateClientStateFilter({
                clientStateRoles: rootState.clientConfig.clientStateRoles,
                userRoles: rootGetters['context/userRoles'],
                toggleLogic: rootState.clientConfig.toggleLogic,
                returnArray: true,
            });

            let allowedStates;
            if (!isEmpty(clientStatesForUser) && !isEmpty(statesInFilter)) {
                allowedStates = intersection(clientStatesForUser, statesInFilter);
            } else if (!isEmpty(clientStatesForUser)) {
                allowedStates = clientStatesForUser;
            } else {
                allowedStates = statesInFilter;
            }

            const params = {
                scenarioIds,
                tags: tagsInFilter,
                clientStates: allowedStates,
            };

            commit('setLoading', true);
            const promotionsAggregatedByScenario = await axios.get(
                '/api/promotions/promotionsAggregatedByScenario',
                { params }
            );

            commit('setPromotionsAggregatedByScenario', {
                promotionsAggregatedByScenario: promotionsAggregatedByScenario.data.promotions,
            });
            commit('setLoading', false);
        },

        async fetchPromotionsForSelectedSubCampaign({ dispatch, rootGetters, rootState }) {
            const subCampaign =
                rootGetters['subCampaigns/selectedSubCampaign'] ||
                rootState.parkingLotFilters.selections.subCampaign;
            if (subCampaign) {
                await dispatch('fetchPromotionsForSubCampaign', {
                    subCampaign,
                    timeoutExecution: false,
                });
            }
        },

        async fetchPromotionsForSubCampaign(
            { state, getters, rootState, dispatch, rootGetters },
            { subCampaign, scenario = null, timeoutExecution = true, promotionFields = [] }
        ) {
            // client state filter here based on user roles
            const clientStateFilter = clientStateRolesUtils.generateClientStateFilter({
                clientStateRoles: rootState.clientConfig.clientStateRoles,
                userRoles: rootGetters['context/userRoles'],
                toggleLogic: rootState.clientConfig.toggleLogic,
            });
            // If no specific scenario provided,
            // then we fetch for all scenarios within the sub-campaign
            const scenarioIds = scenario
                ? [scenario._id]
                : rootGetters['scenarios/allScenariosIdsBySubCampaignId'](subCampaign._id);
            // If we click on subCampaign with the same parent we add promotions to the store and patch stored ones
            if (subCampaign.campaignId === rootState.subCampaigns.selectedSubCampaignParentId) {
                await dispatch('fetchPromotions', {
                    params: {
                        where: {
                            scenarioId: {
                                $in: scenarioIds,
                            },
                            ...(rootState.clientConfig.toggleLogic[allowStoreWidePromotions]
                                ? { storeWideCategories: get(subCampaign, 'categories') }
                                : {}),
                            ...clientStateFilter,
                            isGhost: true,
                        },
                        pick: promotionFields.map(field => `{"${field}": 1}`),
                    },
                    // if we already have promotions stored for this subCampaign they will be overwritten
                    patchState: true,
                    patchOptions: {
                        fieldToMatchOn: '_id',
                        isMatchFunction: promotion => includes(scenarioIds, promotion.scenarioId),
                    },
                });
            } else {
                // If we switched to sub-campaign with different parent, promotions will be overwritten.
                await dispatch('fetchPromotions', {
                    params: {
                        where: {
                            scenarioId: {
                                $in: scenarioIds,
                            },
                            ...(rootState.clientConfig.toggleLogic[allowStoreWidePromotions]
                                ? { storeWideCategories: get(subCampaign, 'categories') }
                                : {}),
                            ...clientStateFilter,
                            isGhost: true,
                        },
                        pick: promotionFields.map(field => `{"${field}": 1}`),
                    },
                });
            }

            if (state.selectedPromotionId && getters.promotionMap[state.selectedPromotionId]) {
                // If we have a selected promotion, ensure it's full details are loaded as well.
                await dispatch('fetchPromotions', {
                    params: {
                        where: {
                            _id: state.selectedPromotionId,
                            isGhost: true,
                            ...(rootState.clientConfig.toggleLogic[allowStoreWidePromotions]
                                ? { storeWideCategories: get(subCampaign, 'categories') }
                                : {}),
                        },
                    },
                    patchState: true,
                    patchOptions: {
                        fieldToMatchOn: '_id',
                        isMatchFunction: promotion => promotion._id === state.selectedPromotionId,
                    },
                });
            }

            if (timeoutExecution) {
                return dispatch('timeoutExecution');
            }
        },

        async fetchPromotionsForScenario({ rootState, dispatch, rootGetters }, { scenario }) {
            if (isNil(scenario)) {
                return;
            }

            // client state filter here based on user roles
            const clientStateFilter = clientStateRolesUtils.generateClientStateFilter({
                clientStateRoles: rootState.clientConfig.clientStateRoles,
                userRoles: rootGetters['context/userRoles'],
                toggleLogic: rootState.clientConfig.toggleLogic,
            });
            const selectedSubCampaign = rootGetters['subCampaigns/selectedSubCampaign'];

            await dispatch('fetchPromotions', {
                params: {
                    where: {
                        scenarioId: scenario._id,
                        ...(rootState.clientConfig.toggleLogic[allowStoreWidePromotions]
                            ? { storeWideCategories: selectedSubCampaign.categories }
                            : {}),
                        ...clientStateFilter,
                        isGhost: true,
                    },
                },
                // if we already have promotions stored for selected subCampaign they will be overwritten
                patchState: true,
                patchOptions: {
                    fieldToMatchOn: '_id',
                    isMatchFunction: promotion => scenario._id === promotion.scenarioId,
                },
            });
        },

        async fetchPromotionChangeset(
            { dispatch },
            { promotionId, parentId, versionReference } = {}
        ) {
            const [error, response] = await to(
                axios.get(
                    `/api/promotion-changesets/forVersionReference/${promotionId}/${parentId}/${versionReference}`
                )
            );

            if (error) {
                return dispatch('handleResponseNotifications', {
                    error,
                    errorMessage: i18n.t('notifications.fetchError', {
                        resource: i18n.tc('entities.promotions', 2),
                    }),
                });
            }

            return response.data;
        },

        timeoutExecution({ state, rootGetters }) {
            const now = Vue.moment();
            const { callbackTimeout } = rootGetters['clientConfig/getExecutionApiConfig'];
            state.promotions.forEach(async promotion => {
                const { executionId, executionTimestamp } = promotion.execution;
                if (executionId) {
                    const endTime = Vue.moment(executionTimestamp).add(callbackTimeout, 's');
                    const diff = endTime.diff(now);

                    if (diff < 0) {
                        await axios.post('/api/execution-api/timeout', {
                            promotionId: promotion._id,
                        });
                    } else {
                        setTimeout(async () => {
                            await axios.post('/api/execution-api/timeout', {
                                promotionId: promotion._id,
                            });
                        }, diff);
                    }
                }
            });
        },

        setPromotionExecution(
            { dispatch, commit },
            { promotionId, executionId, execution = null }
        ) {
            commit('setPromotionExecution', {
                promotionId,
                executionId,
                executionResult: execution,
            });
            dispatch('execution/setPromotionExecutionTimeout', { promotionId }, { root: true });
        },

        setPromotionApplyingNotificationsFlag({ commit }, { entityId, value = true }) {
            commit('setPromotionApplyingNotificationsFlag', { entityId, value });
        },

        async fetchPromotionsForSupplierCommitment(
            { commit, dispatch, rootGetters, rootState },
            supplierCommitmentId
        ) {
            const supplierCommitment = rootGetters['supplierCommitments/getSupplierCommitmentById'](
                {
                    _id: supplierCommitmentId,
                    usePluralResourceName: true,
                }
            );

            // client state filter here based on user roles
            const clientStateFilter = clientStateRolesUtils.generateClientStateFilter({
                clientStateRoles: rootState.clientConfig.clientStateRoles,
                userRoles: rootGetters['context/userRoles'],
                toggleLogic: rootState.clientConfig.toggleLogic,
            });

            const promotions = await dispatch('fetchPromotions', {
                params: {
                    where: {
                        _id: {
                            $in: supplierCommitment.allocatedPromotionIds,
                        },
                        ...clientStateFilter,
                    },
                },
                returnDocuments: true,
            });

            commit('setPromotionsForSelectedSupplierCommitment', promotions);
        },
        applyOfferMechanicTemplate({ commit }, { namespace, config, offerMechanicField }) {
            forEach(config, (fieldConfig, key) => {
                commit('setStagingAreaField', {
                    namespace,
                    fieldPath: fieldConfig.path
                        ? `${offerMechanicField}.${fieldConfig.path}`
                        : offerMechanicField,
                    fieldName: key,
                    value: cloneDeep(fieldConfig.value),
                });
            });
        },
        async applyPromoPricesToProducts({ dispatch, state, rootState }, { namespace }) {
            const stagingAreaProducts = state.stagingArea[namespace].products;
            const promoPrices = promoPriceCalculator({
                products: stagingAreaProducts,
                offerMechanic: state.stagingArea[namespace].offerMechanic,
                productOfferGroups: state.stagingArea[namespace].productOfferGroups,
                precision: rootState.clientConfig.generalConfig.precisionForPricesAndCosts,
            });
            // Key the promo prices by product key for more efficient lookups below.
            const promoPricesKeyed = keyBy(promoPrices, 'product.productKey');

            const productUpdates = [];
            stagingAreaProducts.forEach(({ productKey }, ix) => {
                const promoPricesForProduct = promoPricesKeyed[productKey];
                // Skip products which we do not have promo prices for.
                if (promoPricesForProduct) {
                    productUpdates.push({
                        fieldPath: `products[${ix}]`,
                        fieldName: 'promoPrices',
                        value: promoPricesForProduct.promoPrices,
                    });
                }
            });

            await dispatch('setStagingAreaFields', {
                namespace,
                fieldsToUpdate: productUpdates,
            });
            this.$app.globalEmit(UXEvents.promotionProductsUpdatedForGridRefresh, {
                namespace,
                products: stagingAreaProducts,
                added: true,
            });
        },

        applyPromoPricesToProductsWithoutSaving({ dispatch }, { namespace }) {
            return dispatch('applyPromoPricesToProducts', {
                namespace,
                savePromotion: false,
            });
        },

        async fetchWeeklyMetrics(
            { commit, dispatch, rootState, rootGetters },
            { startDate, endDate } = {}
        ) {
            commit('setLoadingWeeklyMetrics', true);
            const selectedCampaignId = get(rootGetters['campaigns/selectedCampaign'], '_id', null);

            // if no startDate provided as param and
            // if the firstWeekObj.startDate is after Jan 1st previous year
            // then need to fetch weekly metrics starting from Jan 1st previous year
            // to be able to calculate previous YTD metrics
            const previousYear = Vue.moment().year() - 1;
            if (
                !startDate &&
                Vue.moment({ year: previousYear }).isBefore(
                    Vue.moment(rootGetters['weeks/firstWeekObj'].startDate)
                )
            ) {
                startDate = `${previousYear}-01-01`;
            }
            endDate = endDate || rootGetters['weeks/lastWeekObj'].endDate;

            const categoriesToFilterOn = rootGetters['hierarchy/getCategoriesInFilter'];
            const tagsToFilterOn = get(rootState, ['tagMetadata', 'filter', 'tags'], []);
            const statesToFilterOn = get(rootState, ['promotions', 'filter', 'clientState'], []);
            const clientStatesForUser = clientStateRolesUtils.generateClientStateFilter({
                clientStateRoles: rootState.clientConfig.clientStateRoles,
                userRoles: rootGetters['context/userRoles'],
                toggleLogic: rootState.clientConfig.toggleLogic,
                returnArray: true,
            });

            let allowedStates;
            if (!isEmpty(clientStatesForUser) && !isEmpty(statesToFilterOn)) {
                allowedStates = intersection(clientStatesForUser, statesToFilterOn);
            } else if (!isEmpty(clientStatesForUser)) {
                allowedStates = clientStatesForUser;
            } else {
                allowedStates = statesToFilterOn;
            }

            const params = {
                startDate,
                endDate,
                selectedCampaignId,
            };
            if (categoriesToFilterOn && categoriesToFilterOn.length) {
                params.where = {
                    categories: { $in: categoriesToFilterOn },
                };
            }

            if (tagsToFilterOn && tagsToFilterOn.length) {
                params.tagFilter = {
                    tags: {
                        $elemMatch: { tagKey: { $in: tagsToFilterOn } },
                    },
                };
            }

            if (allowedStates && allowedStates.length) {
                params.clientStateFilter = { clientState: { $in: allowedStates } };
            }

            const weeklyMetrics = await axios.get('/api/promotions/weeklyMetrics', {
                params,
            });
            commit('setWeeklyMetrics', weeklyMetrics.data);
            dispatch(
                'weeks/updateDeltaKPIMetricsMap',
                { weeklyMetrics: weeklyMetrics.data },
                { root: true }
            );
            commit('setLoadingWeeklyMetrics', false);
        },

        fetchSubCampaignWeeklyMetrics({ dispatch, rootState, rootGetters }) {
            const selectedCampaign = rootGetters['campaigns/getCampaignById']({
                _id: rootState.subCampaigns.selectedSubCampaignParentId,
                usePluralResourceName: true,
            });

            // if sub-campaign is selected we need to display weekly metrics for sub-campaign and it's siblings
            if (selectedCampaign) {
                const startDate = rootGetters['weeks/getWeekByDate'](selectedCampaign.startDate)
                    .startDate;
                const endDate = rootGetters['weeks/getWeekByDate'](selectedCampaign.endDate)
                    .endDate;

                dispatch('fetchWeeklyMetrics', { startDate, endDate });
            }
        },

        logEntity({ getters }, { entityId }) {
            console.log(`promotion: ${entityId} `, getters.getPromotionById(entityId));
        },

        addTier({ commit, state, rootState }, { namespace, tierIndex }) {
            const { tiers } = state.stagingArea[namespace].offerMechanic;
            if (tiers.length < rootState.clientConfig.generalConfig.maxOfferMechanicTierCount) {
                commit('addTier', { namespace, tierIndex });
            }
        },

        // replace the contents of the staging area with the selected promotion
        populateStagingAreaWithSelectedPromo({ state, getters }) {
            const { selectedPromotionId } = state;
            const selectedPromotion = getters.getPromotionById(selectedPromotionId);
            // namespace is selectedPromotionId
            state.stagingArea[selectedPromotionId] = selectedPromotion;
        },

        validatePromotionMaintenanceArea(
            { commit, getters, rootState, rootGetters },
            submittedPromotion
        ) {
            // check namespace, as just namespace is used as a parameter in promotionIsInvalid event
            // parkingLotSaved event is emitted after dialog is closed and staging area is cleaned,
            // in this case we use submittedPromotion that comes from params
            if (isNil(submittedPromotion._id) && submittedPromotion.namespace) {
                submittedPromotion = getters.getStagingAreaPromotionById(
                    submittedPromotion.namespace
                );
            }
            const promotion = submittedPromotion || getters.selectedPromotion;
            const scenario = rootGetters['scenarios/selectedScenario'];
            const toggleLogic = rootState.clientConfig.toggleLogic;
            const rateCards = rootGetters['rateCards/getAllRateCards'];

            const featureAwareFactory = createFeatureAwareFactory(toggleLogic);

            // Validation functions for each tab can be get from featureAwareFactory
            const dateValidatorFunction = featureAwareFactory.getDateValidatorFunction();
            const channelsStoresValidatorFunction = featureAwareFactory.getChannelsStoresValidatorFunction();
            const mechanicValidatorFunction = featureAwareFactory.getMechanicValidatorFunction();
            const productsValidatorFunction = featureAwareFactory.getProductsValidatorFunction();
            const supplierValidatorFunction = featureAwareFactory.getSupplierValidatorFunction();
            const supplyValidatorFunction = featureAwareFactory.getSupplyValidatorFunction();
            const offerValidatorFunction = featureAwareFactory.getOfferValidatorFunction();

            const dateState = dateValidatorFunction({ scenario, promotion });
            const channelsStoresState = channelsStoresValidatorFunction(promotion);
            const mechanicState = mechanicValidatorFunction({ promotion });
            const productsState = productsValidatorFunction({ promotion });
            const supplierState = supplierValidatorFunction({ promotion, rateCards });
            const supplyState = supplyValidatorFunction({ promotion });
            const offerState = offerValidatorFunction({ promotion });

            const tabValidationState = {
                [tabsEnum.date]: dateState,
                [tabsEnum.channels]: channelsStoresState,
                [tabsEnum.mechanic]: mechanicState,
                [tabsEnum.products]: productsState,
                [tabsEnum.suppliers]: supplierState,
                [tabsEnum.supply]: supplyState,
                [tabsEnum.offer]: offerState,
            };

            commit('setTabValidationState', tabValidationState);
        },
        clearTabValidationState({ commit }) {
            commit('setTabValidationState', {});
        },
        updateOfferMechanicDescription({ dispatch, getters, rootState }, { namespace }) {
            const stagingAreaPromotion = getters.getStagingAreaPromotionById(namespace);
            const stagingPromoContainsMechanic =
                stagingAreaPromotion && stagingAreaPromotion.offerMechanic;
            // the promotion may not be in the staging area if being edited on the parkinglot or sub-campaign allocation.
            const promotion = stagingPromoContainsMechanic
                ? stagingAreaPromotion
                : getters.getPromotionById(namespace);
            const description = generateOfferMechanicDescription({
                offerMechanic: promotion.offerMechanic,
                products: promotion.products,
                offerGroups: promotion.productOfferGroups,
                isDescriptionWithoutProducts:
                    rootState.clientConfig.generalConfig.offerMechanicDescriptionWithoutProducts,
            });

            if (!stagingPromoContainsMechanic) {
                const { offerMechanic } = promotion;
                offerMechanic.description = description;
                return dispatch('updatePromotion', {
                    id: promotion._id,
                    updates: { offerMechanic },
                });
            }

            return dispatch('setStagingAreaField', {
                namespace,
                fieldPath: 'offerMechanic',
                fieldName: 'description',
                value: description,
            });
        },
        generateAlternativeMechanics({ dispatch, getters, rootState }, { promotionId }) {
            const promotionForForecasting = getters.getPromotionForForecasting({
                promotionId,
            });

            const maxNumberOfAlternativeMechanics =
                rootState.clientConfig.toggleLogic.maxNumberOfAlternativeMechanics;
            dispatch(
                'forecasting/forecastSinglePromotion',
                {
                    promotion: {
                        ...promotionForForecasting,
                        maxNumberOfAlternativeMechanics,
                    },
                },
                { root: true }
            );
        },
        setCustomTemplate({ commit }, { offerMechanic, namespace }) {
            if (offerMechanic.offerTemplate !== offerMechanicTemplates.custom) {
                commit('setStagingAreaField', {
                    namespace,
                    fieldPath: 'offerMechanic',
                    fieldName: 'offerTemplate',
                    value: offerMechanicTemplates.custom,
                });
            }
        },

        prepareAndUpdatePromotion({ dispatch }, payload) {
            const updates = payload.updates;

            return dispatch('updatePromotion', {
                ...payload,
                updates,
            });
        },

        async movePromotionsToScenario({ commit, dispatch }, { scenario, promotionIds }) {
            commit('setLoading', true);
            const [error, response] = await to(
                axios.post('/api/promotions/moveToScenario', {
                    scenarioId: scenario._id,
                    promotionIds,
                })
            );
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t(`notifications.moveSuccess`, {
                    resource: i18n.tc('entities.promotions', promotionIds.length > 1 ? 2 : 1),
                }),
                errorMessage: i18n.t(`notifications.moveError`, {
                    resource: i18n.tc('entities.promotions', promotionIds.length > 1 ? 2 : 1),
                }),
            });
            commit('setLoading', false);
            if (error) {
                throw error;
            }

            return response.data;
        },

        async movePromotionsToParkingLot(
            { commit, dispatch, getters, rootGetters },
            {
                promotionIds,
                startDate,
                endDate,
                copyPromotions = false,
                sendUpdatedDates = false,
                updateForecastReference = false,
                removeNonPromotableProducts = false,
            }
        ) {
            commit('setLoading', true);

            const promotionsWithChildrenIds = promotionIds.filter(id => {
                const promotion = getters.getPromotionById(id);
                if (!promotion || !promotion.scenarioId) {
                    return false;
                }
                const { scenarioId } = promotion;
                const { subCampaignId } = rootGetters['scenarios/getScenarioById']({
                    _id: scenarioId,
                    usePluralResourceName: true,
                });
                const { children } = rootGetters['subCampaigns/getSubCampaignById']({
                    _id: subCampaignId,
                    usePluralResourceName: true,
                });
                // check children for parent promotion
                return !isEmpty(children);
            });

            const checkIfChildrenExecutingResults = await Promise.allSettled(
                promotionsWithChildrenIds.map(id => {
                    return axios.post(`/api/promotions/checkChildrenExecutionStatus`, {
                        promotionId: id,
                    });
                })
            );

            const promotionsWithChildrenExecutingMap = promotionsWithChildrenIds.reduce(
                (acc, id, idx) => {
                    const { value, reason } = checkIfChildrenExecutingResults[idx];
                    const responseError =
                        reason || get(value, 'data.childrenExecutionStatus', false);
                    if (responseError) {
                        acc[id] = responseError;
                    }
                    return acc;
                },
                {}
            );

            const promotionIdsReadyToMoveToParkingLot = promotionIds.filter(
                id => !promotionsWithChildrenExecutingMap[id]
            );

            const dateParams = { startDate, endDate };
            const params = {
                promotionIds: promotionIdsReadyToMoveToParkingLot,
                copyPromotions,
                updateForecastReference,
                removeNonPromotableProducts,
                ...(sendUpdatedDates && dateParams),
            };

            const [error, response] = await to(
                axios.post('/api/promotions/moveToParkingLot', params)
            );
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t(`notifications.moveSuccess`, {
                    resource: i18n.tc('entities.promotions', promotionIds.length > 1 ? 2 : 1),
                }),
                errorMessage: i18n.t(`notifications.moveError`, {
                    resource: i18n.tc('entities.promotions', promotionIds.length > 1 ? 2 : 1),
                }),
            });
            if (error) {
                commit('setLoading', false);
                throw error;
            }

            if (isArray(response.data)) {
                const globalEvent = UXEvents.parkingLotSaved;
                response.data.forEach(promotion => {
                    this.$app.globalEmit(globalEvent, promotion);
                });
            }

            if (Object.values(promotionsWithChildrenExecutingMap).length) {
                dispatch(
                    'notifications/addNotification',
                    {
                        message: i18n.t('general.errors.cannotBeMomedToParkingLotError'),
                        severity: 'error',
                    },
                    { root: true }
                );
                // these lines were added to keep toast visible
                // whithout this, toast disapierd very fast it no depend on popupTimeout param
                // looks like issue is in vuetify
                await new Promise(resolve => {
                    setTimeout(() => resolve(), 2500);
                });
            }

            if (!copyPromotions) {
                // if we deleted promos from sub-campaign - refetch notifications
                dispatch('notifications/fetchOpenNotifications', {}, { root: true });
            }

            commit('setLoading', false);
        },

        async copyPromotions(
            { commit, dispatch, rootState, rootGetters },
            { scenarioId, promotionIds }
        ) {
            commit('setLoading', true);
            const [error, response] = await to(
                axios.post('/api/promotions/copy', {
                    scenarioId,
                    promotionIds,
                    removeNonPromotableProducts: true,
                })
            );
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t(`notifications.copySuccess`, {
                    resource: i18n.tc('entities.promotions', promotionIds.length > 1 ? 2 : 1),
                }),
                errorMessage: i18n.t(`notifications.copyError`, {
                    resource: i18n.tc('entities.promotions', promotionIds.length > 1 ? 2 : 1),
                }),
            });
            if (error) {
                commit('setLoading', false);
                throw error;
            }

            // Re-fetch the promotions, promotionAggreagations and weeklyMetrics if they are being copied to the same selected sub-campaign
            const selectedSubCampaignId = rootState.subCampaigns.selectedSubCampaignId;
            const selectedSubCampaign = rootGetters['subCampaigns/selectedSubCampaign'];
            const scenarioIds = rootGetters['scenarios/allScenariosIdsBySubCampaignId'](
                selectedSubCampaignId
            );
            // client state filter here based on user roles
            const clientStateFilter = clientStateRolesUtils.generateClientStateFilter({
                clientStateRoles: rootState.clientConfig.clientStateRoles,
                userRoles: rootGetters['context/userRoles'],
                toggleLogic: rootState.clientConfig.toggleLogic,
            });
            const isRefetchNeeded = scenarioIds.some(id => id === scenarioId);
            if (isRefetchNeeded) {
                const fetchPromotions = dispatch('fetchPromotions', {
                    params: {
                        where: {
                            scenarioId: {
                                $in: scenarioIds,
                            },
                            ...(rootState.clientConfig.toggleLogic[allowStoreWidePromotions]
                                ? { storeWideCategories: selectedSubCampaign.categories }
                                : {}),
                            ...clientStateFilter,
                            isGhost: true,
                        },
                    },
                });
                const fetchSelectedSubcampaignPromotionAggregations = dispatch(
                    'fetchSelectedSubcampaignPromotionAggregations'
                );

                await Promise.all([fetchPromotions, fetchSelectedSubcampaignPromotionAggregations]);
            }
            commit('setLoading', false);
        },
        setDefaultPromotionProduct(
            { state, dispatch, commit },
            { newDefaultProductKey, namespace }
        ) {
            const currentDefaultProductIndex = state.stagingArea[namespace].products.findIndex(
                product => product.isDefault
            );

            const newDefaultProductIndex = state.stagingArea[namespace].products.findIndex(
                product => product.productKey === newDefaultProductKey
            );

            if (newDefaultProductIndex < 0) {
                throw new Error('Could not set the new default product because it does not exist.');
            }

            const stagingAreaUpdates = [];
            // There may not always be a default product set.
            if (currentDefaultProductIndex >= 0) {
                stagingAreaUpdates.push({
                    fieldPath: `products[${currentDefaultProductIndex}]`,
                    fieldName: 'isDefault',
                    value: false,
                });
            }

            stagingAreaUpdates.push({
                fieldPath: `products[${newDefaultProductIndex}]`,
                fieldName: 'isDefault',
                value: true,
            });

            dispatch('setStagingAreaFields', {
                namespace,
                fieldsToUpdate: stagingAreaUpdates,
            });
            commit('setUnsavedPromotion', { namespace, tab: tabsEnum.channels, value: true });
            return { previousIndex: currentDefaultProductIndex, newIndex: newDefaultProductIndex };
        },
        setProductPriceByWeight({ state, dispatch, commit }, { productKey, value, namespace }) {
            const productIndex = state.stagingArea[namespace].products.findIndex(
                product => product.productKey === productKey
            );

            dispatch('setStagingAreaField', {
                namespace,
                fieldPath: `products[${productIndex}]`,
                fieldName: 'priceByWeight',
                value,
            });
            commit('setUnsavedPromotion', { namespace, tab: tabsEnum.channels, value: true });
        },
        setProductSecondaryPlacement(
            { state, dispatch, commit },
            { productKey, value, namespace }
        ) {
            const productIndex = state.stagingArea[namespace].products.findIndex(
                product => product.productKey === productKey
            );

            dispatch('setStagingAreaField', {
                namespace,
                fieldPath: `products[${productIndex}]`,
                fieldName: 'secondaryPlacement',
                value,
            });
            commit('setUnsavedPromotion', { namespace, tab: tabsEnum.channels, value: true });
        },
        setPromotionProductRank({ state, dispatch, commit }, { productKey, rank, namespace }) {
            const products = state.stagingArea[namespace].products;
            const productIndex = products.findIndex(product => product.productKey === productKey);

            if (productIndex < 0) {
                throw new Error('Could not set the new rank of product because it does not exist.');
            }

            // need to update the whole product instead of just one field to fix reactivity issue
            dispatch('setStagingAreaField', {
                namespace,
                fieldPath: `products`,
                fieldName: productIndex,
                fullEventPath: `products[${productIndex}].rank`,
                value: { ...products[productIndex], rank: rank === '' ? null : rank },
            });
            commit('setUnsavedPromotion', { namespace, tab: tabsEnum.channels, value: true });
        },
        setPromotionProductListPrice(
            { state, dispatch, commit },
            { productKey, nonPromoListPrice, nonPromoListPriceSameAsSystem, namespace }
        ) {
            const products = state.stagingArea[namespace].products;
            const productIndex = products.findIndex(product => product.productKey === productKey);

            if (productIndex < 0) {
                throw new Error(
                    'Could not update list price attributes because the product does not exist.'
                );
            }

            dispatch('setStagingAreaField', {
                namespace,
                fieldPath: `products`,
                fieldName: productIndex,
                fullEventPath: `products[${productIndex}].nonPromoListPrice`,
                value: {
                    ...products[productIndex],
                    nonPromoListPrice,
                    nonPromoListPriceSameAsSystem,
                },
            });
            commit('setUnsavedPromotion', { namespace, tab: tabsEnum.channels, value: true });
        },
        clearDefaultPromotionProduct({ state, dispatch }, { namespace }) {
            const currentDefaultProductIndex = state.stagingArea[namespace].products.findIndex(
                product => product.isDefault
            );

            if (currentDefaultProductIndex < 0) {
                throw new Error(
                    'Could not clear the selected default product because it does not exist.'
                );
            }

            dispatch('setStagingAreaField', {
                namespace,
                fieldPath: `products[${currentDefaultProductIndex}]`,
                fieldName: 'isDefault',
                value: false,
            });
        },
        addNewDetailedProvision({ state }, { namespace, instanceKey, newDetailedProvision }) {
            const resourceToUpdate = state.stagingArea[namespace].resources.find(resource =>
                resource.instances.some(instance => instance.key === instanceKey)
            );

            const instanceToUpdate = resourceToUpdate.instances.find(
                instance => instance.key === instanceKey
            );

            // We're purposefully not calling setStagingArea field here because
            // we don't want to forecast/save the newly added provisions since they won't
            // yet have a value selected.
            instanceToUpdate.detailedProvision.push(newDetailedProvision);
        },
        updateDetailedProvision(
            { state, dispatch, commit },
            { namespace, resourceType, instanceKey, newValue }
        ) {
            const overwriteObject = getResourceInstanceDetailedProvisionOverwriteObject({
                state,
                namespace,
                resourceType,
                instanceKey,
            });

            dispatch('setStagingAreaField', {
                namespace,
                fieldPath: overwriteObject.fieldPath,
                fieldName: `detailedProvision`,
                value: newValue,
            });
            commit('setUnsavedPromotion', { namespace, tab: tabsEnum.channels, value: true });
        },
        deleteDetailedProvision(
            { state, dispatch, commit },
            { namespace, resourceType, instanceKey }
        ) {
            const overwriteObject = getResourceInstanceDetailedProvisionOverwriteObject({
                state,
                namespace,
                resourceType,
                instanceKey,
            });

            dispatch('setStagingAreaField', {
                namespace,
                fieldPath: overwriteObject.fieldPath,
                fieldName: `detailedProvision`,
                value: [],
            });
            commit('setUnsavedPromotion', { namespace, tab: tabsEnum.channels, value: true });
        },
        async applyPromotionChangeset(
            { dispatch, commit, getters, rootGetters, rootState },
            { parentPromotionId, childPromotionId, parentVersionReference, changeset, changesetId }
        ) {
            commit('setChangesetApplyInProgress', { promoId: childPromotionId, value: true });
            commit('setLoading', true);

            const notification = getters.getPromotionNotifications({
                promotionId: childPromotionId,
                notificationKey: notificationTypes.parentPromotionUpdated,
            })[0];

            const subCampaign =
                rootGetters['subCampaigns/selectedSubCampaign'] ||
                rootState.parkingLotFilters.selections.subCampaign;

            const [error, response] = await to(
                axios.post('/api/promotions/publishChangeset', {
                    parentPromotionId,
                    changeset,
                    changesetId,
                    parentVersionReference,
                    childrenIds: [childPromotionId],
                    notificationId: notification._id,
                    skipExternalSystems: true,
                })
            );
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t(`notifications.applyChangesSuccess`, {
                    resource: i18n.tc('entities.promotions', 1),
                }),
                errorMessage: i18n.t(`notifications.applyChangesError`, {
                    resource: i18n.tc('entities.promotions', 1),
                }),
            });
            if (error) {
                commit('setChangesetApplyInProgress', { promoId: childPromotionId, value: false });
                throw error;
            }
            await dispatch(
                'notifications/closeNotification',
                {
                    notificationId: notification._id,
                },
                { root: true }
            );

            // reload promotion to which we applied changes
            await dispatch('fetchPromotions', {
                params: {
                    where: {
                        _id: childPromotionId,
                        ...(rootState.clientConfig.toggleLogic[allowStoreWidePromotions]
                            ? { storeWideCategories: get(subCampaign, 'categories') }
                            : {}),
                    },
                },
                patchState: true,
                patchOptions: {
                    fieldToMatchOn: '_id',
                    isMatchFunction: promotion => promotion._id === childPromotionId,
                },
            });

            const selectedPromotion = getters.selectedPromotion;

            // call mutation directly to skip autosave flow
            const fieldsToUpdate = [
                { fieldName: 'products', value: selectedPromotion.products },
                { fieldName: 'offerMechanic', value: selectedPromotion.offerMechanic },
                { fieldName: 'notification', value: selectedPromotion.notification },
                {
                    fieldName: 'additionalClientSpecificFields',
                    value: selectedPromotion.additionalClientSpecificFields,
                },
            ];
            commit('setStagingAreaFields', {
                namespace: childPromotionId,
                fieldsToUpdate,
            });

            await dispatch(
                'forecasting/forecastSinglePromotionById',
                { namespace: childPromotionId },
                { root: true }
            );

            commit('setChangesetApplyInProgress', { promoId: childPromotionId, value: false });
            commit('setLoading', false);
        },

        removeRateCardFunding({ getters, dispatch }, { rateCardId, namespace }) {
            const promotion = getters.getStagingAreaPromotionById(namespace);

            const productUpdates = [];
            promotion.products.forEach((product, index) => {
                const productLumpFunding = product.funding.lumpFunding.filter(
                    lf => String(lf.rateCardId) !== String(rateCardId)
                );
                const totalLumpFunding = productLumpFunding.reduce(
                    (sum, rc) => sum + rc.rateCardAmount,
                    0
                );
                productUpdates.push(
                    {
                        fieldName: 'lumpFunding',
                        fieldPath: `products[${index}].funding`,
                        value: productLumpFunding,
                    },
                    {
                        fieldName: 'lumpFundingTotal',
                        fieldPath: `products[${index}].funding`,
                        value: totalLumpFunding,
                    }
                );
            });
            const promotionUpdates = [
                {
                    fieldName: 'rateCards',
                    value: promotion.rateCards.filter(rc => String(rc._id) !== String(rateCardId)),
                },
            ];

            return dispatch('setStagingAreaFields', {
                namespace,
                fieldsToUpdate: [...promotionUpdates, ...productUpdates],
            });
        },

        async updatePromoFunding({ state, rootState, dispatch }, { namespace }) {
            const toggleLogic = rootState.clientConfig.toggleLogic;
            const featureAwareFactory = createFeatureAwareFactory(toggleLogic);
            const fundingUpdates = map(state.stagingArea[namespace].products, product => {
                const {
                    buyingPrice,
                    supplierCompensation,
                    unitFunding,
                    uiFields,
                } = calculateProductVariableFunding({
                    product,
                    featureAwareFactory,
                    showOnlyMinimumPrices: toggleLogic[showOnlyMinPrices],
                });

                const recalculatedVariableFunding = {
                    uiFields,
                    buyingPrice,
                    supplierCompensation,
                    unitFundingValue: unitFunding,
                };

                const { funding } = product;
                const originalVariableFunding = funding.variableFunding;

                const updatedVariableFunding = merge(
                    {},
                    originalVariableFunding,
                    recalculatedVariableFunding
                );

                const fieldPath = getFieldPathByProductKey({
                    productKey: product.productKey,
                    path: 'funding',
                    promotion: state.stagingArea[namespace],
                });

                return {
                    fieldPath,
                    fieldName: 'variableFunding',
                    value: updatedVariableFunding,
                };
            });

            await dispatch('setStagingAreaFields', {
                namespace,
                fieldsToUpdate: fundingUpdates,
            });
        },

        setParentPromotions({ commit }, parentPromotions) {
            commit('setParentPromotions', parentPromotions);
        },

        async setChildPromotionIsGhost(
            { dispatch, getters, rootGetters, rootState },
            { promotionId, isGhost }
        ) {
            await dispatch('setSelectedPromotion', { promotionId: null });
            await dispatch('updatePromotion', {
                id: promotionId,
                updates: { isGhost, execution: { executionId: null }, clientPromotionKey: null },
            });
            await dispatch('setSelectedPromotion', { promotionId });
            await Promise.all([dispatch('fetchSelectedSubcampaignPromotionAggregations')]);
            const promotionNotifications = getters.getPromotionNotifications({
                promotionId,
                notificationKey: notificationTypes.parentPromotionUpdated,
            });
            const subCampaign =
                rootGetters['subCampaigns/selectedSubCampaign'] ||
                rootState.parkingLotFilters.selections.subCampaign;
            if (promotionNotifications && promotionNotifications.length) {
                await dispatch(
                    'notifications/closeNotification',
                    {
                        resourceId: promotionId,
                        notificationKey: notificationTypes.parentPromotionUpdated,
                        resource: resources.promotions,
                    },
                    { root: true }
                );
                if (isGhost) {
                    // on BE side we applied changes if we make promotion as ghost
                    // we need to update it
                    await dispatch('fetchPromotions', {
                        params: {
                            where: {
                                _id: promotionId,
                                isGhost: true,
                                ...(rootState.clientConfig.toggleLogic[allowStoreWidePromotions]
                                    ? { storeWideCategories: get(subCampaign, 'categories') }
                                    : {}),
                            },
                        },
                        patchState: true,
                        patchOptions: {
                            fieldToMatchOn: '_id',
                            isMatchFunction: promotion => String(promotion._id) === promotionId,
                        },
                    });
                }
            }
            // Forecast promo if reinstating it
            if (!isGhost) {
                await dispatch(
                    'forecasting/forecastSinglePromotionById',
                    { namespace: promotionId },
                    { root: true }
                );
            }
        },

        async fetchPromotionsAggregations({ commit }, { params }) {
            commit('setLoading', true);
            const [err, res] = await to(axios.get('/api/promotions/aggregations', { params }));
            if (err) {
                commit('setLoading', false);
                return;
            }
            commit('setLoading', false);
            const { data: aggregations } = res;
            return aggregations;
        },

        setUserSelectedCategories: ({ state, rootState }) => {
            const promotionInStagingArea =
                state.stagingArea[state.selectedPromotionId || 'default'];

            // store wide promos should not have userSelectedCategories on promotion level
            // only user with access to all categories should be able to see SW promotion
            // otherwise promotion userSelectedCategories are affecting access middleware for promotion
            // createNotStrictPromotionAccessFilterWithAccessToEmptyPromotions in promotion-utils
            if (isStoreWidePromotion(promotionInStagingArea)) {
                return;
            }

            const userSelectedCategories = promotionInStagingArea.products.map(product =>
                product.hierarchy.find(
                    item => item.level === rootState.clientConfig.hierarchyConfig.categoryLevel
                )
            );
            const currentCategories = get(promotionInStagingArea, 'userSelectedCategories', []);
            Vue.set(
                promotionInStagingArea,
                'userSelectedCategories',
                uniqBy([...currentCategories, ...userSelectedCategories], 'levelEntryKey')
            );
        },

        async bulkUploadSupplierFunding({ dispatch, state, commit }, { dataFile }) {
            const promotionId = state.selectedPromotionId;

            const [error, response] = await to(
                axios.post(`/api/promotions/${promotionId}/uploadSupplierFunding`, dataFile, {
                    headers: {
                        // Now axios 1.x set the Content-Type to 'application/json' automatically so in order to have the value as 'FormData/HTMLForm' we need to set as undefined
                        // You can read more about in the axios migration guide to v1.x on https://github.com/bmuenzenmeyer/axios-1.0.0-migration-guide?tab=readme-ov-file#multipart-form-data-is-no-longer-automatically-set
                        'Content-Type': undefined,
                    },
                })
            );

            const formattingError = get(response, ['data', 'errors', 'formattingErrors'], null);

            await dispatch('handleResponseNotifications', {
                response,
                error: error || formattingError,
                successMessage: i18n.t('notifications.uploadSuccess', {
                    resource: i18n.t(`planning.promotionsMaintenance.funding.supplierFunding`),
                }),
                errorMessage: i18n.t('notifications.uploadError', {
                    resource: i18n.t(`planning.promotionsMaintenance.funding.supplierFunding`),
                }),
            });

            commit('setBulkUploadSupplierFundingErrors', response.data.errors);

            this.$app.globalEmit('supplier-funding-uploaded', {
                products: response.data.updatedPromotion.products,
                wasFundingUpdated: response.data.wasFundingUpdated,
            });

            if (response.data.updatedPromotion.products && response.data.wasFundingUpdated) {
                await dispatch('setStagingAreaField', {
                    namespace: promotionId,
                    fieldName: 'products',
                    value: response.data.updatedPromotion.products,
                });
                await dispatch(
                    'forecasting/forecastSinglePromotionById',
                    { namespace: promotionId },
                    { root: true }
                );
            }
        },
        async fetchPromotionsState({ dispatch, commit }, { params }) {
            commit('setLoading', true);
            const [error, response] = await to(
                axios.post('/api/promotions/fetchPromotionsState', { params })
            );

            if (error) {
                dispatch('handleResponseNotifications', {
                    error,
                    errorMessage: i18n.t('notifications.fetchError', {
                        resource: i18n.tc('entities.promotions', 2),
                    }),
                });

                commit('setLoading', false);

                return;
            }

            const { data: newResources } = response;

            commit('setLoading', false);
            return newResources;
        },
        setProductPrice(
            { state, dispatch },
            { productKey, value, namespace, priceId, priceIds, tierIndex }
        ) {
            const productIndex = state.stagingArea[namespace].products.findIndex(
                product => product.productKey === productKey
            );

            if (productIndex < 0) {
                throw new Error('Could not update price because the product does not exist.');
            }
            const product = state.stagingArea[namespace].products[productIndex];

            if (!priceIds) {
                priceIds = [priceId];
            }

            priceIds.forEach(id => {
                product.promoPrices[tierIndex][id] = value;
            });

            dispatch('setStagingAreaField', {
                namespace,
                fieldPath: `products[${productIndex}]`,
                fieldName: 'promoPrices',
                value: product.promoPrices,
            });
        },
        async addParentPromotionToState({ state, dispatch, commit }, { promotionId }) {
            const parentPromotion = await dispatch('fetchPromotionsState', {
                params: {
                    where: {
                        _id: promotionId,
                    },
                },
            });
            if (parentPromotion && parentPromotion[0]) {
                commit(
                    'setParentPromotions',
                    uniqBy([...state.parentPromotions, parentPromotion[0]], '_id')
                );
            }
        },
        async addBulkProductsToPromotion(
            { dispatch, commit, getters, state },
            { promotionId, productOfferGroupId, productKeys }
        ) {
            const [error, response] = await to(
                axios.post('/api/promotions/addBulkProductsToPromotion', {
                    promotionId,
                    productOfferGroupId,
                    productKeys,
                })
            );

            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t(`notifications.saveSuccess`, {
                    resource: i18n.tc('entities.promotions', 1),
                }),
                errorMessage: i18n.t(`notifications.saveError`, {
                    resource: i18n.tc('entities.promotions', 1),
                }),
            });

            if (error) {
                commit('setLoading', false);
                commit('setUploadReportDetails', {});
                return {};
            }

            commit('setUploadReportDetails', response.data);

            await dispatch('setSelectedPromotion', { promotionId });

            // update the staging area incase forecasting fails
            state.stagingArea[promotionId] = getters.selectedPromotion;

            dispatch(
                'forecasting/forecastSinglePromotion',
                {
                    promotion: getters.selectedPromotion,
                },
                { root: true }
            );

            this.$app.globalEmit(UXEvents.promotionProductsUpdatedForGridRefresh, {
                products: getters.selectedPromotion.products,
                added: true,
                fetchProductsAndAttributesForPromotionProductOfferGroup: false,
                source: 'bulkAddProducts',
            });

            commit('setLoading', false);
            return response.data;
        },

        async updatePromotionAfterNotificationsApplication(
            { dispatch, getters },
            { entityId: promotionId }
        ) {
            dispatch('setPromotionApplyingNotificationsFlag', {
                entityId: promotionId,
                value: false,
            });

            await dispatch('fetchPromotions', {
                params: {
                    where: {
                        _id: promotionId,
                    },
                },
                patchState: true,
                patchOptions: {
                    fieldToMatchOn: '_id',
                    isMatchFunction: promotion => String(promotion._id) === promotionId,
                },
            });

            this.$app.globalEmit(UXEvents.promotionProductsUpdatedForGridRefresh, {
                products: getters.selectedPromotion.products,
                added: true,
                fetchProductsAndAttributesForPromotionProductOfferGroup: false,
            });
        },

        async acceptAllNotifications({ dispatch }, { entityId: promotionId }) {
            const [error, response] = await to(
                axios.patch(`/api/promotions/acceptChangesForPromotion/${promotionId}`)
            );

            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t(
                    `notifications.successfullyQueuedPromotionsForNotifications`,
                    {
                        count: 1,
                    }
                ),
                errorMessage: i18n.t(`notifications.errorQueuingPromotionsForNotifications`),
            });
            if (error) return { error };
        },

        async setPromotionStoreWide({ state, dispatch, rootState }, { namespace, pogIndex }) {
            const promotion = state.stagingArea[namespace];
            const pog = promotion.productOfferGroups[pogIndex];

            const products = promotion.products
                .filter(p => !pog.products.includes(p.productKey))
                .map(p => omit(p, 'rank'));

            pog.products = [];
            pog.userSelectedCategories = [];
            pog.scope = pogScope.storeWide;

            const description = generateOfferMechanicDescription({
                offerMechanic: promotion.offerMechanic,
                products: promotion.products,
                offerGroups: promotion.productOfferGroups,
                isDescriptionWithoutProducts:
                    rootState.clientConfig.generalConfig.offerMechanicDescriptionWithoutProducts,
            });

            const fieldsToUpdate = [
                {
                    fieldName: 'products',
                    value: products,
                },
                {
                    fieldName: 'productOfferGroups',
                    value: promotion.productOfferGroups,
                },
                {
                    fieldName: 'offerMechanic',
                    value: {
                        ...promotion.offerMechanic,
                        description,
                    },
                },
                {
                    fieldName: 'userSelectedCategories',
                    value: [],
                },
                {
                    fieldName: 'categories',
                    value: [],
                },
                {
                    fieldName: 'effectivenessRating',
                    value: 0,
                },
                {
                    fieldName: 'forecastingAggregations',
                    value: {
                        promotion: {},
                        weekly: [],
                        errors: [],
                    },
                },
                {
                    fieldName: 'forecasts',
                    value: {
                        algorithm: null,
                        override: null,
                        reference: null,
                    },
                },
                {
                    fieldName: 'lastForecastDate',
                    value: null,
                },
                {
                    fieldName: 'rateCards',
                    value: [],
                },
                {
                    fieldName: 'actuals',
                    value: null,
                },
                {
                    fieldName: 'rankedProductsCount',
                    value: 0,
                },
            ];

            await dispatch('setStagingAreaFields', {
                namespace,
                fieldsToUpdate,
            });
        },

        async setPromotionCategoryWide({ state, dispatch, rootState }, { namespace, pogIndex }) {
            const promotion = state.stagingArea[namespace];
            const pog = promotion.productOfferGroups[pogIndex];

            const categoriesKeyed = keyBy(pog.userSelectedCategories, 'levelEntryKey');
            const products = promotion.products
                .filter(p => !pog.products.includes(p.productKey))
                .map(p => omit(p, 'rank'));
            const userSelectedCategoriesFromOtherPogs = promotion.userSelectedCategories.filter(
                cat => !categoriesKeyed[cat.levelEntryKey]
            );

            pog.products = [];
            pog.scope = pogScope.categoryWide;

            const categories = pog.userSelectedCategories.map(cat => cat.levelEntryKey);

            const description = generateOfferMechanicDescription({
                offerMechanic: promotion.offerMechanic,
                products: promotion.products,
                offerGroups: promotion.productOfferGroups,
                isDescriptionWithoutProducts:
                    rootState.clientConfig.generalConfig.offerMechanicDescriptionWithoutProducts,
            });

            const fieldsToUpdate = [
                {
                    fieldName: 'products',
                    value: products,
                },
                {
                    fieldName: 'productOfferGroups',
                    value: promotion.productOfferGroups,
                },
                {
                    fieldName: 'offerMechanic',
                    value: {
                        ...promotion.offerMechanic,
                        description,
                    },
                },
                // keep the categories selected from the category wide POG
                // and the categories from other non category wide POGs
                {
                    fieldName: 'userSelectedCategories',
                    value: [...pog.userSelectedCategories, ...userSelectedCategoriesFromOtherPogs],
                },
                // keep the categories selected from the category wide POG
                // and the categories from other non category wide POGs
                {
                    fieldName: 'categories',
                    value: [
                        ...categories,
                        ...userSelectedCategoriesFromOtherPogs.map(usc => usc.levelEntryKey),
                    ],
                },
                {
                    fieldName: 'effectivenessRating',
                    value: 0,
                },
                {
                    fieldName: 'forecastingAggregations',
                    value: {
                        promotion: {},
                        weekly: [],
                        errors: [],
                    },
                },
                {
                    fieldName: 'forecasts',
                    value: {
                        algorithm: null,
                        override: null,
                        reference: null,
                    },
                },
                {
                    fieldName: 'lastForecastDate',
                    value: null,
                },
                {
                    fieldName: 'rateCards',
                    value: [],
                },
                {
                    fieldName: 'actuals',
                    value: null,
                },
                {
                    fieldName: 'rankedProductsCount',
                    value: 0,
                },
            ];

            await dispatch('setStagingAreaFields', {
                namespace,
                fieldsToUpdate,
            });
        },

        async updateNotificationStateForPromotion({ state, dispatch }, { notification }) {
            const promotionId = get(notification, 'details.entityIds.promotionId', null);
            const isPromoInState = state.promotions.some(
                promo => String(promo._id) === String(promotionId)
            );
            if (promotionId && isPromoInState) {
                await dispatch('fetchPromotions', {
                    params: {
                        where: {
                            _id: promotionId,
                        },
                    },
                    patchState: true,
                    patchOptions: {
                        fieldToMatchOn: '_id',
                        isMatchFunction: promotion => String(promotion._id) === promotionId,
                    },
                });
            }
        },

        async queuePromotionForSplitting({ dispatch, commit }, { promotionId }) {
            commit('setLoading', true);

            await dispatch('setStagingAreaField', {
                namespace: promotionId,
                fieldName: 'splitInProgress',
                value: true,
            });

            const [error, response] = await to(
                axios.post(`/api/promotions/queuePromotionForSplitting/${promotionId}`)
            );

            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t(`notifications.successfullyQueuedPromotionForSplitting`),
                errorMessage: i18n.t(`notifications.errorQueuingPromotionForSplitting`),
            });

            commit('setLoading', false);

            if (error) {
                await dispatch('setStagingAreaField', {
                    namespace: promotionId,
                    fieldName: 'splitInProgress',
                    value: false,
                });
                throw error;
            }
        },
    },
};

const mixinParams = {
    resource: 'promotion',
    useForm: true,
    useNotes: true,
    useFilters: true,
    useWorkflow: true,
    useNominationMatrix: true,
    getInitialState,
};

export default merge({}, storeMixin(mixinParams), store);
