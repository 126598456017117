<template>
    <div class="redis-bull">
        <div class="redis-bull__queue-operations">
            <div class="redis-bull__queue-operations--title"><b>Perform Queue Operation:</b></div>
            <vuex-select
                class="redis-bull__queue-operations--select-queue"
                :getter="() => selectedQueue"
                :setter="value => setSelectedQueue(value)"
                :options="queueOptions"
                :placeholder="$tkey(`selectedQueue`) | toSentenceCase"
                item-text="queueName"
                item-value="queueId"
            />
            <vuex-select
                class="redis-bull__queue-operations--select-operation"
                :getter="() => selectedOperation"
                :setter="value => setSelectedOperation(value)"
                :options="operationOptions"
                :disabled="!selectedQueue"
                :placeholder="$tkey(`selectedOperation`) | toSentenceCase"
                item-text="operation"
                item-value="operationId"
            />
            <v-btn
                class="redis-bull__queue-operations--submit"
                depressed
                color="primary"
                :disabled="!selectedQueue || !selectedOperation"
                @click="submit()"
            >
                {{ $tkey(`submit`) | toSentenceCase }}
            </v-btn>
            <confirm-dialog
                ref="cofirmOperationDialog"
                class="redis-bull__queue-operations--confirm-dialog"
                :question-text="$tkey(`questionText`) | toSentenceCase"
                :warning-text="confirmWarning"
                :action-text="$tkey('confirm') | toSentenceCase"
                :has-activator="false"
                @confirm="confirm()"
                @close="cancelDialog()"
            />
        </div>
        <div v-if="numberOfEffectedJobs !== null" class="redis-bull__queue-message">
            {{ $tkey('jobsRemoved', { numberOfEffectedJobs }) | toSentenceCase }}
        </div>
        <div class="redis-bull__bulk-add">
            <div class="redis-bull__bulk-add--title"><b>Add promotions to queue:</b></div>
            <vuex-select
                class="redis-bull__bulk-add--select-queue"
                :getter="() => selectedBulkAddQueue"
                :setter="value => setSelectedBulkAddQueue(value)"
                :options="bulkAddsQueueOptions"
                :placeholder="$tkey(`selectedQueue`) | toSentenceCase"
                item-text="queueName"
                item-value="queueId"
            />
            <vuex-date-range
                :key="dateRange.length"
                :from="{ fieldName: 'from' }"
                :to="{ fieldName: 'to' }"
                :use-vuex-staging-area="false"
                :getter="() => dateRange"
                :min-date="minDateRangeDate"
                :disabled="!selectedBulkAddQueue"
                class="redis-bull__bulk-add--date-range"
                @change="dateRangeSelected"
            />
            <v-text-field
                v-model="filterText"
                filled
                class="redis-bull__bulk-add--filter"
                clearable
                flat
                placeholder='Mongo filter e.g. { "isInParkingLot": false }'
                :disabled="!selectedBulkAddQueue"
            />
            <v-checkbox
                v-model="includeGhosts"
                class="redis-bull__bulk-add--checkbox"
                :disabled="!selectedBulkAddQueue"
            />
            <v-btn
                class="redis-bull__bulk-add--submit"
                depressed
                color="primary"
                :disabled="!selectedBulkAddQueue || (!selectedBulkAddDateFilter || !filterText)"
                @click="submitAddToQueue()"
            >
                {{ $tkey(`submit`) | toSentenceCase }}
            </v-btn>
        </div>
        <div v-if="numberOfAddedPromotions !== null" class="redis-bull__queue-message">
            {{ $tkey('jobsAdded', { numberOfAddedPromotions }) | toSentenceCase }}
        </div>
        <div class="redis-bull__batch-job">
            <div class="redis-bull__batch-job--title"><b>Submit batch job for filter:</b></div>
            <vuex-select
                class="redis-bull__batch-job--select-queue"
                :getter="() => selectedBatchJob"
                :setter="value => setSelectedBatchJob(value)"
                :options="batchJobsOptions"
                :placeholder="$tkey(`selectedJob`) | toSentenceCase"
                item-text="jobName"
                item-value="jobId"
            />
            <vuex-date-range
                :key="dateRange.length"
                :from="{ fieldName: 'from' }"
                :to="{ fieldName: 'to' }"
                :use-vuex-staging-area="false"
                :getter="() => jobDateRange"
                :disabled="!selectedBatchJob"
                class="redis-bull__batch-job--date-range"
                @change="jobDateRangeSelected"
            />
            <v-text-field
                v-model="batchJobFilterText"
                filled
                class="redis-bull__batch-job--filter"
                clearable
                flat
                placeholder='Mongo filter e.g. { "isInParkingLot": false }'
                :disabled="!selectedBatchJob"
            />
            <v-btn
                class="redis-bull__batch-job--submit"
                depressed
                color="primary"
                :disabled="disabledBatchJobForFilter"
                @click="submitBatchJob()"
            >
                {{ $tkey(`submit`) | toSentenceCase }}
            </v-btn>
            <div v-if="numberOfProcessedPromotions !== null" class="redis-bull__queue-message">
                {{
                    $tkey('jobsProcessed', { numberOfProcessedIds: numberOfProcessedPromotions })
                        | toSentenceCase
                }}
            </div>
        </div>
        <div class="redis-bull__batch-job">
            <div class="redis-bull__batch-job--title"><b>Submit batch job for ids:</b></div>
            <vuex-select
                class="redis-bull__batch-job--select-queue"
                :getter="() => selectedBatchJobForIds"
                :setter="value => setSelectedBatchJobForIds(value)"
                :options="batchJobForIdsOptions"
                :placeholder="$tkey(`selectedJob`) | toSentenceCase"
                item-text="jobName"
                item-value="jobId"
            />
            <v-text-field
                v-model="batchJobIdsText"
                filled
                class="redis-bull__batch-job--filter"
                clearable
                flat
                placeholder='Mongo filter e.g. { "isInParkingLot": false }'
                :disabled="!selectedBatchJobForIds"
            />
            <v-checkbox v-model="isDeleted" label="isDeleted" />
            <v-checkbox v-model="isGhost" label="isGhost" />
            <v-btn
                class="redis-bull__batch-job--submit"
                depressed
                color="primary"
                :disabled="!selectedBatchJobForIds || !batchJobIdsText"
                @click="submitBatchJobForIds()"
            >
                {{ $tkey(`submit`) | toSentenceCase }}
            </v-btn>
            <div v-if="numberOfProcessedIds !== null" class="redis-bull__queue-message">
                {{ $tkey('jobsProcessed', { numberOfProcessedIds }) | toSentenceCase }}
            </div>
        </div>
        <div class="redis-bull__bulk-add">
            <div class="redis-bull__bulk-add--title">
                <b>Fix products mismatch on promotions:</b>
            </div>
            <vuex-date-range
                :key="productsMismatchDateRange.length"
                :from="{ fieldName: 'from' }"
                :to="{ fieldName: 'to' }"
                :use-vuex-staging-area="false"
                :getter="() => productsMismatchDateRange"
                :min-date="minDateRangeDate"
                class="redis-bull__bulk-add--date-range"
                @change="productsMismatchDateRangeSelected"
            />
            <v-checkbox v-model="fixGhostsForMismatches" label="fixGhosts" />
            <v-btn
                class="redis-bull__bulk-add--submit"
                depressed
                color="primary"
                :disabled="!(productsMismatchDateRange.length > 1)"
                @click="fixProductsMismatchForDates()"
            >
                {{ $tkey(`submit`) | toSentenceCase }}
            </v-btn>
            <div
                v-if="numberOfFixedMismatchingProductsPromotions !== null"
                class="redis-bull__queue-message"
            >
                {{
                    $tkey('promotionsFixed', {
                        numberOfPromotionsFixed: numberOfFixedMismatchingProductsPromotions,
                    }) | toSentenceCase
                }}
            </div>
        </div>
        <div class="redis-bull__batch-job">
            <div class="redis-bull__batch-job--title"><b>Resync Child ids</b></div>
            <v-textarea
                v-model="promoIdsToResync"
                filled
                clearable
                flat
                placeholder="promoA,promoB"
                class="redis-bull__batch-job--textarea"
            />
            <v-btn
                class="redis-bull__batch-job--submit"
                depressed
                color="primary"
                :disabled="!promoIdsToResync"
                @click="resyncChildIds()"
            >
                {{ $tkey(`submit`) | toSentenceCase }}
            </v-btn>
            <div v-if="numberOfResyncedIds !== null" class="redis-bull__queue-message">
                {{ $tkey('promotionsResynced', { numberOfResyncedIds }) | toSentenceCase }}
            </div>
        </div>
        <div class="redis-bull__batch-job">
            <div class="redis-bull__batch-job--title">
                <b>Fix promotions where wasInParkingLot became false</b>
            </div>
            <v-btn
                class="redis-bull__batch-job--submit"
                depressed
                color="primary"
                @click="fixingParkingLotPromosWhereWasInParkingLotBecameFalse()"
            >
                {{ $tkey(`submit`) | toSentenceCase }}
            </v-btn>
            <div
                v-if="numberOfFixedItemsForWasInParkingLot !== null"
                class="redis-bull__queue-message"
            >
                {{
                    $tkey('wasInParkingLotFix', { numberOfFixedItemsForWasInParkingLot })
                        | toSentenceCase
                }}
            </div>
        </div>
        <div class="redis-bull__batch-job">
            <div class="redis-bull__batch-job--title"><b>Add missing field to promotions</b></div>
            <v-text-field
                v-model="addMissingFieldToPromosPayload.field"
                filled
                class="redis-bull__batch-job--text"
                clearable
                flat
                placeholder="Field to be added"
            />
            <v-text-field
                v-model="addMissingFieldToPromosPayload.value"
                filled
                class="redis-bull__batch-job--text"
                clearable
                flat
                placeholder="Field value"
                :disabled="!addMissingFieldToPromosPayload.field"
            />
            <v-text-field
                v-model="addMissingFieldToPromosPayload.filter"
                filled
                class="redis-bull__batch-job--filter"
                clearable
                flat
                placeholder='Mongo filter e.g. { "isInParkingLot": false }'
                :disabled="!addMissingFieldToPromosPayload.field"
            />
            <v-btn
                class="redis-bull__batch-job--submit"
                depressed
                color="primary"
                :disabled="!addMissingFieldToPromosPayload.field"
                @click="submitAddMissingFieldToPromos()"
            >
                {{ $tkey(`submit`) | toSentenceCase }}
            </v-btn>
            <div v-if="numberOfFixedPromotions !== null" class="redis-bull__queue-message">
                {{
                    $tkey('promotionsFixed', { numberOfPromotionsFixed: numberOfFixedPromotions })
                        | toSentenceCase
                }}
            </div>
        </div>
        <div class="redis-bull__batch-job">
            <div class="redis-bull__batch-job--title">
                <b>Fix promotions where start and end dates are string</b>
            </div>
            <v-btn
                class="redis-bull__batch-job--submit"
                depressed
                color="primary"
                @click="fixingStartEndDates()"
            >
                {{ $tkey(`submit`) | toSentenceCase }}
            </v-btn>
            <div v-if="numberOfFixedItemsWithWrongDates !== null" class="redis-bull__queue-message">
                {{
                    $tkey('promotionsFixed', {
                        numberOfPromotionsFixed: numberOfFixedItemsWithWrongDates,
                    }) | toSentenceCase
                }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { get, trim, find } from 'lodash';

export default {
    localizationKey: 'admin.redisBull',
    data() {
        return {
            selectedQueue: null,
            selectedBulkAddQueue: null,
            selectedBulkAddDateFilter: null,
            selectedBatchJob: null,
            selectedBatchJobDateFilter: null,
            selectedBatchJobForIds: null,
            includeGhosts: false,
            dateRange: [],
            jobDateRange: [],
            productsMismatchDateRange: [],
            numberOfFixedMismatchingProductsPromotions: null,
            fixGhostsForMismatches: false,
            selectedOperation: null,
            disabled: false,
            dialogOpen: false,
            numberOfEffectedJobs: null,
            numberOfAddedPromotions: null,
            numberOfProcessedPromotions: null,
            numberOfProcessedIds: null,
            filterText: '{ "isInParkingLot": false}',
            batchJobFilterText: '{}',
            batchJobIdsText: '[]',
            isDeleted: false,
            isGhost: false,
            promoIdsToResync: null,
            numberOfResyncedIds: null,
            numberOfFixedItemsForWasInParkingLot: null,
            numberOfFixedItemsWithWrongDates: null,
            numberOfFixedPromotions: null,
            addMissingFieldToPromosPayload: { field: null, value: null, filter: '{}' },
        };
    },
    computed: {
        ...mapState('clientConfig', ['redisBullAdminConfig']),

        queueOptions() {
            return this.redisBullAdminConfig.queueOptions.map(op => {
                return {
                    queueId: op.queueId,
                    queueName: this.$tkey(`queues.${op.queueId}`),
                };
            });
        },

        minDateRangeDate() {
            return this.$options.filters.datepickerFormat(this.$moment());
        },

        bulkAddsQueueOptions() {
            return this.queueOptions.filter(queue => {
                return (
                    queue.queueId === 'promotion-execution' ||
                    queue.queueId === 'promotion-forecasting' ||
                    queue.queueId === 'promotion-costs-prices-post-processing' ||
                    queue.queueId === 'promotion-suppliers-post-processing'
                );
            });
        },

        batchJobsOptions() {
            return [
                {
                    jobId: 'regenerate-products-with-promotions',
                    jobName: 'Regenerate products with promotions',
                },
                {
                    jobId: 'close-empty-notifications',
                    jobName: 'Close notifications without changeset',
                },
                {
                    jobId: 'close-duplicate-notifications',
                    jobName: 'Close duplicate notifications',
                    jobFunction: this.closeDuplicateNotifications,
                },
                {
                    jobId: 'fix-sell-in-period',
                    jobName: 'Fix sell in period',
                    jobFunction: this.fixSellInPeriodMismatch,
                },
                {
                    jobId: 'copy-funding',
                    jobName: 'Copy funding from parent to child',
                    jobFunction: this.copyFundingToChild,
                },
                {
                    jobId: 'fix-client-state',
                    jobName: 'Fixing wrong client state for approved promotions',
                    jobFunction: this.fixClientState,
                },
                {
                    jobId: 'fix-categories',
                    jobName: 'Fixing user selected categories',
                    jobFunction: this.fixUserSelectedCategories,
                },
                {
                    jobId: 'fix-promos-with-wrong-funding',
                    jobName: 'Fixing promos with wrong funding',
                    jobFunction: this.fixPromosWithWrongFunding,
                },
                {
                    jobId: 'fix-promos-with-wrong-rate-card-id-type',
                    jobName: 'Fixing promos with wrong rate card id type',
                    jobFunction: this.fixPromosWithWrongRateCardIdType,
                },
                {
                    jobId: 'remove-unused-categories',
                    jobName: 'Remove unused categories from promotions',
                    jobFunction: this.removeUnusedCategories,
                    filterPlaceholder: '{"changesetId": ""}',
                },
                {
                    jobId: 'fix-products-hierarchy',
                    jobName: 'Fix products hierarchy',
                    jobFunction: this.fixProductsHierachy,
                    filterPlaceholder: '{"levelEntryKey": ""}',
                },
                {
                    jobId: 'recalculate-funding',
                    jobName: 'Recalculate funding for planned promotions',
                    jobFunction: this.recalculateFunding,
                },
            ];
        },

        batchJobForIdsOptions() {
            return [
                {
                    jobId: 'delete-in-execution',
                    jobName: 'Delete from execution',
                },
                {
                    jobId: 'send-to-execution',
                    jobName: 'Send to execution',
                },
                {
                    jobId: 'send-to-forecasting',
                    jobName: 'Send to forecasting',
                },
                {
                    jobId: 'delete-in-webtool',
                    jobName: 'Delete from webtool',
                },
            ];
        },

        operationOptions() {
            return this.redisBullAdminConfig.operationOptions.map(op => {
                return {
                    operationId: op.operationId,
                    operation: this.$tkey(`operations.${op.operationId}`),
                };
            });
        },
        confirmWarning() {
            const queueName = get(
                this.queueOptions.find(queue => queue.queueId === this.selectedQueue),
                'queueName'
            );
            const operationName = get(
                this.operationOptions.find(op => op.operationId === this.selectedOperation),
                'operation'
            );
            return this.$tkey('warning', { queueName, operationName });
        },
        disabledBatchJobForFilter() {
            if (
                this.selectedBatchJob &&
                [
                    'close-empty-notifications',
                    'close-duplicate-notifications',
                    'copy-funding',
                ].includes(this.selectedBatchJob)
            ) {
                return false;
            }
            return (
                !this.selectedBatchJob ||
                (!this.selectedBatchJobDateFilter || !this.batchJobFilterText)
            );
        },
    },
    methods: {
        ...mapActions('redisBull', [
            'performOperationOnQueue',
            'bulkAddsForQueue',
            'runBatchJob',
            'resyncPromoIds',
            'fixProductsWithMismatchingProducts',
            'closeDuplicateNotifications',
            'fixSellInPeriodMismatch',
            'copyFundingToChild',
            'fixClientState',
            'fixUserSelectedCategories',
            'fixPromosWithWrongFunding',
            'fixPromosWithWrongRateCardIdType',
            'fixParkingLotPromosWhereWasInParkingLotBecameFalse',
            'addMissingFieldToPromos',
            'fixingStartEndDatesAction',
            'removeUnusedCategories',
            'recalculateFunding',
            'fixProductsHierachy',
        ]),
        setSelectedQueue(queue) {
            this.selectedQueue = queue;
            this.numberOfEffectedJobs = null;
        },
        setSelectedOperation(operation) {
            this.selectedOperation = operation;
        },

        setSelectedBulkAddQueue(queue) {
            this.selectedBulkAddQueue = queue;
            this.numberOfAddedPromotions = null;
        },

        setSelectedBatchJob(job) {
            const placeholder = get(
                find(this.batchJobsOptions, option => option.jobId === job),
                'filterPlaceholder'
            );
            this.selectedBatchJob = job;
            this.batchJobFilterText = placeholder || '{}';
        },

        setSelectedBatchJobForIds(job) {
            this.selectedBatchJobForIds = job;
        },

        dateRangeSelected({ from, to: toValue }) {
            this.selectedStartDate = from;
            this.selectedEndDate = toValue;
            this.dateRange = [this.selectedStartDate, this.selectedEndDate];
            this.selectedBulkAddDateFilter = {
                $and: [
                    {
                        startDate: { $gte: this.selectedStartDate },
                    },
                    {
                        startDate: { $lte: this.selectedEndDate },
                    },
                ],
            };
        },

        jobDateRangeSelected({ from, to: toValue }) {
            this.selectedStartDate = from;
            this.selectedEndDate = toValue;
            this.jobDateRange = [this.selectedStartDate, this.selectedEndDate];
            this.selectedBatchJobDateFilter = {
                $and: [
                    {
                        startDate: { $gte: this.selectedStartDate },
                    },
                    {
                        startDate: { $lte: this.selectedEndDate },
                    },
                ],
            };
        },

        productsMismatchDateRangeSelected({ from, to: toValue }) {
            this.productsMismatchDateRange = [from, toValue];
        },

        isJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },

        submit() {
            this.$refs.cofirmOperationDialog.open();
            this.dialogOpen = true;
        },

        async submitAddToQueue() {
            const filter = {
                ...(this.selectedBulkAddDateFilter && this.selectedBulkAddDateFilter),
                ...(this.isJsonString(this.filterText) && JSON.parse(this.filterText)),
            };
            this.numberOfAddedPromotions = await this.bulkAddsForQueue({
                queue: this.selectedBulkAddQueue,
                filter,
                includeGhosts: this.includeGhosts,
            });
        },

        async submitBatchJob() {
            const filter = {
                ...(this.selectedBatchJobDateFilter && this.selectedBatchJobDateFilter),
                ...(this.isJsonString(this.batchJobFilterText) &&
                    JSON.parse(this.batchJobFilterText)),
            };

            const jobFunction = get(
                this.batchJobsOptions.find(job => job.jobId === this.selectedBatchJob),
                'jobFunction',
                null
            );

            if (jobFunction) {
                this.numberOfProcessedPromotions = await jobFunction({
                    filter: {
                        startDate: this.selectedStartDate,
                        endDate: this.selectedEndDate,
                        ...(this.isJsonString(this.batchJobFilterText) &&
                            JSON.parse(this.batchJobFilterText)),
                    },
                });
            } else {
                this.numberOfProcessedPromotions = await this.runBatchJob({
                    job: this.selectedBatchJob,
                    filter,
                });
            }
        },

        async resyncChildIds() {
            const promotionIds = this.promoIdsToResync.split(',').map(id => trim(id));
            const idsProcessed = await this.resyncPromoIds({ promotionIds });
            this.numberOfResyncedIds = idsProcessed.length;
        },

        async fixingParkingLotPromosWhereWasInParkingLotBecameFalse() {
            this.numberOfFixedItemsForWasInParkingLot = await this.fixParkingLotPromosWhereWasInParkingLotBecameFalse();
        },

        async fixingStartEndDates() {
            this.numberOfFixedItemsWithWrongDates = await this.fixingStartEndDatesAction();
        },

        async fixProductsMismatchForDates() {
            this.numberOfFixedMismatchingProductsPromotions = await this.fixProductsWithMismatchingProducts(
                {
                    startDate: this.productsMismatchDateRange[0],
                    endDate: this.productsMismatchDateRange[1],
                    includeGhosts: this.fixGhostsForMismatches,
                }
            );
        },

        async submitAddMissingFieldToPromos() {
            const filter = {
                ...(this.isJsonString(this.addMissingFieldToPromosPayload.filter) &&
                    JSON.parse(this.addMissingFieldToPromosPayload.filter)),
            };
            this.numberOfFixedPromotions = await this.addMissingFieldToPromos({
                ...this.addMissingFieldToPromosPayload,
                filter,
            });
        },

        async submitBatchJobForIds() {
            if (!this.isJsonString(this.batchJobIdsText)) return;

            const filter = {
                _id: { $in: JSON.parse(this.batchJobIdsText) },
                ...(this.isDeleted ? { isDeleted: true } : { isDeleted: { $ne: true } }),
                ...(this.isGhost ? { isGhost: true } : { isGhost: { $ne: true } }),
            };

            this.numberOfProcessedIds = await this.runBatchJob({
                job: this.selectedBatchJobForIds,
                filter,
            });
        },

        async confirm() {
            this.numberOfEffectedJobs = await this.performOperationOnQueue({
                queueId: this.selectedQueue,
                operationId: this.selectedOperation,
            });
        },

        cancelDialog() {
            this.dialogOpen = false;
            this.selectedQueue = null;
            this.selectedOperation = null;
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.redis-bull {
    height: 100%;
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    background-color: white;
    font-size: 1.4rem;

    &__queue-operations,
    &__bulk-add,
    &__batch-job {
        display: flex;
        column-gap: 1rem;
        margin-left: 1rem;
        margin-top: 1rem;

        &--submit,
        &--title {
            margin-top: 1rem;
        }
        &--select-queue,
        &--select-operation {
            min-width: 11%;
            max-width: 100%;
        }
        &--date-range {
            width: 11%;
        }
        &--checkbox {
            align-self: center;
            margin-top: 0;
        }
        &--textarea {
            max-width: 50%;
        }
        &--filter {
            font-size: 1.4rem !important;
            max-width: 20%;

            ::v-deep {
                .v-input__slot {
                    min-height: 2.8rem;
                }
                .v-text-field__slot {
                    height: 1.8rem;
                }
            }
        }

        &--text {
            font-size: 1.4rem !important;
            max-width: 10%;

            ::v-deep {
                .v-input__slot {
                    min-height: 2.8rem;
                }
                .v-text-field__slot {
                    height: 1.8rem;
                }
            }
        }
    }

    &__queue-message {
        margin-left: 1rem;
        margin-top: 1rem;
    }

    ::v-deep .v-input--selection-controls {
        margin-top: 0;
    }
}
</style>
